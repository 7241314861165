import { get } from '../util/apiHelper';

export const getAnalyticsTileData = async (msalInstance) => {
  try {
    const response = await get(`/Analytics/PendingCreditApprovals`, null, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAnalyticsOrderStatus = async (data, msalInstance) => {
  try {
    const response = await get(`/Analytics/OrderStatus`, data, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAnalyticsTokenStatus = async (data, msalInstance) => {
  try {
    const response = await get(`/Analytics/TokenStatus`, data, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAnalyticsCarbonStatus = async (data, msalInstance) => {
  try {
    const response = await get(`/Analytics/CarbonStatus`, data, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllProducts = async (msalInstance) => {
  try {
    const response = await get(`/OrderManagement/GetAllProducts`, null, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};
