import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Dropdownmenu = ({ path, id, receiver, action, className }) => {
  const navigate = useNavigate();

  const navigateToPage = () => {
    navigate(path, { state: { orderId: id, receiverName: receiver ? receiver : '' } });
  };

  return (
    <Dropdown.Item data-testid={className} onClick={navigateToPage}>
      {action}
    </Dropdown.Item>
  );
};

export default Dropdownmenu;
