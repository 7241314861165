export const addNotification = (status, page, message) => {
  return {
    type: 'NOTIFY',
    status: status,
    page: page,
    message: message
  };
};

export const clearNotification = () => {
  return {
    type: 'CLEAR',
    status: '',
    page: '',
    message: ''
  };
};
