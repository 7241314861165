import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AuthInteceptor } from '../components/authInterceptor';
import { RouteGuard } from '../components/RouterGuard';
import Analytics from '../features/analytics/Analytics';
import CarbonManagement from '../features/carbonMgt/CarbonManagement';
import CreateCustomer from '../features/customers/CreateCustomer';
import Customers from '../features/customers/Customers';
import EditCustomer from '../features/customers/EditCustomer';
import Orders from '../features/orders/Orders';
import ManageUser from '../features/users/ManageUser';
import Users from '../features/users/Users';
import ManageTradeAccount from '../features/carbonMgt/ManageTradeAccount';
import TradeAccountDetails from '../features/carbonMgt/TradeAccountDetails';
import TransferCarbon from '../features/carbonMgt/TransferCarbon';
import { appRoles } from '../util/authConfig';
import '../styles/style.scss';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import ViewOrder from '../features/orders/ViewOrder';
import PageLayout from '../components/layouts/PageLayout';
import ManageOrder from '../features/orders/ManageOrder';
import XeroIntegration from '../features/orders/XeroIntegration';
import TransactionsUploader from '../features/carbonMgt/TransactionsUploader';
import Reports from '../features/reports/Reports';
import OrderReleases from '../features/orders/OrderReleases';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-loading-skeleton/dist/skeleton.css';
import DeliverTokens from '../features/orders/DeliverTokens';
import { PausedOrderReleases } from '../features/orders/PausedOrderReleases';
import { Templates } from '../components/Templates';

export const AppContext = React.createContext({ msalInstance: null });

export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;

const Pages = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Analytics />
    },
    {
      path: '/users',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <Users />
        </RouteGuard>
      )
    },
    {
      path: '/users/create-user',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser]}>
          <ManageUser />
        </RouteGuard>
      )
    },
    {
      path: '/users/update-user',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <ManageUser />
        </RouteGuard>
      )
    },
    {
      path: '/customers',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <Customers />
        </RouteGuard>
      )
    },
    {
      path: '/customers/edit-customer',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <EditCustomer />
        </RouteGuard>
      )
    },
    {
      path: '/customers/create-customer',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser]}>
          <CreateCustomer />
        </RouteGuard>
      )
    },
    // ================= dev purpose path ==================
    {
      path: '/orders/paused-order-releases',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser]}>
          <PausedOrderReleases />
        </RouteGuard>
      )
    },
    {
      path: '/carbon-management',
      element: (
        <RouteGuard
          exact
          roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.carbonMgtUser, appRoles.readOnlyUser]}
        >
          <CarbonManagement />
        </RouteGuard>
      )
    },
    {
      path: '/carbon-management/create-trade-account',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser]}>
          <ManageTradeAccount />
        </RouteGuard>
      )
    },
    {
      path: '/carbon-management/update-trade-account',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser]}>
          <ManageTradeAccount />
        </RouteGuard>
      )
    },
    {
      path: '/carbon-management/trade-account-details',
      element: (
        <RouteGuard
          exact
          roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser, appRoles.carbonMgtUser]}
        >
          <TradeAccountDetails />
        </RouteGuard>
      )
    },
    {
      path: '/carbon-management/transfer-carbon',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.carbonMgtUser]}>
          <TransferCarbon />
        </RouteGuard>
      )
    },
    {
      path: '/carbon-management/upload-transactions',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.carbonMgtUser]}>
          <TransactionsUploader />
        </RouteGuard>
      )
    },
    {
      path: '/orders',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <Orders />
        </RouteGuard>
      )
    },
    {
      path: '/orders/create-order',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser]}>
          <ManageOrder />
        </RouteGuard>
      )
    },
    {
      path: '/orders/edit-order',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <ManageOrder />
        </RouteGuard>
      )
    },
    {
      path: '/orders/re-order',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <ManageOrder />
        </RouteGuard>
      )
    },
    {
      path: 'orders/view-order/deliver-token',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <DeliverTokens />
        </RouteGuard>
      )
    },
    {
      path: '/orders/view-order',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <ViewOrder />
        </RouteGuard>
      )
    },
    {
      path: '/orders/view-order/:orderIdParam/:orderReleaseIdParam',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <ViewOrder />
        </RouteGuard>
      )
    },
    {
      path: '/orders/deliver-tokens',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <ManageOrder />
        </RouteGuard>
      )
    },
    {
      path: '/orders/order-releases',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <OrderReleases />
        </RouteGuard>
      )
    },
    {
      path: '/reports',
      element: (
        <RouteGuard
          exact
          roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.carbonMgtUser, appRoles.readOnlyUser]}
        >
          <Reports />
        </RouteGuard>
      )
    },
    {
      path: '/templates',
      element: (
        <RouteGuard
          exact
          roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.carbonMgtUser, appRoles.readOnlyUser]}
        >
          <Templates />
        </RouteGuard>
      )
    },
    {
      path: '/xero',
      element: (
        <RouteGuard exact roles={[appRoles.adminUser, appRoles.c2zeroUser, appRoles.readOnlyUser]}>
          <XeroIntegration />
        </RouteGuard>
      )
    }
  ]);

  return routes;
};

const App = ({ msalInstance }) => {
  return (
    <Router>
      <AuthInteceptor>
        <AppContextProvider
          value={{
            msalInstance
          }}
        >
          <PageLayout>
            <Pages />
          </PageLayout>
        </AppContextProvider>
      </AuthInteceptor>
    </Router>
  );
};

export default App;
