import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';

import TableComponentUpdated from '../../components/TableNew';
import PaginationComponent from '../../components/PaginationComponent';
import { createColumnHelper } from '@tanstack/table-core';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import { useNavigate } from 'react-router-dom';
import addUserIcon from '../../assets/images/icons/user-plus.svg';
import TableNoData from '../../components/TableNoData';
import { recordsPerPage } from '../../util/constants';
import { getAllPausedOrderReleases } from '../../services/orderService';
import { AppContext } from '../../app/App';
import { formatDate } from '../../common/formatter';

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.accessor('createdOn', {
    id: 'Date',
    cell: (info) => <span>{formatDate(info.getValue())}</span>,
    header: () => <span>Order Created Date</span>,
    enableSorting: true
  }),
  columnHelper.accessor('orderNo', {
    id: 'OrderNo',
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>ORDER NO</span>,
    enableSorting: false
  }),
  columnHelper.accessor('approvalStatus', {
    id: 'Status',
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>ORDER RELEASE STATUS</span>,
    enableSorting: false
  }),
  columnHelper.accessor((row) => (row.isPaused && !row.isPaid ? 'Pending Payment' : 'Pending Carbon'), {
    id: 'PausedReason',
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>PAUSED REASON</span>
  })
];

export const PausedOrderReleases = () => {
  const [allOrders, setAllOrders] = useState([]);
  const context = React.useContext(AppContext);
  const [getOrdersApiRequestInProgress, setGetOrdersApiRequestInProgress] = useState(false);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(null);
  const [tableState, setTableState] = useState({
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getPausedOrderReleases(tableState.pageNumber);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tableState.pageNumber !== 1 || tableState.sortField || tableState.isAscSort) {
      getPausedOrderReleases(tableState.pageNumber, tableState.sortField?.toLowerCase(), tableState.isAscSort);
    }
    // eslint-disable-next-line
  }, [tableState]);

  let navigate = useNavigate();
  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;

  const getPausedOrderReleases = async (pageNumber = 1, sortBy = null, isAscSort = null) => {
    setGetOrdersApiRequestInProgress(true);
    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        sortBy,
        isAscSort
      };

      const response = await getAllPausedOrderReleases(data, context.msalInstance);

      if (response && response.paginatedList) {
        setAllOrders(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetOrdersApiRequestInProgress(false);
    }
  };

  const ordersTableRowEvents = {
    onClick: (row) => {
      navigate('/orders/view-order', {
        state: { orderId: row.orderId, receiverName: row?.receiverName ? row.receiverName : '' }
      });
    }
  };

  return (
    <section>
      <div>
        <div className="page-header">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item active>Orders</Breadcrumb.Item>
              <Breadcrumb.Item>Paused Order Releases</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        {isTableInitiallyEmpty === null ? (
          <Loader />
        ) : isTableInitiallyEmpty === true ? (
          <React.Fragment>
            <NoData
              mainMessage="You do not have paused order releases right now!"
              secondaryMessage=""
              icon={addUserIcon}
            />
          </React.Fragment>
        ) : (
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="customer-tbl-responsive">
                <TableComponentUpdated
                  columns={columns}
                  data={allOrders}
                  setTableState={setTableState}
                  rowClickable={true}
                  onRowClick={ordersTableRowEvents.onClick}
                  tableLoading={getOrdersApiRequestInProgress}
                  noDataIndicator={NoDataIndication}
                />
                <PaginationComponent
                  totalCount={totalCount}
                  recordsPerPage={recordsPerPage}
                  setTableState={setTableState}
                  tableState={tableState}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
