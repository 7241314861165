import { rolePermissions } from '../util/constants';

export const verifyPermissions = (action, instance) => {
  const currentAccount = instance.getActiveAccount();
  const loggedUserRole = currentAccount?.idTokenClaims?.extension_Role;
  const rolesArray = loggedUserRole ? loggedUserRole.split(',') : [];
  if (rolesArray.length > 0) {
    return rolesArray.some((item) => rolePermissions[item].includes(action));
  } else {
    return rolePermissions[rolesArray].includes(action);
  }
};
