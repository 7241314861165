import React from 'react';
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap';
import CustomerForm from './CustomerForm';

const CreateCustomer = (props) => {
  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item href="/customers">Customers</Breadcrumb.Item>
            <Breadcrumb.Item active>Create Customer</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Row>
        <Col lg={10} md={12}>
          <Card>
            <Card.Body>
              <CustomerForm />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CreateCustomer;
