import { addNotification } from '../app/actions';
import { get, post, put } from '../util/apiHelper';

export const getAllTradeAccounts = async (data, msalInstance) => {
  try {
    const response = await get(
      '/TradeAccount',
      {
        PageNumber: data.pageNumber,
        PageSize: data.pageSize,
        ...(data.searchKeyWord ? { SearchKeyWord: data.searchKeyWord } : {}),
        ...(data.sortBy ? { SortBy: data.sortBy } : {}),
        ...(data.isAscSort !== null && (data.isAscSort.toString() === 'true' || data.isAscSort.toString() === 'false')
          ? { IsAscSort: data.isAscSort }
          : {})
      },
      true,
      msalInstance
    );
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllUnitDetails = async (tradeAccountId, msalInstance, data) => {
  try {
    const response = await get(
      `/TradeAccount/UnitDetails/${tradeAccountId}`,
      {
        PageNumber: data.pageNumber,
        PageSize: data.pageSize,
        ...(data.searchKeyWord ? { SearchKeyWord: data.searchKeyWord } : {}),
        ...(data.sortBy ? { SortBy: data.sortBy } : {}),
        ...(data.isAscSort !== null && (data.isAscSort.toString() === 'true' || data.isAscSort.toString() === 'false')
          ? { IsAscSort: data.isAscSort }
          : {})
      },
      true,
      msalInstance
    );
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllTransactionHistory = async (tradeAccountId, data, msalInstance) => {
  try {
    const response = await get(
      `/TradeAccount/TransactionHistory/${tradeAccountId}`,
      {
        PageNumber: data.pageNumber,
        PageSize: data.pageSize,
        ...(data.searchKeyWord ? { SearchKeyWord: data.searchKeyWord } : {}),
        ...(data.sortBy ? { SortBy: data.sortBy } : {}),
        ...(data.isAscSort !== null && (data.isAscSort.toString() === 'true' || data.isAscSort.toString() === 'false')
          ? { IsAscSort: data.isAscSort }
          : {})
      },
      true,
      msalInstance
    );
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getTradeAccount = async (tradeAccountId, msalInstance) => {
  try {
    const response = await get(`/TradeAccount/${tradeAccountId}`, null, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const createTradeAccount = async (requestData, msalInstance) => {
  try {
    const response = await post('/TradeAccount', requestData, true, msalInstance);
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const createTransfer = async (page, dispatch, requestData, msalInstance) => {
  try {
    const response = await post('/TradeAccount/TransferCarbon', requestData, true, msalInstance);
    if (response) {
      dispatch(addNotification('success', page, `Carbon Transferred Successfully`));
      return true;
    }
    throw response;
  } catch (error) {
    dispatch(addNotification('danger', page, error.title));
    throw error;
  }
};

export const updateTradeAccount = async (tradeAccountId, requestData, msalInstance) => {
  try {
    const response = await put(`/TradeAccount/${tradeAccountId}`, requestData, true, msalInstance);
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const uploadCSVRecords = async (data, msalInstance) => {
  try {
    const response = await post(`/TradeAccount/BulkTransactionUpload`, data, true, msalInstance);
    if (response) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};
