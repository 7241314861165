import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createTradeAccount, getTradeAccount, updateTradeAccount } from '../../services/carbonManagementService';
import { Alert, Breadcrumb, Card, Button, Form, Spinner } from 'react-bootstrap';
import { tradeAccountFormSchema, tradeAccountUpdateSchema } from './formValidation';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FormSkeleton } from '../../components/formSkeleton';
// import { SkeletonTheme } from 'react-loading-skeleton';
import { tradeAccountETSTypes, tradeAccountUsageTypes } from '../../util/constants';

const ManageTradeAccount = (props) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const location = useLocation();

  const tradeAccountId = location?.state && location.state.tradeAccountId ? location.state.tradeAccountId : null;
  const [notification, setNotificationMessage] = useState(null);
  const [initialValues, setInitialValues] = useState({
    etsType: '',
    name: '',
    accountNumber: '',
    usageType: '',
    kyotoAccountNumber: ''
  });
  const [getTradeAccountApiRequestInProgress, setGetTradeAccountApiRequestInProgress] = useState(true);
  const [saveTradeAccountsApiRequestInProgress, setSaveTradeAccountsApiRequestInProgress] = useState(false);
  const tradeAccountName = location?.state && location.state.tradeAccountName ? location.state.tradeAccountName : null;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(tradeAccountId ? tradeAccountUpdateSchema : tradeAccountFormSchema),
    mode: 'all'
  });
  const selectedEtsType = watch('etsType');
  const resetForm = () => {
    reset();
  };

  const onSubmitHandler = (data) => {
    if (tradeAccountId) {
      tradeAccountUpdateSchema
        .validate(data)
        .then(function (value) {
          delete data['confirmPassword'];

          updateSingleTradeAccount(value);
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      tradeAccountFormSchema
        .validate(data)
        .then(function (value) {
          delete data['confirmPassword'];

          createNewTradeAccount(value);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  };

  const createNewTradeAccount = async (tradeAccountData) => {
    setSaveTradeAccountsApiRequestInProgress(true);
    try {
      const resp = await createTradeAccount(tradeAccountData, instance);
      if (resp) {
        await setNotifications('success', 'Trade Account Successfully Created', '/carbon-management');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveTradeAccountsApiRequestInProgress(false);
    }
  };

  const updateSingleTradeAccount = async (tradeAccountData) => {
    setSaveTradeAccountsApiRequestInProgress(true);
    try {
      const resp = await updateTradeAccount(tradeAccountId, tradeAccountData, instance);
      if (resp) {
        await setNotifications('success', 'Trade Account Successfully Updated');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveTradeAccountsApiRequestInProgress(false);
    }
  };

  const getTradeAccountDetails = async (tradeAccountId) => {
    setGetTradeAccountApiRequestInProgress(true);
    try {
      const response = await getTradeAccount(tradeAccountId, instance);
      if (response) {
        setInitialValues(response);
        reset(response);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetTradeAccountApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          navigate(redirectUrl);
        }
        if (tradeAccountId) {
          navigateToTradeAccountDetailsView();
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };

  const navigateToTradeAccountDetailsView = () => {
    navigate(`/carbon-management/trade-account-details`, {
      state: { tradeAccountId, tradeAccountName }
    });
  };

  useEffect(() => {
    if (tradeAccountId) {
      getTradeAccountDetails(tradeAccountId);
    } else {
      setGetTradeAccountApiRequestInProgress(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/carbon-management">Carbon</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{tradeAccountId ? tradeAccountName : 'Create Trade Account'}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8">
          <Card>
            <Card.Body>
              {notification && notification.message && (
                <Alert variant={notification.type}>{notification.message}</Alert>
              )}
              <Form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="none">
                <div className="row">
                  {getTradeAccountApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>ETS Type *</Form.Label>
                      <Form.Control as="select" type="select" {...register('etsType')} isInvalid={errors.etsType}>
                        <option value="">Select the ETS Type</option>
                        {Object.keys(tradeAccountETSTypes).map((etsType, index) => (
                          <option key={index} value={etsType}>
                            {tradeAccountETSTypes[etsType]}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.etsType?.message}</Form.Control.Feedback>
                    </div>
                  )}
                  {selectedEtsType == 3 && (
                    <div className="col-lg-6">
                      <Form.Label>Reference Instrument *</Form.Label>
                      <Form.Control
                        {...register('referenceInstrument')}
                        isInvalid={errors.referenceInstrument}
                        placeholder="Please trade account name"
                        autoComplete="none"
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.referenceInstrument?.message}
                      </Form.Control.Feedback>
                    </div>
                  )}
                  {getTradeAccountApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>Trade Account Name *</Form.Label>
                      <Form.Control
                        {...register('name')}
                        isInvalid={errors.name}
                        placeholder="Please trade account name"
                        autoComplete="none"
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                    </div>
                  )}
                  {getTradeAccountApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>Account Number *</Form.Label>
                      <Form.Control
                        {...register('accountNumber')}
                        isInvalid={errors.accountNumber}
                        placeholder="Please enter account number"
                        autoComplete="none"
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.accountNumber?.message}</Form.Control.Feedback>
                    </div>
                  )}
                  {getTradeAccountApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>Usage Type *</Form.Label>
                      <Form.Control as="select" type="select" {...register('usageType')} isInvalid={errors.usageType}>
                        <option value="">Select the Usage Type</option>
                        {Object.keys(tradeAccountUsageTypes).map((usageType, index) => (
                          <option key={index} value={usageType}>
                            {tradeAccountUsageTypes[usageType]}
                          </option>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.usageType?.message}</Form.Control.Feedback>
                    </div>
                  )}
                  {getTradeAccountApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>Kyoto Account Number</Form.Label>
                      <Form.Control
                        {...register('kyotoAccountNumber')}
                        isInvalid={errors.kyotoAccountNumber}
                        placeholder="Please enter kyoto account number"
                        autoComplete="none"
                        maxLength={30}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.kyotoAccountNumber?.message}</Form.Control.Feedback>
                    </div>
                  )}
                </div>
                <div className="form-buttons">
                  {tradeAccountId ? (
                    <button onClick={navigateToTradeAccountDetailsView} className="btn btn-light">
                      Cancel
                    </button>
                  ) : (
                    <span onClick={resetForm} className="btn btn-light">
                      Clear
                    </span>
                  )}
                  <Button
                    type="submit"
                    className="btn btn-primary m-2"
                    disabled={getTradeAccountApiRequestInProgress || saveTradeAccountsApiRequestInProgress}
                  >
                    {saveTradeAccountsApiRequestInProgress && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        variant="light"
                        style={{ marginRight: '10px' }}
                      />
                    )}
                    {tradeAccountId ? 'Update Trade Account' : 'Create Trade Account'}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ManageTradeAccount;
