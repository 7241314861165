import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Alert, Breadcrumb, Card, Col, Row, Tab, Tabs, Button } from 'react-bootstrap';
import CustomerForm from './CustomerForm';
import { deactivateCustomer, getCustomer, getOrderHistory, getTokenData } from '../../services/partyService';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import { OnboardStatus } from '../../util/enums';
import ConfirmAction from '../../components/ConfirmAction';
import { verifyPermissions } from '../../common/permissionVerifier';
import { formatDate, formatRoles } from '../../common/formatter';
import { approvalStatusArray, orderStatus } from '../../util/constants';
import UserAssignModal from './UserAssignModal';
import { recordsPerPage } from '../../util/constants';
import { assignUserstoCustomer, getAllUsers } from '../../services/userService';
import TableNoData from '../../components/TableNoData';
import { SearchBarUpdated } from '../../components/SearchBar';
import { createColumnHelper } from '@tanstack/react-table';
import TableComponentUpdated from '../../components/TableNew';
import PaginationComponent from '../../components/PaginationComponent';
import NoData from '../../components/NoData';

const columnHelper = createColumnHelper();

const ordersColumns = [
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Order No</span>,
    enableSorting: true
  }),
  columnHelper.accessor('createdOn', {
    cell: (info) => formatDate(info.getValue()),
    header: () => <span>Order Date</span>,
    enableSorting: true
  }),
  columnHelper.accessor('carbonUnits', {
    cell: (info) => info.getValue(),
    header: () => <span>Carbon Amount</span>,
    enableSorting: true
  }),
  columnHelper.accessor('status', {
    cell: (info) => orderStatus[info.getValue()],
    header: () => <span>Order Status</span>,
    enableSorting: true
  })
];

const tokenColumns = [
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Order No</span>,
    enableSorting: true
  }),
  columnHelper.accessor('createdDate', {
    cell: (info) => formatDate(info.getValue()),
    header: () => <span>Order Date</span>,
    enableSorting: true
  }),
  columnHelper.accessor('batch', {
    cell: (info) => info.getValue(),
    header: () => <span>Batch No</span>,
    enableSorting: true
  }),
  columnHelper.accessor('approvalStatus', {
    cell: (info) =>
      approvalStatusArray[info.getValue()]
        ? approvalStatusArray[info.getValue()].replace(/([A-Z])/g, ' $1').trim()
        : '-',
    header: () => <span>Token Status</span>,
    enableSorting: true
  })
];

const usersColumns = [
  columnHelper.accessor((user) => `${user.firstName || ''} ${user.lastName || ''}`, {
    id: 'firstName',
    cell: (info) => info.getValue(),
    header: () => <span>Name</span>,
    enableSorting: true
  }),
  columnHelper.accessor('roles', {
    cell: (info) => formatRoles(info.getValue()) || '-',
    header: () => <span>USER TYPE</span>,
    enableSorting: true
  })
];

const EditCustomer = (props) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [allOrders, setAllOrders] = useState([]);
  const [allTokens, setAllTokens] = useState([]);
  const [allUsers, setAllUsers] = useState();
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [notification, setNotificationMessage] = useState(null);
  const [getCustomerApiRequestInProgress, setGetCustomerApiRequestInProgress] = useState(false);
  const [getOrderHistoryApiRequestInProgress, setGetOrderHistoryApiRequestInProgress] = useState(false);
  const [changeCustomersStatusInProgress, setChangeCustomersStatusInProgress] = useState();
  const [getUsersApiRequestInProgress, setGetUsersApiRequestInProgress] = useState();
  const [getAssignedUsersApiRequestInProgress, setGetAssignedUsersApiRequestInProgress] = useState();
  const [getTokenDetailsApiRequestInProgress, setGetTokenDetailsApiRequestInProgress] = useState();
  const [assignUsersApiInprogess, setAssignUsersApiInprogess] = useState();
  const location = useLocation();
  const [showConfirmActionModal, setShowConfirmActionModal] = useState(false);
  const [showAssignUserModal, setShowAssignUserModal] = useState(false);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [totalTokenCount, setTotalTokenCount] = useState(0);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [selectedTab, setSelectedTab] = useState('customerDetails');
  const [userAssignModalKey, setUserAssignModalKey] = useState(new Date().toISOString());
  const [confirmActionModalKey, setConfirmActionModalKey] = useState(new Date().toISOString() + 1);

  const customerId = location.state && location.state.customerId ? location.state.customerId : null;
  const customerName = location.state && location.state.customerName ? location.state.customerName : null;

  const [ordersTableState, setOrdersTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });

  const [tokensTableState, setTokensTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });
  const [usersTableState, setUsersTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });

  const [isAllOrdersTableInitiallyEmpty, setIsAllOrdersTableInitiallyEmpty] = useState(null);
  const [isTokensTableInitiallyEmpty, setIsTokensTableInitiallyEmpty] = useState(null);
  const [isAssignedUsersTableInitiallyEmpty, setIsAssignedUsersTableInitiallyEmpty] = useState(null);

  const ordersTableRowEvents = {
    onClick: (row) => {
      navigate(`/orders/view-order`, {
        state: {
          orderId: row.id
        }
      });
    }
  };
  const handleOrdersSearchChange = (value) => {
    setOrdersTableState((prevState) => ({
      ...prevState,
      searchText: value,
      pageNumber: 1,
      sortField: null,
      isAscSort: null
    }));
  };
  const handleTokensSearchChange = (value) => {
    setTokensTableState((prevState) => ({
      ...prevState,
      searchText: value,
      pageNumber: 1,
      sortField: null,
      isAscSort: null
    }));
  };

  const getOrders = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetOrderHistoryApiRequestInProgress(true);
    const data = {
      pageNumber,
      pageSize: recordsPerPage,
      searchKeyWord,
      sortBy,
      isAscSort
    };
    try {
      const response = await getOrderHistory(customerId, data, instance);
      if (response) {
        setAllOrders(response.paginatedList);
        setTotalOrdersCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsAllOrdersTableInitiallyEmpty(true);
        } else {
          setIsAllOrdersTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetOrderHistoryApiRequestInProgress(false);
    }
  };

  const getTokens = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetTokenDetailsApiRequestInProgress(true);
    const data = {
      pageNumber,
      pageSize: recordsPerPage,
      searchKeyWord,
      sortBy,
      isAscSort
    };
    try {
      const response = await getTokenData(customerId, data, instance);
      if (response) {
        setAllTokens(response.paginatedList);
        setTotalTokenCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsTokensTableInitiallyEmpty(true);
        } else {
          setIsTokensTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetTokenDetailsApiRequestInProgress(false);
    }
  };

  const changeCustomerStatus = async () => {
    setChangeCustomersStatusInProgress(true);
    try {
      const data = { customerId, status: isActive ? OnboardStatus.SUSPENDED : OnboardStatus.ONBOARDED };
      const resp = await deactivateCustomer(data, null, instance);
      if (resp) {
        handleConfirmActionModalClose();
        setSelectedTab('customerDetails');
        await setNotifications('success', `Customer Successfully ${isActive ? ' Deactivated' : ' Activated'}`);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setChangeCustomersStatusInProgress(false);
      getCustomerDetails(customerId);
    }
  };

  const assignUsers = async (users) => {
    setAssignUsersApiInprogess(true);
    try {
      const response = await assignUserstoCustomer(customerId, users, instance);
      if (response) {
        setShowAssignUserModal(false);
        setSelectedTab('assignUsers');
        setNotifications('success', 'Users Successfully Assigned');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setAssignUsersApiInprogess(false);
      getCustomerDetails(customerId);
    }
  };

  const getUsers = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetUsersApiRequestInProgress(true);
    try {
      const data = {
        pageNumber,
        pageSize: 0,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllUsers(data, instance);
      if (response && response.paginatedList) {
        setAllUsers(response.paginatedList.map((i) => ({ ...i, label: `${i.firstName} ${i.lastName}`, value: i.id })));
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetUsersApiRequestInProgress(false);
    }
  };

  const getAssignedUsers = async (pageNumber = 1, searchKeyWord = customerId, sortBy = null, isAscSort = null) => {
    setGetAssignedUsersApiRequestInProgress(true);
    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllUsers(data, instance);
      if (response && response.paginatedList) {
        setAssignedUsers(response.paginatedList);
        setTotalUsersCount(response.totalCount);
        setSelectedTab(null);
        if (response.totalCount === 0 && pageNumber === 1) {
          setIsAssignedUsersTableInitiallyEmpty(true);
        } else {
          setIsAssignedUsersTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetAssignedUsersApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });

    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          navigate(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };

  useEffect(() => {
    getOrders();
    getTokens();
    getCustomerDetails(customerId);
    getUsers();
    getAssignedUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAssignedUsers();
    getUsers();
    // eslint-disable-next-line
  }, [selectedTab]);

  useEffect(() => {
    getOrders(
      ordersTableState.pageNumber,
      ordersTableState.searchText,
      ordersTableState.sortField?.toLowerCase(),
      ordersTableState.isAscSort
    );
    // eslint-disable-next-line
  }, [ordersTableState]);

  useEffect(() => {
    getTokens(
      tokensTableState.pageNumber,
      tokensTableState.searchText,
      tokensTableState.sortField?.toLowerCase(),
      tokensTableState.isAscSort
    );
    // eslint-disable-next-line
  }, [tokensTableState]);
  useEffect(() => {
    getAssignedUsers(
      usersTableState.pageNumber,
      usersTableState.searchText,
      usersTableState.sortField?.toLowerCase(),
      usersTableState.isAscSort
    );
    // eslint-disable-next-line
  }, [usersTableState]);

  const getCustomerDetails = async (customerId) => {
    setGetCustomerApiRequestInProgress(true);
    try {
      const response = await getCustomer(customerId, instance);
      if (response) {
        setCustomer(response);
        setIsActive(response.onboardStatus === OnboardStatus.ONBOARDED);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetCustomerApiRequestInProgress(false);
    }
  };

  const handleConfirmActionModalClose = () => {
    setConfirmActionModalKey(new Date().toISOString());
    setShowConfirmActionModal(false);
  };

  const handleConfirmActionModalShow = () => setShowConfirmActionModal(true);

  const handleAssignUsersModalState = (state) => {
    setUserAssignModalKey(new Date().toISOString());
    setShowAssignUserModal(state);
  };

  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;

  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item href="/customers">Customers</Breadcrumb.Item>
            <Breadcrumb.Item active>{customerName}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {!showAssignUserModal && notification && notification.message && (
          <Alert variant={notification.type}>{notification.message}</Alert>
        )}
        {!changeCustomersStatusInProgress &&
          !getCustomerApiRequestInProgress &&
          customer &&
          (customer.onboardStatus === OnboardStatus.SUSPENDED ||
            customer.onboardStatus === OnboardStatus.ONBOARDED) && (
            <Button
              className={isActive ? 'btn btn-danger m-2' : 'btn btn-primary m-2'}
              disabled={
                !verifyPermissions('write', instance) ||
                getCustomerApiRequestInProgress ||
                changeCustomersStatusInProgress
              }
              onClick={handleConfirmActionModalShow}
            >
              {`${isActive ? 'Deactivate' : 'Activate'} User`}
            </Button>
          )}
      </div>
      {!showConfirmActionModal && changeCustomersStatusInProgress ? (
        <Loader />
      ) : (
        <Row className="customer-account">
          <Col lg={8}>
            <Tabs defaultActiveKey={selectedTab}>
              <Tab title="Customer Details" eventKey="customerDetails">
                <Card>
                  <Card.Body>
                    <CustomerForm customerId={customerId} />
                  </Card.Body>
                </Card>
              </Tab>
              {/* -----------------------------
            Order History
            ----------------------------- */}
              <Tab title="Order History" eventKey="orderHistory">
                <Card>
                  <Card.Body>
                    <div>
                      <Row>
                        <Col lg={12}>
                          <SearchBarUpdated
                            searchHandler={handleOrdersSearchChange}
                            className="table-search search-in-card"
                          />
                        </Col>
                        <Col lg={12}>
                          {isAllOrdersTableInitiallyEmpty === null ? (
                            <Loader />
                          ) : isAllOrdersTableInitiallyEmpty === true ? (
                            <React.Fragment>
                              <NoData mainMessage="You do not have Orders right now!" />
                            </React.Fragment>
                          ) : (
                            <div className="customer-orderhistory-responsive">
                              <TableComponentUpdated
                                columns={ordersColumns}
                                data={allOrders}
                                setTableState={setOrdersTableState}
                                rowClickable={true}
                                onRowClick={ordersTableRowEvents.onClick}
                                tableLoading={getOrderHistoryApiRequestInProgress}
                                noDataIndicator={() => <NoDataIndication />}
                              />
                              <PaginationComponent
                                totalCount={totalOrdersCount}
                                recordsPerPage={recordsPerPage}
                                setTableState={setOrdersTableState}
                                tableState={ordersTableState}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              {/* -----------------------------
            Token Details
            ----------------------------- */}
              <Tab title="Tokens Details" eventKey="tokenDetails">
                <Card>
                  <Card.Body>
                    <div>
                      <Row>
                        <Col lg={12}>
                          <SearchBarUpdated
                            searchHandler={handleTokensSearchChange}
                            className="table-search search-in-card"
                          />
                        </Col>
                        <Col lg={12}>
                          {isTokensTableInitiallyEmpty === null ? (
                            <Loader />
                          ) : isTokensTableInitiallyEmpty === true ? (
                            <React.Fragment>
                              <NoData mainMessage="You do not have Tokens right now!" />
                            </React.Fragment>
                          ) : (
                            <div className="order-tbl-responsive">
                              <TableComponentUpdated
                                columns={tokenColumns}
                                data={allTokens}
                                setTableState={setTokensTableState}
                                rowClickable={false}
                                tableLoading={getTokenDetailsApiRequestInProgress}
                                noDataIndicator={() => <NoDataIndication />}
                              />
                              <PaginationComponent
                                totalCount={totalTokenCount}
                                recordsPerPage={recordsPerPage}
                                setTableState={setTokensTableState}
                                tableState={tokensTableState}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              {/* -----------------------------
            Assign Users
            ----------------------------- */}
              <Tab title="Users" eventKey="assignUsers">
                <Card>
                  <Card.Body>
                    <div>
                      <Row>
                        <Col lg={12}>
                          <div className="d-flex  btn-in-card">
                            <Button
                              className="btn btn-primary m-2"
                              disabled={
                                !verifyPermissions('write', instance) ||
                                getCustomerApiRequestInProgress ||
                                changeCustomersStatusInProgress ||
                                getUsersApiRequestInProgress ||
                                assignUsersApiInprogess
                              }
                              onClick={() => handleAssignUsersModalState(true)}
                            >
                              Assign Users
                            </Button>
                          </div>
                        </Col>
                        <Col lg={12}>
                          {isAssignedUsersTableInitiallyEmpty === null ? (
                            <Loader />
                          ) : isAssignedUsersTableInitiallyEmpty === true ? (
                            <React.Fragment>
                              <NoData mainMessage="You do not have Assigned Users right now!" />
                            </React.Fragment>
                          ) : (
                            <div className="order-tbl-responsive">
                              <TableComponentUpdated
                                columns={usersColumns}
                                data={assignedUsers}
                                setTableState={setUsersTableState}
                                rowClickable={false}
                                tableLoading={getAssignedUsersApiRequestInProgress}
                                noDataIndicator={() => <NoDataIndication />}
                              />
                              <PaginationComponent
                                totalCount={totalUsersCount}
                                recordsPerPage={recordsPerPage}
                                setTableState={setUsersTableState}
                                tableState={usersTableState}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
      <ConfirmAction
        key={confirmActionModalKey}
        show={showConfirmActionModal}
        title={isActive ? 'Deactivate Customer' : 'Activate Customer'}
        content={'Are you sure you want to ' + (isActive ? 'deactivate' : 'activate') + ' this customer?'}
        action={isActive ? 'Deactivate' : 'Activate'}
        handleClose={handleConfirmActionModalClose}
        onDeleteConfirm={changeCustomerStatus}
        isLoading={changeCustomersStatusInProgress}
        actionButtonVariant="danger"
        onActionConfirm={changeCustomerStatus}
      />

      <UserAssignModal
        key={userAssignModalKey}
        title={'Assign Users'}
        users={allUsers}
        show={showAssignUserModal}
        handleClose={() => handleAssignUsersModalState(false)}
        onAssignUsers={assignUsers}
        instance={instance}
        isLoading={assignUsersApiInprogess}
        action="Assign Users"
        notification={notification}
      />
    </div>
  );
};

export default EditCustomer;
