import React, { useState } from 'react';
import { Modal, Button, Spinner, Alert } from 'react-bootstrap';
import Select from 'react-select';
import { verifyPermissions } from '../../common/permissionVerifier';
import makeAnimated from 'react-select/animated';

const UserAssignModal = ({
  show,
  handleClose,
  onAssignUsers,
  title,
  action,
  isLoading,
  actionButtonVariant,
  users,
  instance,
  notification
}) => {
  const animatedComponents = makeAnimated();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const defaultGUID = '00000000-0000-0000-0000-000000000000';
  const[mulitSelectInputKey, setMulitSelectInputKey] = useState(new Date().toISOString());

  const handleCloseEvent = () => {
    setMulitSelectInputKey(new Date().toISOString());
    handleClose();
  }

  return (
    <Modal show={show} onHide={handleCloseEvent} size="lg" className="assginUserModal">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
      {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
        <div className="row">
          <div className="col-sm-6">
            <Select
              key={mulitSelectInputKey}
              placeholder="Select the Users"
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              isDisabled={!verifyPermissions('write', instance)}
              options={users?.filter((item) => item?.partyId === defaultGUID)}
              onChange={(selectedOptions) => {
                setSelectedUsers(selectedOptions);
              }}
              classNamePrefix="react-select"
            />
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleCloseEvent}>
          Cancel
        </Button>
        <Button
          variant={actionButtonVariant}
          onClick={() => onAssignUsers(selectedUsers)}
          disabled={isLoading || selectedUsers.length < 1}
        >
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              variant="light"
              style={{ marginRight: '10px' }}
            />
          )}
          {action}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserAssignModal;
