import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import transferIcon from '../../assets/images/icons/repeat.svg';
import createIcon from '../../assets/images/icons/plus.svg';
import { NavLink } from 'react-router-dom';

import { getAllTradeAccounts } from '../../services/carbonManagementService';
import { AppContext } from '../../app/App';
import NoData from '../../components/NoData';
import Loader from '../../components/Loader';
import { recordsPerPage, tradeAccountETSTypes } from '../../util/constants';
import TableNoData from '../../components/TableNoData';
import { padLeadingZeros } from '../../common/formatter';
import { createColumnHelper } from '@tanstack/table-core';
import { SearchBarUpdated } from '../../components/SearchBar';
import TableComponentUpdated from '../../components/TableNew';
import PaginationComponent from '../../components/PaginationComponent';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('name', {
    cell: (info) => info.getValue(),
    header: () => <span>Trade Account</span>,
    enableSorting: true
  }),
  columnHelper.accessor('accountNumber', {
    cell: (info) => info.getValue(),
    header: () => <span>Account No</span>,
    enableSorting: true
  }),
  columnHelper.accessor('etsType', {
    cell: (info) => tradeAccountETSTypes[info.getValue()],
    header: () => <span>Ets Type</span>,
    enableSorting: false
  }),
  columnHelper.accessor('totalCarbonUnits', {
    cell: (info) =>
      info.getValue()
        ? Number(padLeadingZeros(info.getValue())) !== 0
          ? padLeadingZeros(info.getValue()).toLowerCase().includes('tonnes')
            ? padLeadingZeros(info.getValue())
            : padLeadingZeros(info.getValue()) + ' Tonnes'
          : 0 + ' Tonnes'
        : 0 + ' Tonnes',
    header: () => <span>Available Carbon Units</span>,
    enableSorting: false
  }),
  columnHelper.accessor('isActive', {
    cell: (info) => (info.getValue() ? 'Active' : 'Inactive'),
    header: () => <span>Status</span>,
    enableSorting: false
  })
];

const CarbonManagement = () => {
  const context = React.useContext(AppContext);
  let navigate = useNavigate();
  const [allTradeAccounts, setAllTradeAccounts] = useState([]);
  const [getTradeAccountsApiRequestInProgress, setGetTradeAccountsApiRequestInProgress] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(null);

  const [tableState, setTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });

  const tradeAccountsTableRowEvents = {
    onClick: (row) => {
      navigate(`/carbon-management/trade-account-details`, {
        state: { tradeAccountId: row.id, tradeAccountName: row.name }
      });
    }
  };
  const handleTradeAccountsSearchChange = (value) => {
    setTableState((prevState) => ({
      ...prevState,
      searchText: value,
      pageNumber: 1
    }));
  };

  const getTradeAccounts = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetTradeAccountsApiRequestInProgress(true);
    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort
      };
      const response = await getAllTradeAccounts(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllTradeAccounts(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetTradeAccountsApiRequestInProgress(false);
    }
  };
  useEffect(() => {
    getTradeAccounts();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getTradeAccounts(
      tableState.pageNumber,
      tableState.searchText,
      tableState.sortField?.toLowerCase(),
      tableState.isAscSort
    );
    // eslint-disable-next-line
  }, [tableState]);
  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;

  return (
    <section>
      <div>
        <div className="page-header multiple-buttons">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Carbon Management</Breadcrumb.Item>
            </Breadcrumb>
            {/* todo: remove previous search bar and do the relevant renames  */}
            {/* <SearchBar apiRequest={getCustomers} className="table-search" /> */}
            <SearchBarUpdated searchHandler={handleTradeAccountsSearchChange} className="table-search" />
            <div className="button-wrapper2">
              <div className="block-1">
                <NavLink to="/carbon-management/create-trade-account" className="btn btn-outline-secondary">
                  <img src={createIcon} alt="add user icon" />
                  Create Trade Account
                </NavLink>
                <NavLink to="/carbon-management/upload-transactions" className="btn btn-outline-secondary">
                  Upload CSV
                </NavLink>
              </div>
              <div className="block-2">
                <NavLink to="/carbon-management/transfer-carbon" className="btn btn-primary">
                  <img src={transferIcon} alt="add user icon" />
                  Transfer Carbon
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        {isTableInitiallyEmpty === null ? (
          <Loader />
        ) : isTableInitiallyEmpty === true ? (
          <React.Fragment>
            <NoData
              link="/carbon-management/create-trade-account"
              buttonText="Create New Trade Account"
              mainMessage="You do not have Trade Accounts right now!"
              secondaryMessage="Please click 'Create New Trade Account' to add a new Trade Account"
              icon={createIcon}
            />
          </React.Fragment>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="carbon-tbl-responsive">
                <TableComponentUpdated
                  columns={columns}
                  data={allTradeAccounts}
                  setTableState={setTableState}
                  rowClickable={true}
                  onRowClick={tradeAccountsTableRowEvents.onClick}
                  tableLoading={getTradeAccountsApiRequestInProgress}
                  noDataIndicator={NoDataIndication}
                />
                <PaginationComponent
                  totalCount={totalCount}
                  recordsPerPage={recordsPerPage}
                  setTableState={setTableState}
                  tableState={tableState}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default CarbonManagement;
