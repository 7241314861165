import { get, post, put } from '../util/apiHelper';

export const getAllUsers = async (data, msalInstance) => {
  try {
    const response = await get(
      '/Users',
      {
        PageNumber: data.pageNumber,
        PageSize: data.pageSize,
        ...(data.searchKeyWord ? { SearchKeyWord: data.searchKeyWord } : {}),
        ...(data.sortBy ? { SortBy: data.sortBy } : {}),
        ...(data.isAscSort !== null && (data.isAscSort.toString() === 'true' || data.isAscSort.toString() === 'false')
          ? { IsAscSort: data.isAscSort }
          : {})
      },
      true,
      msalInstance
    );
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getUser = async (subjectId, msalInstance) => {
  try {
    const response = await get(`/Users/GetById/${subjectId}`, null, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const createUser = async (requestData, msalInstance) => {
  try {
    const response = await post('/Users', requestData, true, msalInstance);
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (subjectId, requestData, msalInstance) => {
  try {
    const response = await put(`/Users/${subjectId}`, requestData, true, msalInstance);
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const getPlatformUsers = async (msalInstance) => {
  try {
    const response = await get('/Users/PlatformUsers', null, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const assignUserstoCustomer = async (partyId, requestData, msalInstance) => {
  try {
    const response = await post(`/Users/CustomerWiseUsers/${partyId}`, requestData, true, msalInstance);
    if (response) {
      return true;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};