import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createCustomer,
  getCertificateTemplates,
  getCustomer,
  getXeroContactsList,
  updateCustomer
} from '../../services/partyService';
import { getPlatformUsers } from '../../services/userService';
import { Alert, Button, Form, Spinner, Col, Row, InputGroup } from 'react-bootstrap';
import { customerFormSchema, customerUpdateSchema } from './formValidation';
import { NavLink, useNavigate } from 'react-router-dom';
import { FormSkeleton } from '../../components/formSkeleton';
import Select from 'react-select';
import { verifyPermissions } from '../../common/permissionVerifier';
import { ECertificateModal } from '../../components/ECertificateModal';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import { tradeAccountUsageTypes } from '../../util/constants';
import { getAllTradeAccounts } from '../../services/carbonManagementService';
import { currencyList } from '../../util/currencies';
import minOrder from '../../assets/images/icons/min.svg';
import { countries } from 'countries-list';
import { appConfigs } from '../../util/appConfigs';

const CustomerForm = ({ customerId }) => {
  let navigate = useNavigate();
  const countryList = Object.keys(countries).map((code) => ({
    code,
    name: countries[code].name
  }));
  const { instance } = useMsal();
  const [sameAsPrimary, setSameAsPrimary] = useState(false);
  const [notification, setNotificationMessage] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: '',
    partyType: '',
    postalAddressPostBox: '',
    postalAddressStreetName: '',
    postalAddressAdditionalStreetName: '',
    postalAddressBuildingName: '',
    postalAddressBuildingNumber: '',
    postalAddressCityName: '',
    postalAddressPostalZone: '',
    postalAddressCountry: '',
    primaryContactTitle: 'Mr',
    primaryContactName: '',
    billingContactEmail: '',
    primaryContactEmail: '',
    primaryContactTelephone: '',
    accountManagerId: appConfigs.accountManagerId,
    relationshipManagerId: appConfigs.relationshipManagerId,
    primaryContactForBilling: false,
    billingContactTitle: 'Mr',
    billingContactName: '',
    billingContactTelephone: '',
    billingContactNote: '',
    note: '',
    priceBands: [{ fromTonnes: null, toTonnes: null, marginPercentage: null }],
    currentRegionTax: null,
    australianTax: null,
    basePrice: null,
    minimumCarbon: null
  });

  const [getCustomerApiRequestInProgress, setGetCustomerApiRequestInProgress] = useState(true);
  const [getXeroContactsApiRequestInProgress, setGetXeroContactsApiRequestInProgress] = useState(false);
  const [saveCustomersApiRequestInProgress, setSaveCustomersApiRequestInProgress] = useState(false);
  const [getPlatformUsersApiRequestInProgress, setGetPlatformUsersApiRequestInProgress] = useState(false);
  const [platformUsers, setPlatformUsers] = useState([]);
  const [xeroReferenceContacts, setXeroReferenceContacts] = useState([]);
  const [showCertificateTemplate, setShowCertificateTemplate] = useState(false);
  const [getTradeAccountsApiRequestInProgress, setGetTradeAccountsApiRequestInProgress] = useState(true);
  const [allTradeAccounts, setAllTradeAccounts] = useState([]);
  const [eCertificateTemplates, setECertificateTemplates] = useState([]);
  const [getTemplatesAPIInProgress, setGetTemplatesAPIInProgress] = useState(true);
  const [logoRemoved, setLogoRemoved] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    setValue,
    onBlur,
    watch,
    onTouched,
    getValues,
    control
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(customerId ? customerUpdateSchema : customerFormSchema),
    mode: 'all'
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'priceBands'
  });
  const selectedCurrency = watch('currency');
  const selectedCountry = watch('region');
  const eCertificateTemplate = watch('eCertificateTemplateId');
  const defaultCarbonAccountId = watch('defaultCarbonAccountId');

  const getCurrencyCodeGivenCountryCode = (countryCode) => {
    const countryInfo = countries[countryCode];
    if (countryInfo) {
      return countryInfo.currency[0];
    }
    return null;
  };
  useEffect(() => {
    if (selectedCountry) setValue('currency', getCurrencyCodeGivenCountryCode(selectedCountry));
    // eslint-disable-next-line
  }, [selectedCountry]);

  const reader = new FileReader();
  const [uppy] = useState(
    () =>
      new Uppy({
        restrictions: {
          maxFileSize: 2000000,
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
          allowedFileTypes: ['image/*']
        }
      })
  );
  useEffect(() => {
    uppy.on('file-added', (result) => {
      setLogoRemoved(false);
      setFile(result.data);
    });

    uppy.on('file-removed', (file, reason) => {
      setLogoRemoved(true);
      setValue('customerLogo', null);
    });

    return () => {};
    // eslint-disable-next-line
  }, [uppy]);

  useEffect(() => {
    /**This is to revalidate customerLogo field when user removes the file */
    const revalidateCustomerLogoField = async () => {
      await trigger('customerLogo');
    };
    if (logoRemoved) revalidateCustomerLogoField();
    // eslint-disable-next-line
  }, [logoRemoved]);

  const setFile = async (files) => {
    if (files) {
      reader.readAsDataURL(files);
      reader.onloadend = async () => {
        const imageBase64 = reader.result.replace('application/octet-stream', 'image/jpeg');
        setValue('customerLogo', imageBase64);
        // Trigger revalidation for the specific customerLogo field when user has added new file
        await trigger('customerLogo');
      };
    }
  };

  const resetForm = () => {
    reset();
    setSameAsPrimary(false);
  };

  const onSubmitHandler = (data) => {
    const paymentMatrixReferenceObject = data?.isRunAutomatically
      ? {
          name: data.referenceName,
          allowanceType: data.allowanceType,
          basePrice: data.basePrice,
          priceBands: [...data.priceBands],
          currency: data.currency,
          currentRegionTax: data.currentRegionTax,
          australianTax: data.australianTax
        }
      : null;
    if (customerId) {
      try {
        delete data.referenceName;
        delete data.allowanceType;
        delete data.basePrice;
        delete data.priceBands;
        delete data.currency;
        delete data.currentRegionTax;
        delete data.australianTax;

        updateSingleCustomer({ ...data, paymentMatrixReference: paymentMatrixReferenceObject });
      } catch (error) {
        console.log(error);
      } finally {
      }
    } else {
      try {
        setGetCustomerApiRequestInProgress(true);
        delete data.referenceName;
        delete data.allowanceType;
        delete data.basePrice;
        delete data.priceBands;
        delete data.currency;
        delete data.currentRegionTax;
        delete data.australianTax;
        createNewCustomer({ ...data, paymentMatrixReference: paymentMatrixReferenceObject });
      } catch (error) {
        console.log(error);
      } finally {
        setGetCustomerApiRequestInProgress(false);
      }
    }
  };

  const createNewCustomer = async (customerData) => {
    setSaveCustomersApiRequestInProgress(true);
    try {
      const resp = await createCustomer(customerData, instance);
      if (resp) {
        await setNotifications('success', 'Customer Successfully Created', '/customers');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveCustomersApiRequestInProgress(false);
    }
  };

  const updateSingleCustomer = async (customerData) => {
    setSaveCustomersApiRequestInProgress(true);
    try {
      const resp = await updateCustomer(customerId, customerData, instance);
      if (resp) {
        await setNotifications('success', 'Customer Successfully Updated', '/customers');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveCustomersApiRequestInProgress(false);
    }
  };

  const getCustomerDetails = async (customerId) => {
    setGetCustomerApiRequestInProgress(true);
    try {
      const response = await getCustomer(customerId, instance);
      const formValues = { ...response };
      if (formValues?.isRunAutomatically) {
        formValues.referenceName = response.paymentMatrixReference.name;
        formValues.allowanceType = response.paymentMatrixReference.allowanceType;
        formValues.basePrice = response.paymentMatrixReference.basePrice;
        formValues.priceBands = [...response.paymentMatrixReference.priceBands];
        formValues.currency = response.paymentMatrixReference.currency;
        formValues.currentRegionTax = response.paymentMatrixReference.currentRegionTax;
        formValues.australianTax = response.paymentMatrixReference.australianTax;
        delete formValues.paymentMatrixReference;

        let imgResponse = await fetch(formValues.customerLogo);
        // Check if the image fetch was unsuccessful
        if (!imgResponse.ok) {
          setNotifications('danger', `Failed to fetch customer logo. Status: ${imgResponse?.status}`);
        } else {
          let imgBlob = await imgResponse.blob();
          // Check if the image blob is empty or invalid
          if (!imgBlob || !(imgBlob instanceof Blob)) {
            setNotifications('danger', 'Invalid customer logo data.');
          } else {
            uppy.addFile({
              name: formValues.customerLogo.split('/').pop(),
              type: 'image/' + formValues.customerLogo.split('.').pop(),
              data: imgBlob
            });
          }
        }
      } else {
        delete formValues.paymentMatrixReference;
        delete formValues.eCertificateTemplateId;
        delete formValues.customerLogo;
      }
      if (formValues) {
        setInitialValues(formValues);
        reset(formValues);
        setSameAsPrimary(formValues.primaryContactForBilling);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetCustomerApiRequestInProgress(false);
    }
  };

  const getXeroContacts = async () => {
    setGetXeroContactsApiRequestInProgress(true);
    try {
      const response = await getXeroContactsList(instance);
      if (response) {
        setXeroReferenceContacts(response.map((i) => ({ ...i, label: `${i.name} `, value: i.contactID })));
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetXeroContactsApiRequestInProgress(false);
    }
  };

  const getAllTemplates = async () => {
    setGetTemplatesAPIInProgress(true);
    try {
      const response = await getCertificateTemplates({ pageNumber: 1, pageSize: 100000 }, instance);
      const modifiedResponse = response?.paginatedList.map((template) => ({
        ...template,
        label: `${template.name} `,
        value: template.id
      }));
      if (response) {
        setECertificateTemplates(modifiedResponse);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error?.title);
    } finally {
      setGetTemplatesAPIInProgress(false);
    }
  };

  const fetchPlatformUsers = async () => {
    setGetPlatformUsersApiRequestInProgress(true);
    try {
      const response = await getPlatformUsers(instance);
      if (response) {
        setPlatformUsers(response.map((i) => ({ ...i, label: `${i.firstName} ${i.lastName}`, value: i.id })));
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetPlatformUsersApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          navigate(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };
  const getTradeAccounts = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    try {
      const data = {
        pageNumber,
        searchKeyWord,
        sortBy,
        isAscSort
      };
      const response = await getAllTradeAccounts(data, instance);
      if (response && response.paginatedList) {
        setAllTradeAccounts(response.paginatedList);
        return response.paginatedList;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetTradeAccountsApiRequestInProgress(false);
    }
  };

  useEffect(() => {
    if (sameAsPrimary) {
      setValue('billingContactTitle', getValues('primaryContactTitle'));
      setValue('billingContactName', getValues('primaryContactName'));
      setValue('billingContactEmail', getValues('primaryContactEmail'));
      setValue('billingContactTelephone', getValues('primaryContactTelephone'));
    } else {
      setValue('billingContactTitle', 'Mr');
      setValue('billingContactName', '');
      setValue('billingContactEmail', '');
      setValue('billingContactTelephone', '');
    }
    // eslint-disable-next-line
  }, [sameAsPrimary]);

  useEffect(() => {
    fetchPlatformUsers();
    getXeroContacts();
    getTradeAccounts();
    getAllTemplates();
    if (customerId) {
      getCustomerDetails(customerId);
    } else {
      setGetCustomerApiRequestInProgress(false);
    }
    // eslint-disable-next-line
  }, []);

  const handlePreviewECertificate = () => {
    setShowCertificateTemplate(true);
  };
  const handleCancelECertificatePreviewModal = (e) => {
    setShowCertificateTemplate(false);
  };

  return (
    <React.Fragment>
      {/* E-Certificate preview */}
      <ECertificateModal
        show={showCertificateTemplate}
        handleOnCancel={handleCancelECertificatePreviewModal}
        eCertificateTemplate={eCertificateTemplate}
        defaultCarbonAccountId={defaultCarbonAccountId}
        recipientName={null}
        orderReleaseId={null}
      />

      {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
      <Form onSubmit={handleSubmit(onSubmitHandler)} noValidate autoComplete="none">
        <Row>
          <Col className="col-12">
            <Form.Check
              className="form-check"
              type="checkbox"
              label="Run order workflow automatically (Applicable for the carbon cancellation certificate customer)"
              {...register('isRunAutomatically')}
              isInvalid={errors.isRunAutomatically}
              autoComplete="none"
              disabled={!verifyPermissions('write', instance)}
            />
          </Col>
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={6}>
              <Form.Label>Company / Individual Name *</Form.Label>
              <Form.Control
                placeholder="Please enter Company / Individual Name"
                {...register('name')}
                isInvalid={errors.name}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={6}>
              <Form.Label>Division / Group</Form.Label>
              <Form.Control
                {...register('partyType')}
                isInvalid={errors.partyType}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.partyType?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={6}>
              <Form.Label>Xero Reference Contact *</Form.Label>
              <Controller
                name="accountManagerId"
                control={control}
                {...register('xeroRefId')}
                render={({ field, fieldState: { invalid }, formState }) => {
                  return (
                    <React.Fragment>
                      <Select
                        isSearchable={true}
                        isClearable={true}
                        placeholder="Please select Xero Reference Contact"
                        options={xeroReferenceContacts}
                        isInvalid={invalid}
                        onTouched={onTouched}
                        onBlur={onBlur}
                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                        defaultValue={xeroReferenceContacts.filter((contact) => contact.contactID === field.value)[0]}
                        // className={`form-control ${invalid ? 'is-invalid' : ''}`}
                        // styles={customStyles}
                        disabled={!verifyPermissions('write', instance)}
                        classNamePrefix="react-select"
                        isDisabled={!verifyPermissions('write', instance)}
                      />
                      {invalid && <div className="error">{errors.xeroRefId?.message}</div>}
                    </React.Fragment>
                  );
                }}
              />
            </Col>
          )}

          {/* ===================================== NEW FIELDS REQUIRED FOR E-CERTIFICATE =================================== */}

          <Col className="col-12 d-flex flex-row">
            <Row>
              <Col lg={6}>
                <Form.Label>Brand</Form.Label>
                <Form.Control
                  placeholder="Please enter Brand"
                  {...register('brand')}
                  isInvalid={errors.brand}
                  autoComplete="none"
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                {errors.brand && <div className="error">{errors.brand?.message}</div>}
              </Col>

              <Col lg={6}>
                <Form.Label>Product Name *</Form.Label>
                <Form.Control
                  placeholder="Please enter Product Name"
                  {...register('productName')}
                  isInvalid={errors.productName}
                  autoComplete="none"
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                {errors.productName && <div className="error">{errors.productName?.message}</div>}
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                {getTradeAccountsApiRequestInProgress ? (
                  <FormSkeleton size="6" />
                ) : (
                  <>
                    <Form.Label>Default Carbon Account *</Form.Label>
                    <Form.Control
                      as="select"
                      {...register('defaultCarbonAccountId')}
                      isInvalid={!!errors.defaultCarbonAccountId}
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Please select Carbon Account
                      </option>
                      {allTradeAccounts
                        .filter((account) => tradeAccountUsageTypes[account.usageType] === tradeAccountUsageTypes[2])
                        .map((account, index) => (
                          <option key={index} value={account.id}>
                            {account.name}
                          </option>
                        ))}
                    </Form.Control>
                    {errors.defaultCarbonAccountId && (
                      <div className="error">{errors.defaultCarbonAccountId?.message}</div>
                    )}
                  </>
                )}

                <Form.Label>Product Display Text *</Form.Label>
                <Form.Control
                  placeholder="Please enter Product Display Text"
                  {...register('productDisplayText')}
                  isInvalid={errors.productDisplayText}
                  autoComplete="none"
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                {errors.productDisplayText && <div className="error">{errors.productDisplayText?.message}</div>}

                {getTemplatesAPIInProgress ? (
                  <FormSkeleton size="6" />
                ) : (
                  <>
                    <Form.Label>Carbon Cancellation Certificate Template *</Form.Label>
                    <Controller
                      name="eCertificateTemplateId"
                      control={control}
                      render={({ field, fieldState: { invalid } }) => {
                        const existingValue = field?.value;
                        // Set the default value based on the existing value in the form
                        const defaultValue = existingValue
                          ? eCertificateTemplates.find((option) => option.id == existingValue)
                          : null;
                        return (
                          <React.Fragment>
                            <Select
                              isSearchable={false}
                              isClearable={false}
                              placeholder="Please Select Carbon Cancellation Certificate Template"
                              options={eCertificateTemplates}
                              isInvalid={invalid}
                              onTouched={onTouched}
                              onBlur={onBlur}
                              onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.id : '')}
                              defaultValue={defaultValue}
                              disabled={!verifyPermissions('write', instance)}
                              classNamePrefix="react-select"
                              isDisabled={!verifyPermissions('write', instance)}
                            />
                            {invalid && <div className="error">{errors.eCertificateTemplateId?.message}</div>}
                          </React.Fragment>
                        );
                      }}
                    />
                  </>
                )}

                <Col>
                  {eCertificateTemplate && defaultCarbonAccountId && (
                    <Button variant="link" style={{ padding: 0 }} onClick={handlePreviewECertificate}>
                      View Carbon Cancellation Certificate Template
                    </Button>
                  )}
                </Col>
              </Col>

              <Col lg={6}>
                <Form.Label>Customer Logo *</Form.Label>
                <Controller
                  name="customerLogo"
                  control={control}
                  render={() => (
                    <Dashboard
                      uppy={uppy}
                      note="Preferred image types: image/svg, image/png, image/jpeg, Size 2MB or less"
                      width={600}
                      height={200}
                      showLinkToFileUploadResult={true}
                      proudlyDisplayPoweredByUppy={false}
                      showProgressDetails={true}
                      hideUploadButton={true}
                    />
                  )}
                />
                {errors.customerLogo && <div className="invalid-logo">Customer Logo is a required field</div>}
              </Col>
              <Col lg={6}>
                <Form.Label>Minimum Carbon (kg) *</Form.Label>
                <Form.Control
                  placeholder="Minimum carbon amount purchasable"
                  {...register('minimumCarbon')}
                  isInvalid={errors.minimumCarbon}
                  autoComplete="none"
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.minimumCarbon?.message}</Form.Control.Feedback>
              </Col>
            </Row>
          </Col>

          <Col lg={12}>
            <h6 className="Payment-Matrix">
              <strong>Payment Matrix Configuration</strong>
            </h6>
          </Col>

          <Col lg={12}>
            <Row>
              <Col lg={6}>
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  placeholder="Please enter Name"
                  {...register('referenceName')}
                  isInvalid={errors.referenceName}
                  autoComplete="none"
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.referenceName?.message}</Form.Control.Feedback>
              </Col>
              <Col lg={6}>
                <Form.Label>Country *</Form.Label>
                <Form.Control
                  as="select"
                  {...register('region')}
                  isInvalid={!!errors.region}
                  value={selectedCountry}
                  defaultValue={''}
                >
                  <option value="" disabled>
                    Please select a country
                  </option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country.code}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
                {errors.region && <div className="error">{errors.region?.message}</div>}
              </Col>
              <Col lg={6}>
                <Form.Label>Currency *</Form.Label>
                <Form.Control
                  as="select"
                  {...register('currency')}
                  isInvalid={!!errors.currency}
                  value={selectedCurrency}
                  defaultValue={''}
                >
                  <option value="" disabled>
                    Please select The Currency
                  </option>
                  {currencyList.map((currency, index) => (
                    <option key={index} value={currency.code}>
                      {currency.code}
                    </option>
                  ))}
                </Form.Control>
                {errors.currency && <div className="error">{errors.currency?.message}</div>}
              </Col>
              <Col lg={6}>
                <Form.Label>Allowance type *</Form.Label>
                <Form.Control
                  placeholder="Please enter Allowance type"
                  {...register('allowanceType')}
                  isInvalid={errors.allowanceType}
                  autoComplete="none"
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                {errors.allowanceType && <div className="error">{errors.allowanceType?.message}</div>}
              </Col>
              {selectedCountry !== 'AU' && (
                <Col lg={6}>
                  <Form.Label>Current Region Tax (%) *</Form.Label>
                  <Form.Control
                    placeholder="Please enter tax amount"
                    {...register('currentRegionTax')}
                    isInvalid={errors.currentRegionTax}
                    autoComplete="none"
                    disabled={!verifyPermissions('write', instance)}
                  ></Form.Control>
                  {errors.currentRegionTax && <div className="error">{errors.currentRegionTax?.message}</div>}
                </Col>
              )}

              <Col lg={6}>
                <Form.Label>Australian Tax (%) *</Form.Label>
                <Form.Control
                  placeholder="Please enter tax percentage"
                  {...register('australianTax')}
                  isInvalid={errors.australianTax}
                  autoComplete="none"
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                {errors.australianTax && <div className="error">{errors.australianTax?.message}</div>}
              </Col>
              <Col lg={6}>
                <Form.Label>Base price *</Form.Label>
                <Form.Control
                  placeholder="Please enter basic price"
                  {...register('basePrice')}
                  isInvalid={errors.basePrice}
                  autoComplete="none"
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                {errors.basePrice && <div className="error">{errors.basePrice?.message}</div>}
              </Col>
              <Col className="" lg={12}>
                <table className="table table-margins">
                  <thead>
                    <tr>
                      <th>
                        <Form.Label>From (tonnes) *</Form.Label>
                      </th>
                      <th>
                        <Form.Label>To (tonnes) *</Form.Label>
                      </th>
                      <th>
                        <Form.Label>Margin (%) *</Form.Label>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map((entry, index) => (
                      <tr key={entry.id}>
                        <td>
                          <Form.Control
                            placeholder="Please enter from (tonnes)"
                            {...register(`priceBands.${index}.fromTonnes`)}
                            isInvalid={errors.priceBands?.[index]?.fromTonnes}
                            autoComplete="none"
                            disabled={!verifyPermissions('write', instance)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.priceBands?.[index]?.fromTonnes?.message}
                          </Form.Control.Feedback>
                        </td>
                        <td>
                          <Form.Control
                            placeholder="Please enter to (tonnes)"
                            {...register(`priceBands.${index}.toTonnes`)}
                            isInvalid={errors.priceBands?.[index]?.toTonnes}
                            autoComplete="none"
                            disabled={!verifyPermissions('write', instance)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.priceBands?.[index]?.toTonnes?.message}
                          </Form.Control.Feedback>
                        </td>
                        <td>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Please enter margin (%)"
                              {...register(`priceBands.${index}.marginPercentage`)}
                              isInvalid={errors.priceBands?.[index]?.marginPercentage}
                              autoComplete="none"
                              disabled={!verifyPermissions('write', instance)}
                            />
                            <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                          </InputGroup>
                          <Form.Control.Feedback type="invalid">
                            {errors.priceBands?.[index]?.marginPercentage?.message}
                          </Form.Control.Feedback>
                        </td>
                        <td className="wrap-btn-min-order">
                          {fields.length > 1 && (
                            <button
                              className="btn btn-min-order btn-outline-secondary"
                              type="button"
                              onClick={() => remove(index)}
                            >
                              <img style={{ marginRight: 0 }} src={minOrder} alt="remove Order icon" />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td className="wrap-btn-add-order" colSpan="4">
                        <Button
                          className="btn btn-add-Order btn-outline-secondary"
                          onClick={() => {
                            append({ fromTonnes: 0, toTonnes: 0, marginPercentage: 0 });
                          }}
                        >
                          Add New Line
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Row></Row>
            </Row>
          </Col>

          {/* ===================================== NEW FIELDS REQUIRED FOR E-CERTIFICATE =================================== */}

          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={12} className="mt-5">
              <h6 className="Payment-Matrix">
                <strong>Company Address</strong>
              </h6>
            </Col>
          )}

          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={3}>
              <Form.Label>Post Box</Form.Label>
              <Form.Control
                placeholder="Please enter post box"
                {...register('postalAddressPostBox')}
                isInvalid={errors.postalAddressPostBox}
                autoComplete="none"
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressPostBox?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={3}>
              <Form.Label>Building Number</Form.Label>
              <Form.Control
                placeholder="Please enter building number"
                {...register('postalAddressBuildingNumber')}
                isInvalid={errors.postalAddressBuildingNumber}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.postalAddressBuildingNumber?.message}
              </Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={6}>
              <Form.Label>Building Name</Form.Label>
              <Form.Control
                placeholder="Please building name"
                {...register('postalAddressBuildingName')}
                isInvalid={errors.postalAddressBuildingName}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressBuildingName?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={12}>
              <Form.Label>Street Name *</Form.Label>
              <Form.Control
                placeholder="Please enter street name"
                {...register('postalAddressStreetName')}
                isInvalid={errors.postalAddressStreetName}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressStreetName?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={12}>
              <Form.Label>Additional Street Name</Form.Label>
              <Form.Control
                placeholder="Please enter additional street name"
                {...register('postalAddressAdditionalStreetName')}
                isInvalid={errors.postalAddressAdditionalStreetName}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.postalAddressAdditionalStreetName?.message}
              </Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={3}>
              <Form.Label>City *</Form.Label>
              <Form.Control
                placeholder="Please enter city"
                {...register('postalAddressCityName')}
                isInvalid={errors.postalAddressCityName}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressCityName?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={3}>
              <Form.Label>State</Form.Label>
              <Form.Control
                placeholder="Please enter state"
                {...register('postalAddressStateName')}
                isInvalid={errors.postalAddressStateName}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressStateName?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={2}>
              <Form.Label>Post Code</Form.Label>
              <Form.Control
                placeholder="Please enter post code"
                {...register('postalAddressPostalZone')}
                isInvalid={errors.postalAddressPostalZone}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressPostalZone?.message}</Form.Control.Feedback>
            </Col>
          )}
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={4}>
              <Form.Label>Country</Form.Label>
              <Form.Control
                placeholder="Please enter country"
                {...register('postalAddressCountry')}
                isInvalid={errors.postalAddressCountry}
                autoComplete="none"
                maxLength={100}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.postalAddressCountry?.message}</Form.Control.Feedback>
            </Col>
          )}
        </Row>
        <div className="inner-card">
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <div className="form-heading">
              <h3>Primary Contact</h3>
            </div>
          )}
          <Row>
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={2}>
                <Form.Label>Title *</Form.Label>
                <Form.Control
                  as="select"
                  type="select"
                  {...register('primaryContactTitle')}
                  onChange={(e) =>
                    sameAsPrimary && setValue('billingContactTitle', e.target.value, { shouldValidate: true })
                  }
                  disabled={!verifyPermissions('write', instance)}
                  isInvalid={errors.primaryContactTitle}
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Dr">Dr</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.primaryContactTitle?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={10}>
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  placeholder="Please enter name"
                  {...register('primaryContactName')}
                  isInvalid={errors.primaryContactName}
                  autoComplete="none"
                  maxLength={100}
                  onChange={(e) =>
                    sameAsPrimary && setValue('billingContactName', e.target.value, { shouldValidate: true })
                  }
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.primaryContactName?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>Email *</Form.Label>
                <Form.Control
                  placeholder="Please enter email"
                  {...register('primaryContactEmail')}
                  isInvalid={errors.primaryContactEmail}
                  maxLength={100}
                  onChange={(e) =>
                    sameAsPrimary && setValue('billingContactEmail', e.target.value, { shouldValidate: true })
                  }
                  disabled={!verifyPermissions('write', instance)}
                  autoComplete="none"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.primaryContactEmail?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>Mobile *</Form.Label>
                <Form.Control
                  placeholder="Please enter mobile number"
                  {...register('primaryContactTelephone')}
                  isInvalid={errors.primaryContactTelephone}
                  maxLength={100}
                  onChange={(e) =>
                    sameAsPrimary && setValue('billingContactTelephone', e.target.value, { shouldValidate: true })
                  }
                  autoComplete="none"
                  disabled={!verifyPermissions('write', instance)}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.primaryContactTelephone?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>C2Zero Relationship Owner *</Form.Label>
                <Controller
                  name="relationshipManagerId"
                  control={control}
                  {...register('relationshipManagerId')}
                  render={({ field, fieldState: { invalid }, formState }) => {
                    return (
                      <React.Fragment>
                        <Select
                          isSearchable={true}
                          isClearable={true}
                          placeholder="Please select C2Zero relationship owner"
                          options={platformUsers}
                          isInvalid={invalid}
                          onTouched={onTouched}
                          onBlur={onBlur}
                          onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                          defaultValue={platformUsers.filter((user) => user.id === field.value)[0]}
                          // className={`form-control ${invalid ? 'is-invalid' : ''}`}
                          // styles={customStyles}
                          classNamePrefix="react-select"
                          isDisabled={!verifyPermissions('write', instance)}
                        />
                        {invalid && <div className="error">{errors.relationshipManagerId?.message}</div>}
                      </React.Fragment>
                    );
                  }}
                />
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>C2Zero Account Manager *</Form.Label>
                <Controller
                  name="accountManagerId"
                  control={control}
                  {...register('accountManagerId')}
                  render={({ field, fieldState: { invalid }, formState }) => {
                    return (
                      <React.Fragment>
                        <Select
                          isSearchable={true}
                          isClearable={true}
                          placeholder="Please select C2Zero Account Manager"
                          options={platformUsers}
                          isInvalid={invalid}
                          onTouched={onTouched}
                          onBlur={onBlur}
                          onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : '')}
                          defaultValue={platformUsers.filter((user) => user.id === field.value)[0]}
                          // className={`form-control ${invalid ? 'is-invalid' : ''}`}
                          // styles={customStyles}
                          disabled={!verifyPermissions('write', instance)}
                          classNamePrefix="react-select"
                          isDisabled={!verifyPermissions('write', instance)}
                        />
                        {invalid && <div className="error">{errors.accountManagerId?.message}</div>}
                      </React.Fragment>
                    );
                  }}
                />
              </Col>
            )}
          </Row>
        </div>
        <div className="inner-card">
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <div className="form-heading">
              <h3>Billing Contact</h3>
              <Form.Check
                label="Same as Primary Contact"
                onClick={() => {
                  setSameAsPrimary(!sameAsPrimary);
                }}
                {...register('primaryContactForBilling')}
                disabled={!verifyPermissions('write', instance)}
              ></Form.Check>
            </div>
          )}
          <Row>
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={2}>
                <Form.Label>Title *</Form.Label>
                <Form.Control
                  as="select"
                  type="select"
                  disabled={sameAsPrimary}
                  {...register('billingContactTitle')}
                  isInvalid={errors.billingContactTitle}
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Dr">Dr</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.billingContactTitle?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={10}>
                <Form.Label>Name *</Form.Label>
                <Form.Control
                  placeholder="Please enter name"
                  {...register('billingContactName')}
                  disabled={sameAsPrimary || !verifyPermissions('write', instance)}
                  maxLength={100}
                  isInvalid={errors.billingContactName}
                  autoComplete="none"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.billingContactName?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>Email *</Form.Label>
                <Form.Control
                  placeholder="Please enter email"
                  {...register('billingContactEmail')}
                  disabled={sameAsPrimary || !verifyPermissions('write', instance)}
                  isInvalid={errors.billingContactEmail}
                  maxLength={100}
                  autoComplete="none"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.billingContactEmail?.message}</Form.Control.Feedback>
              </Col>
            )}
            {getCustomerApiRequestInProgress ||
            getPlatformUsersApiRequestInProgress ||
            getXeroContactsApiRequestInProgress ? (
              <FormSkeleton size="6" />
            ) : (
              <Col lg={6}>
                <Form.Label>Mobile *</Form.Label>
                <Form.Control
                  placeholder="Please enter mobile number"
                  {...register('billingContactTelephone')}
                  disabled={sameAsPrimary || !verifyPermissions('write', instance)}
                  isInvalid={errors.billingContactTelephone}
                  maxLength={100}
                  autoComplete="none"
                ></Form.Control>
                <Form.Control.Feedback type="invalid">{errors.billingContactTelephone?.message}</Form.Control.Feedback>
              </Col>
            )}
          </Row>
        </div>
        <Row>
          {getCustomerApiRequestInProgress ||
          getPlatformUsersApiRequestInProgress ||
          getXeroContactsApiRequestInProgress ? (
            <FormSkeleton size="6" />
          ) : (
            <Col lg={12}>
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows="4"
                {...register('note')}
                isInvalid={errors.note}
                maxLength={250}
                autoComplete="none"
                disabled={!verifyPermissions('write', instance)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">{errors.note?.message}</Form.Control.Feedback>
            </Col>
          )}
        </Row>
        <div className="form-buttons">
          {customerId ? (
            <NavLink to="/customers" className="btn btn-light">
              Cancel
            </NavLink>
          ) : (
            <span onClick={resetForm} className="btn btn-light">
              Clear
            </span>
          )}
          <Button
            type="submit"
            className="btn btn-primary m-2"
            disabled={
              !verifyPermissions('write', instance) ||
              getCustomerApiRequestInProgress ||
              saveCustomersApiRequestInProgress ||
              getPlatformUsersApiRequestInProgress
            }
          >
            {saveCustomersApiRequestInProgress && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                variant="light"
                style={{ marginRight: '10px' }}
              />
            )}
            {customerId ? 'Update Customer' : 'Create Customer'}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default CustomerForm;

/**
 * todo:
 * remove the hardcoded user ids once deploying to production add these validations back.
 *   accountManagerId: string().required('C2Zero Account Manager is a required field'),
  relationshipManagerId: string().required('C2Zero Relationship Owner is a required field'),

 */
