import React, { useEffect, useState, useRef } from 'react';
import { Alert, Breadcrumb, Button, Spinner, InputGroup } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { recordsPerPage, reportTypes } from '../../util/constants';
import { getGeneratedReport, getReportsData, requestGenerateReport } from '../../services/reportsService';
import { AppContext } from '../../app/App';
import Loader from '../../components/Loader';
import TableNoData from '../../components/TableNoData';
import { formatTimeStamp, fortmatTimStampWithTz } from '../../common/formatter';
import { generateReportFormSchema } from './formValidation';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { clearNotification } from '../../app/actions';
import { FiCalendar } from 'react-icons/fi'; //import calendar icon from reat-icon
import DatePicker from 'react-datepicker';
import { verifyPermissions } from '../../common/permissionVerifier';

import PaginationComponent from '../../components/PaginationComponent';
import { createColumnHelper } from '@tanstack/table-core';
import TableComponentUpdated from '../../components/TableNew';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('productDisplayName', {
    cell: (info) => info.getValue(),
    header: () => <span>Product Name</span>,
    enableSorting: true
  }),
  columnHelper.accessor('carbonEmission', {
    cell: (info) => info.getValue(),
    header: () => <span>CO2 Eliminated</span>,
    enableSorting: false
  }),
  columnHelper.accessor('quantity', {
    cell: (info) => info.getValue(),
    header: () => <span>CO2 Eliminated (In Tonnes)</span>,
    enableSorting: false
  }),
  columnHelper.accessor('createdOn', {
    cell: (info) => formatTimeStamp(info.getValue()),
    header: () => <span>Scanned date</span>,
    enableSorting: true
  }),
  columnHelper.accessor('consumerEmail', {
    cell: (info) => info.getValue(),
    header: () => <span>Email</span>,
    enableSorting: false
  })
];

const Reports = () => {
  // const { SearchBar } = Search;
  const context = React.useContext(AppContext);
  const dispatch = useDispatch();
  const location = useLocation();

  const [getReportsDataApiRequestInProgress, setGetReportsDataApiRequestInProgress] = useState(true);
  const [exportReportsDataApiRequestInProgress, setExportReportsDataApiRequestInProgress] = useState(false);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(null);

  const [totalCount, setTotalCount] = useState(0);
  const [allReports, setAllReports] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [exportData, setExportData] = useState(null);
  const [notification, setNotificationMessage] = useState(null);
  const [tableState, setTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: true,
    pageNumber: 1
  });

  let startDatePickerRef = useRef('');
  let endDatePickerRef = useRef('');
  const openDatepicker = (ref) => ref.current.setOpen(true);

  const {
    register,
    getValues,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(generateReportFormSchema),
    mode: 'all'
  });

  const onSubmitHandler = (data) => {
    try {
      generateReportFormSchema.validate(data).then(function (value) {
        generateReport(data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const generateReport = async () => {
    setExportReportsDataApiRequestInProgress(true);
    try {
      const data = {
        fromDate: getValues('fromDate') ? fortmatTimStampWithTz(getValues('fromDate')) : null,
        toDate: getValues('toDate') ? fortmatTimStampWithTz(getValues('toDate')) : null,
        reportGenerationStatus: 0
      };

      const response = await requestGenerateReport(data, context.msalInstance);
      if (response) {
        setExportData(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setExportReportsDataApiRequestInProgress(false);
    }
  };

  const checkGenereatedReport = async (id) => {
    try {
      const response = await getGeneratedReport(dispatch, location.pathname, id, context.msalInstance);
      if (response) {
        setExportData(response);
        await setNotifications('success', 'Report generated successfully');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    }
  };

  const getReports = async (
    pageNumber = 1,
    searchKeyWord = null,
    sortBy = null,
    isAscSort = true,
    start = startDate,
    end = endDate
  ) => {
    setGetReportsDataApiRequestInProgress(true);

    const fromDate = start ? fortmatTimStampWithTz(start) : null;
    const toDate = end ? fortmatTimStampWithTz(end) : null;

    setStartDate(start);
    setEndDate(end);
    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord: searchKeyWord,
        isAscSort,
        filters: [
          {
            key: 'FromDate',
            value: fromDate
          },
          {
            key: 'ToDate',
            value: toDate
          }
        ]
      };

      const response = await getReportsData(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllReports(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord && startDate === null && endDate === null) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetReportsDataApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        dispatch(clearNotification());
      },
      type === 'success' ? 2000 : 5000
    );
  };

  useEffect(() => {
    getReports(tableState.pageNumber, null, null, true, startDate, endDate);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTableState((prevState) => ({
      ...prevState,
      pageNumber: 1
    }));
  }, [startDate, endDate]);

  useEffect(() => {
    getReports(
      tableState.pageNumber,
      tableState.searchText,
      tableState.sortField?.toLowerCase(),
      tableState.isAscSort,
      startDate,
      endDate
    );
    // eslint-disable-next-line
  }, [tableState, startDate, endDate]);

  useEffect(() => {
    if (exportData && exportData.reportGenerationStatus < 1 && !exportData.reportLink) {
      checkGenereatedReport(exportData.id);
    }
    // eslint-disable-next-line
  }, [exportData]);

  const NoDataIndication = () => <TableNoData colspan="6" mainMessage="No data available" />;

  return (
    <section>
      <div>
        <Form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="none">
          <div className="page-header">
            <div className="breadcrumb-wrapper">
              <Breadcrumb>
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
              </Breadcrumb>
              {/* <SearchBar {...props.searchProps} srText="" className="table-search" /> */}
            </div>
            {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
            <Button type="submit" className="btn btn-primary" disabled={getReportsDataApiRequestInProgress}>
              {exportReportsDataApiRequestInProgress && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  variant="light"
                  style={{ marginRight: '10px' }}
                />
              )}
              Export Scanned Data
            </Button>
          </div>
          <div className="row">
            <div className="col-4 col-sm-4 col-md-3 date-from">
              <Form.Group controlId="date">
                <Form.Label>Date From</Form.Label>
                <Controller
                  name="fromDate"
                  control={control}
                  {...register('fromDate')}
                  render={({ field, fieldState: { invalid } }) => {
                    return (
                      <React.Fragment>
                        <InputGroup className="calendar-container">
                          <div className="calender-icon" onClick={() => openDatepicker(startDatePickerRef)}>
                            <FiCalendar />
                          </div>
                          <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date ? date : null);
                              getReports(1, null, null, true, date, endDate);
                            }}
                            className={`form-control ${invalid ? 'is-invalid' : ''}`}
                            placeholderText="From"
                            todayButton="Today"
                            isClearable={field.value}
                            onBlur={field.onBlur}
                            strictParsing={true}
                            showYearDropdown={true}
                            showMonthDropdown={true}
                            maxDate={endDate ? endDate : null}
                            ref={startDatePickerRef}
                          />
                        </InputGroup>

                        {invalid && <div className="error">{errors.fromDate?.message}</div>}
                      </React.Fragment>
                    );
                  }}
                />
              </Form.Group>
            </div>
            <div className="col-4 col-sm-4 col-md-3 date-to">
              <Form.Group controlId="date">
                <Form.Label>Date To</Form.Label>
                <Controller
                  name="toDate"
                  control={control}
                  {...register('toDate')}
                  render={({ field, fieldState: { invalid } }) => {
                    return (
                      <React.Fragment>
                        <InputGroup className="calendar-container">
                          <div className="calender-icon" onClick={() => openDatepicker(endDatePickerRef)}>
                            <FiCalendar />
                          </div>
                          <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={field.value}
                            onChange={(date) => {
                              field.onChange(date ? date : null);
                              getReports(1, null, null, true, startDate, date);
                            }}
                            className={`form-control ${invalid ? 'is-invalid' : ''}`}
                            placeholderText="To"
                            todayButton="Today"
                            isClearable={field.value}
                            onBlur={field.onBlur}
                            strictParsing={true}
                            showYearDropdown={true}
                            showMonthDropdown={true}
                            minDate={startDate ? startDate : null}
                            ref={endDatePickerRef}
                          />
                        </InputGroup>

                        {invalid && <div className="error">{errors.toDate?.message}</div>}
                      </React.Fragment>
                    );
                  }}
                />
              </Form.Group>
            </div>
            <div className="col-4 col-sm-4 col-md-3">
              <Form.Label>Report Type</Form.Label>
              <Form.Control
                as="select"
                type="select"
                {...register('reportType')}
                isInvalid={errors.partyType}
                disabled={!verifyPermissions('write', context.msalInstance)}
              >
                <option value="" disabled>
                  {' '}
                  Select Report Type
                </option>
                {Object.keys(reportTypes).map((type, index) => (
                  <option key={index} value={type}>
                    {reportTypes[type]}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.partyType?.message}</Form.Control.Feedback>
            </div>
            {exportData && exportData.reportLink ? (
              <div className="col-3 date-to ">
                <br />
                <a className="btn btn-outline-primary btn-md" href={exportData.reportLink}>
                  Download Report
                </a>
              </div>
            ) : (
              ''
            )}
          </div>
          {isTableInitiallyEmpty === null ? (
            <Loader />
          ) : isTableInitiallyEmpty === true ? (
            <div className="row">
              <div className="col-12">
                <div className="report-tbl-responsive">
                  <TableComponentUpdated
                    columns={columns}
                    data={allReports}
                    setTableState={setTableState}
                    rowClickable={false}
                    tableLoading={getReportsDataApiRequestInProgress}
                    noDataIndicator={NoDataIndication}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <div className="report-tbl-responsive">
                  <TableComponentUpdated
                    columns={columns}
                    data={allReports}
                    setTableState={setTableState}
                    rowClickable={false}
                    tableLoading={getReportsDataApiRequestInProgress}
                    noDataIndicator={NoDataIndication}
                  />
                  <PaginationComponent
                    totalCount={totalCount}
                    recordsPerPage={recordsPerPage}
                    setTableState={setTableState}
                    tableState={tableState}
                  />
                </div>
              </div>
            </div>
          )}
        </Form>
      </div>
    </section>
  );
};

export default Reports;
