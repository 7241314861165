import moment from 'moment';
import { userRoles } from '../util/constants';
const unitsConversion = require('convert-units');

export const convertUnits = (quantity, unit) => {
  if (quantity && unit) {
    const converted = unitsConversion(quantity).from(unit).to('mt');
    return converted;
  }
};

export const formatDate = (date, format) => {
  return date
    ? moment
        .utc(date.toString())
        .local()
        .format(format ? format : 'DD-MM-YYYY')
    : '-';
};

export const fortmatTimStampWithTz = (date) => {
  date = date.toISOString();
  return moment.utc(date.toString()).local().format();
};

export const formatTimeStamp = (date) => {
  return date ? moment.utc(date.toString()).local().format('DD-MM-YYYY hh:mm:ss A') : '-';
};

export const formatRoles = (roles) => {
  const rolesArray = roles ? roles.split(',') : [];
  let rolesCSV =
    rolesArray && rolesArray.length > 0 ? rolesArray.map((item) => userRoles[item.trim()]).join(', ') : '-';
  return rolesCSV;
};

export const padLeadingZeros = (num) => {
  if (Number(num) < 1) {
    num = '0' + num;
  }
  // while (s.length < size) s = '0' + s;
  return num;
};
