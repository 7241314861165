import { array, lazy, number, object, string } from 'yup';

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const passwordValidation = (value) => {
  const regexArr = [/[a-z]/, /[A-Z]/, /\d/, /[^A-Za-z 0-9]/];
  if (value.length < 8 || value.length > 16) {
    return false;
  }
  const result = regexArr.filter((regex) => regex.test(value));
  return result.length >= 3;
};

export const userFormSchema = object().shape({
  email: string().required('Email is a required field').email('Invalid email format'),
  firstName: string()
    .test('len', 'Character length should be less than 100 characters', (val) => val.length < 100)
    .required('First name is a required field'),
  lastName: string()
    .test('len', 'Character length should be less than 100 characters', (val) => val.length < 100)
    .required('Last Name is a required field'),
  roles: lazy((val) =>
    Array.isArray(val)
      ? array().min(1, 'User type is a required field').required('User type is a required field')
      : string().required('User type is a required field')
  ),
  userCategory: number()
    .nullable()
    .required('User Category is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  mobileNumber: string()
    .test('len', 'Mobile number is not valid', function (value) {
      if (!!value) {
        const schema = string().matches(phoneRegExp, 'Mobile number is not valid');
        return schema.isValidSync(value);
      }
      return true;
    })
    .nullable(),
  password: string()
    .required('Password is a required field')
    .test(
      'password-validation',
      'Password must be between 8 and 16 Characters and contain atleast 3 of the following: One Uppercase, One Lowercase, One Number and One special case Character.',
      function (value) {
        return passwordValidation(value);
      }
    ),
  confirmPassword: string()
    .required('Confirm Password is a required field')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    })
});

export const userUpdateSchema = object().shape({
  email: string().required('Email is a required field').email('Invalid email format'),
  firstName: string()
    .test('len', 'Character length should be less than 100 characters', (val) => val.length < 100)
    .required('First name is a required field'),
  lastName: string()
    .test('len', 'Character length should be less than 100 characters', (val) => val.length < 100)
    .required('Last Name is a required field'),
  roles: lazy((val) =>
    Array.isArray(val)
      ? array().min(1, 'User type is a required field').required('User type is a required field')
      : string().required('User type is a required field')
  ),
  userCategory: number()
    .nullable()
    .required('User Category is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  mobileNumber: string()
    .test('len', 'Mobile number is not valid', function (value) {
      if (!!value) {
        const schema = string().matches(phoneRegExp, 'Mobile number is not valid');
        return schema.isValidSync(value);
      }
      return true;
    })
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  password: string()
    .nullable()
    .when((password, field) =>
      password
        ? field
            .required('Password is a required field')
            .test(
              'password-validation',
              'Password must be between 8 and 16 Characters and contain atleast 3 of the following: One Uppercase, One Lowercase, One Number and One special case Character.',
              function (value) {
                return passwordValidation(value);
              }
            )
        : field
    ),
  confirmPassword: string()
    .nullable()
    .when('password', {
      is: (password) => password, //just an e.g. you can return a function
      then: () =>
        string()
          .required('Confirm Password is a required field')
          .test('passwords-match', 'Passwords must match', function (value) {
            return value ? this.parent.password === value : true;
          }),
      otherwise: () => string().nullable()
    })
});
