import { array, number, object, string, mixed, boolean } from 'yup';

const phoneRegExp = /^\+(?:[0-9] ?){6,14}[0-9]$/;

const positiveNumberValidater = (value) => {
  if (value !== undefined && value !== null) {
    const numberValue = parseFloat(value);
    return !isNaN(numberValue) && numberValue >= 0;
  }
  return true;
};

const maxDecimalPlaceValidater = (value) => {
  if (value !== undefined && value !== null) {
    const [, decimal] = value.toString().split('.');
    return decimal === undefined || decimal.length <= 2;
  }
  return true;
};

export const customerFormSchema = object().shape({
  name: string().required('Company / Individual Name is a required field'),
  partyType: string(),
  xeroRefId: string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),

  brand: string(),
  defaultCarbonAccountId: string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when('isRunAutomatically', {
      is: true,
      then: () => string().required('Default Carbon Account Id is a required field')
    }),
  productDisplayText: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Product display text is a required field')
  }),
  productName: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Product name is a required field')
  }),
  referenceName: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Reference is a required field')
  }),
  currency: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Currency is a required field')
  }),
  postalAddressStreetName: string().required('Street Name is a required field'),
  postalAddressCityName: string().required('City Name is a required field'),
  postalAddressPostBox: string(),
  postalAddressBuildingName: string(),
  postalAddressBuildingNumber: string(),
  postalAddressAdditionalStreetName: string(),
  postalAddressStateName: string(),
  postalAddressPostalZone: string(),
  postalAddressCountry: string(),
  primaryContactTitle: string().required('Title is a required field'),
  primaryContactName: string().required('Name is a required field'),
  primaryContactEmail: string().required('Email is a required field').email('Invalid email format'),
  primaryContactTelephone: string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Mobile is a required field'),
  billingContactTitle: string().required('Title is a required field'),
  billingContactName: string().required('Name is a required field'),
  billingContactEmail: string().required('Email is a required field').email('Invalid email format'),
  billingContactTelephone: string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Mobile is a required field'),
  accountManagerId: string(),
  relationshipManagerId: string(),
  isRunAutomatically: boolean(),
  allowanceType: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Allowance Type is a required field')
  }),
  basePrice: number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when('isRunAutomatically', {
      is: true,
      then: () =>
        number()
          .transform((v, o) => (o === '' ? null : v))
          .required('Base Price is a required field')
          .typeError('Base Price must be a valid number')
    }),
  priceBands: array()
    .of(
      object().shape({
        fromTonnes: number()
          .nullable()
          .transform((v, o) => (o === '' ? null : v)),
        toTonnes: number()
          .nullable()
          .transform((v, o) => (o === '' ? null : v)),
        marginPercentage: number()
          .nullable()
          .transform((v, o) => (o === '' ? null : v))
      })
    )
    .when('isRunAutomatically', {
      is: true,
      then: () =>
        array().of(
          object().shape({
            fromTonnes: number()
              .nullable()
              .transform((v, o) => (o === '' ? null : v))
              .required('From is a required field')
              .typeError('Must be a valid number'),
            toTonnes: number()
              .nullable()
              .transform((v, o) => (o === '' ? null : v))
              .required('To is a required field')
              .typeError('Must be a valid number'),
            marginPercentage: number()
              .nullable()
              .transform((v, o) => (o === '' ? null : v))
              .min(1, 'Margin must be at least 1')
              .max(100, 'Margin must be at most 100')
              .test('decimal-places', 'Percentage must have at most 2 decimal places', maxDecimalPlaceValidater)
              .test('positive-number', 'Only positive numbers are allowed', positiveNumberValidater)
              .required('Margin is a required field')
              .typeError('Must be a valid number')
          })
        )
    }),
  customerLogo: mixed().when('isRunAutomatically', {
    is: true,
    then: () => mixed().required('Customer Logo is a required field')
  }),
  currentRegionTax: number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when(['isRunAutomatically', 'region'], {
      is: (isRunAutomatically, region) => region !== 'AU' && isRunAutomatically === true,
      then: () =>
        number()
          .required('Tax Amount is a required field')
          .typeError('Tax Amount must be a valid number')
          .test('decimal-places', 'Percentage must have at most 2 decimal places', maxDecimalPlaceValidater)
          .test('positive-number', 'Only positive numbers are allowed', positiveNumberValidater)
    }),
  australianTax: number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when('isRunAutomatically', {
      is: true,
      then: () =>
        number()
          .required('Tax Percentage is a required field')
          .typeError('Tax Percentage must be a valid number')
          .test('decimal-places', 'Percentage must have at most 2 decimal places', maxDecimalPlaceValidater)
          .test('positive-number', 'Only positive numbers are allowed', positiveNumberValidater)
    }),
  eCertificateTemplateId: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Certificate Template is a required field')
  }),
  region: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Country is a required field')
  }),
  minimumCarbon: number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when('isRunAutomatically', {
      is: true,
      then: () =>
        number()
          .min(100, 'Minimum quantity should be 100 Kg')
          .required('Minimum carbon is a required field')
          .typeError('must be a valid number')
    })
});

export const customerUpdateSchema = object().shape({
  name: string().required('Company / Individual Name is a required field'),
  partyType: string(),
  xeroRefId: string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  postalAddressPostBox: string(),
  postalAddressStreetName: string().required('Street Name is a required field'),
  postalAddressCityName: string().required('City Name is a required field'),
  postalAddressBuildingName: string(),
  postalAddressBuildingNumber: string(),
  postalAddressAdditionalStreetName: string(),
  postalAddressStateName: string(),
  postalAddressPostalZone: string(),
  postalAddressCountry: string(),
  primaryContactTitle: string().required('Title is a required field'),
  primaryContactName: string().required('Name is a required field'),
  primaryContactEmail: string().required('Email is a required field').email('Invalid email format'),
  primaryContactTelephone: string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Mobile is a required field'),
  billingContactTitle: string().required('Title is a required field'),
  billingContactName: string().required('Name is a required field'),
  billingContactEmail: string().required('Email is a required field').email('Invalid email format'),
  billingContactTelephone: string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Mobile is a required field'),
  accountManagerId: string(),
  relationshipManagerId: string(),
  brand: string(),
  defaultCarbonAccountId: string()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when('isRunAutomatically', {
      is: true,
      then: () => string().required('Default Carbon Account Id is a required field')
    }),
  productDisplayText: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Product display text is a required field')
  }),
  productName: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Product name is a required field')
  }),
  referenceName: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Reference is a required field')
  }),
  currency: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Currency is a required field')
  }),
  isRunAutomatically: boolean(),
  allowanceType: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Allowance Type is a required field')
  }),
  basePrice: number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when('isRunAutomatically', {
      is: true,
      then: () =>
        number()
          .transform((v, o) => (o === '' ? null : v))
          .required('Base Price is a required field')
          .typeError('Base Price must be a valid number')
    }),
  priceBands: array()
    .of(
      object().shape({
        fromTonnes: number()
          .nullable()
          .transform((v, o) => (o === '' ? null : v)),
        toTonnes: number()
          .nullable()
          .transform((v, o) => (o === '' ? null : v)),
        marginPercentage: number()
          .nullable()
          .transform((v, o) => (o === '' ? null : v))
      })
    )
    .when('isRunAutomatically', {
      is: true,
      then: () =>
        array().of(
          object().shape({
            fromTonnes: number()
              .nullable()
              .transform((v, o) => (o === '' ? null : v))
              .required('From is a required field')
              .typeError('Must be a valid number'),
            toTonnes: number()
              .nullable()
              .transform((v, o) => (o === '' ? null : v))
              .required('To is a required field')
              .typeError('Must be a valid number'),
            marginPercentage: number()
              .nullable()
              .transform((v, o) => (o === '' ? null : v))
              .min(1, 'Margin must be at least 1')
              .max(100, 'Margin must be at most 100')
              .test('decimal-places', 'Percentage must have at most 2 decimal places', maxDecimalPlaceValidater)
              .test('positive-number', 'Only positive numbers are allowed', positiveNumberValidater)
              .required('Margin is a required field')
              .typeError('Must be a valid number')
          })
        )
    }),
  customerLogo: mixed().when('isRunAutomatically', {
    is: true,
    then: () => mixed().required('Customer Logo is a required field')
  }),
  currentRegionTax: number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when(['isRunAutomatically', 'region'], {
      is: (isRunAutomatically, region) => region !== 'AU' && isRunAutomatically === true,
      then: () =>
        number()
          .required('Tax Amount is a required field')
          .typeError('Tax Amount must be a valid number')
          .test('decimal-places', 'Percentage must have at most 2 decimal places', maxDecimalPlaceValidater)
    }),
  australianTax: number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when('isRunAutomatically', {
      is: true,
      then: () =>
        number()
          .required('Tax Percentage is a required field')
          .typeError('Tax Percentage must be a valid number')
          .test('decimal-places', 'Percentage must have at most 2 decimal places', maxDecimalPlaceValidater)
    }),
  eCertificateTemplateId: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Certificate Template is a required field')
  }),
  region: string().when('isRunAutomatically', {
    is: true,
    then: () => string().required('Country is a required field')
  }),
  minimumCarbon: number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when('isRunAutomatically', {
      is: true,
      then: () =>
        number()
          .min(100, 'Minimum quantity should be 100 Kg')
          .required('Minimum carbon is a required field')
          .typeError('must be a valid number')
    })
});
