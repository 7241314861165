import React, { useEffect, useState } from 'react';
// import { Col, Row } from 'react-bootstrap';
import {
  getAllProducts,
  getAnalyticsCarbonStatus,
  getAnalyticsOrderStatus,
  getAnalyticsTileData,
  getAnalyticsTokenStatus
} from '../../services/analyticsService';
import AnalyticsChart from './AnalyticsChart';
import { AppContext } from '../../app/App';
import { getAllCustomers } from '../../services/partyService';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import AddOrder from '../../assets/images/icons/plus.svg';
import { Link } from 'react-router-dom';
import { pendingAprovalstatus } from '../../util/constants';

const ordersChart = ['#56D9FE', '#A3A0FB', '#FF8373'];
const tokenChart = ['#9FE2BF', '#2EE13A', '#FFDA83'];
const carbonChart = ['#FF7F50', '#FFBF00', '#FF8373'];

const Analytics = () => {
  const context = React.useContext(AppContext);
  const [tileData, setTileData] = useState([]);
  const [getAnalyticsTileDataApiRequestInProgress, setGetAnalyticsTileDataApiRequestInProgress] = useState(false);
  const [getCustomersApiRequestInProgress, setGetCustomersApiRequestInProgress] = useState(false);
  const [getProductApiRequestInProgress, setGetProductApiRequestInProgress] = useState(false);
  const [getOrderStatusApiRequestInProgress, setGetOrderStatusApiRequestInProgress] = useState(false);
  const [getTokenStatusApiRequestInProgress, setGetTokenStatusApiRequestInProgress] = useState(false);
  const [getCarbonStatusApiRequestInProgress, setGetCarbonStatusApiRequestInProgress] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [tokenStatus, setTokenStatus] = useState([]);
  const [carbonStatus, setCarbonStatus] = useState([]);
  const analytics = true;

  const getAnalyticsTiles = async () => {
    setGetAnalyticsTileDataApiRequestInProgress(true);
    try {
      const response = await getAnalyticsTileData(context.msalInstance);
      if (response) {
        setTileData(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetAnalyticsTileDataApiRequestInProgress(false);
    }
  };

  const getCustomers = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetCustomersApiRequestInProgress(true);
    try {
      const data = {
        pageNumber,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllCustomers(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllCustomers(response.paginatedList.map((i) => ({ ...i, label: i.name, value: i.id })));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetCustomersApiRequestInProgress(false);
    }
  };

  const getProducts = async () => {
    setGetProductApiRequestInProgress(true);
    try {
      const response = await getAllProducts(context.msalInstance);
      if (response) {
        setAllProducts(response.map((i) => ({ ...i, label: i.name, value: i.id })));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetProductApiRequestInProgress(false);
    }
  };

  const getOrderStatus = async (partyId = null, fromDate = null, toDate = null) => {
    setGetOrderStatusApiRequestInProgress(true);
    try {
      const data = {
        partyId: partyId,
        fromDate: fromDate,
        toDate: toDate
      };

      const response = await getAnalyticsOrderStatus(data, context.msalInstance);
      if (response) {
        let charts = [];
        response.forEach((i) => {
          if (i.statusName === 'Pending') {
            charts[0] = {
              name: i.status,
              value: i.count
            };
          }
          if (i.statusName === 'Created') {
            charts[1] = {
              name: i.status,
              value: i.count
            };
          }
          if (i.statusName === 'Partial Release') {
            charts[2] = {
              name: i.status,
              value: i.count
            };
          }
          if (i.statusName === 'All Tokens Released') {
            charts[3] = {
              name: i.status,
              value: i.count
            };
          }
        });
        setOrderStatus(charts);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetOrderStatusApiRequestInProgress(false);
    }
  };
  const getTokenStatus = async (partyId = null, fromDate = null, toDate = null) => {
    setGetTokenStatusApiRequestInProgress(true);
    try {
      const data = {
        partyId: partyId,
        fromDate: fromDate,
        toDate: toDate
      };

      const response = await getAnalyticsTokenStatus(data, context.msalInstance);
      if (response) {
        let charts = [];
        response.forEach((i) => {
          if (i.status === 'To be Claimed') {
            charts[0] = {
              name: i.status,
              value: i.count
            };
          }
          if (i.status === 'Claimed') {
            charts[1] = {
              name: i.status,
              value: i.count
            };
          }
        });
        setTokenStatus(charts);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetTokenStatusApiRequestInProgress(false);
    }
  };

  const getCarbonStatus = async (productId = null, fromDate = null, toDate = null) => {
    setGetCarbonStatusApiRequestInProgress(true);
    try {
      const data = {
        productId: productId,
        fromDate: fromDate,
        toDate: toDate
      };

      const response = await getAnalyticsCarbonStatus(data, context.msalInstance);
      if (response) {
        let charts = [];
        response.forEach((i) => {
          if (i.statusName === 'Available') {
            charts[0] = {
              name: i.status,
              value: i.count
            };
          }
          if (i.statusName === 'Consumed') {
            charts[1] = {
              name: i.status,
              value: i.count
            };
          }
        });
        setCarbonStatus(response.map((i) => ({ ...i, name: i.status, value: i.count })));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetCarbonStatusApiRequestInProgress(false);
    }
  };

  useEffect(() => {
    getCustomers();
    getProducts();
    getAnalyticsTiles();
    getOrderStatus();
    getTokenStatus();
    getCarbonStatus();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="analytics-page">
      <div>
        <div className="row section1">
          {!getAnalyticsTileDataApiRequestInProgress ? (
            tileData.length > 0 ? (
              pendingAprovalstatus.map((data) => {
                let index = tileData
                  .map(function (e) {
                    return e.pendingStatusName;
                  })
                  .indexOf(data);
                return (
                  <div className="col-lg-2 col-sm-4 col-6 count-col" key={index}>
                    <div className="analyticTile">
                      <Link to="/orders/order-releases" state={{ approvalStatus: tileData[index].approvalStatus }}>
                        {tileData[index].pendingStatusName}
                      </Link>
                      <h5>{tileData[index].count}</h5>
                    </div>
                  </div>
                );
              })
            ) : (
              <NoData
                mainMessage="You do not have any analytics data at the moment"
                secondaryMessage=""
                icon={AddOrder}
              />
            )
          ) : (
            <Loader />
          )}
        </div>

        {analytics && (
          <div className="row section2">
            <div className="col-md-6 sub-section1">
              <div className="chart-section chart1">
                <AnalyticsChart
                  title="Orders"
                  colors={ordersChart}
                  width={800}
                  height={300}
                  data={orderStatus}
                  date={true}
                  filterValues={allCustomers}
                  onChangeData={getOrderStatus}
                  isLoading={getOrderStatusApiRequestInProgress || getCustomersApiRequestInProgress}
                  placeholder="All Companies"
                  // paddingAngle={1}
                  // minAngle={1}
                ></AnalyticsChart>
                <div className="legend-container">
                  <div className="chart-legend">
                    <ul>
                      <li>
                        <span></span>
                        <p>Pending</p>
                      </li>
                      <li>
                        <span></span>
                        <p>Created</p>
                      </li>
                      <li>
                        <span></span>
                        <p>Partial Release</p>
                      </li>
                      <li>
                        <span></span>
                        <p>All Tokens Released</p>
                      </li>
                      <li>
                        <span></span>
                        <p>No Data</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 sub-section2">
              <div className="row row-sec">
                <div className="chart-section chart2">
                  <AnalyticsChart
                    title="Tokens"
                    colors={tokenChart}
                    width={800}
                    height={300}
                    data={tokenStatus}
                    date={true}
                    filterValues={allCustomers}
                    onChangeData={getTokenStatus}
                    isLoading={getTokenStatusApiRequestInProgress || getCustomersApiRequestInProgress}
                    placeholder="All Companies"
                  ></AnalyticsChart>
                  <div className="legend-container">
                    <div className="chart-legend">
                      <ul>
                        <li>
                          <span></span>
                          <p>To Be Claimed</p>
                        </li>
                        <li>
                          <span></span>
                          <p>Claimed</p>
                        </li>
                        <li>
                          <span></span>
                          <p>No Data</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-sec">
                <div className="chart-section chart3">
                  <AnalyticsChart
                    title="Carbon"
                    colors={carbonChart}
                    width={800}
                    height={300}
                    data={carbonStatus}
                    filterValues={allProducts}
                    onChangeData={getCarbonStatus}
                    isLoading={getCarbonStatusApiRequestInProgress || getProductApiRequestInProgress}
                    placeholder="All Products"
                  ></AnalyticsChart>
                  <div className="legend-container">
                    <div className="chart-legend">
                      <ul>
                        <li>
                          <span></span>
                          <p>Available</p>
                        </li>
                        <li>
                          <span></span>
                          <p>Consumed</p>
                        </li>
                        <li>
                          <span></span>
                          <p>No Data</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Analytics;
