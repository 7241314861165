import React from 'react';

const Unauthorized = (props) => {
  return (
    <div className="under-construction">
      <h1>The page you're looking of is Unauthorized</h1>
      <h2>{props.message}</h2>
    </div>
  );
};

export default Unauthorized;
