import { addNotification } from '../app/actions';
import { get, post } from '../util/apiHelper';

export const requestGenerateReport = async (data, msalInstance) => {
  try {
    const response = await post(`/Report/RequestGenerateReport`, data, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getGeneratedReport = async (dispatch, location, id, msalInstance) => {
  try {
    const response = await get(`/Report/RequestReportStatus/${id}`, null, true, msalInstance);
    if (response) {
      if (response.data.reportGenerationStatus < 1 && !response.data.reportLink) {
        const interval = setInterval(() => {
          getGeneratedReport(response.data.id);
        }, 2000);
        return () => clearInterval(interval);
      } else {
        dispatch(addNotification('success', location, `Report generated successfully ${response.data.reportLink}`));
      }
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export const getReportsData = async (data, msalInstance) => {
  try {
    const response = await post(`/Report`, data, true, msalInstance);
    if (response) {
      return response.data;
    }
    return response;
  } catch (error) {
    throw error;
  }
};
