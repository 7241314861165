import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Alert, Breadcrumb, Button, Col, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { formatFileSize, lightenDarkenColor, useCSVReader } from 'react-papaparse';
import BootstrapTable from 'react-bootstrap-table-next';
import TableNoData from '../../components/TableNoData';
import { verifyPermissions } from '../../common/permissionVerifier';
import { uploadCSVRecords } from '../../services/carbonManagementService';
import { formatDate } from '../../common/formatter';
import fail from '../../assets/images/icons/fail.svg';
import success from '../../assets/images/icons/success.svg';
import info from '../../assets/images/icons/info.svg';
import TableComponentUpdated from '../../components/TableNew';
import { createColumnHelper } from '@tanstack/table-core';

const columns = [
  {
    dataField: 'transaction_Code',
    text: 'Transaction ID',
    sort: true
  },
  {
    dataField: 'the_Date',
    text: 'Transaction Date',
    sort: true,
    formatter: (cell, row) => formatDate(cell)
  },
  {
    dataField: 'account_Number',
    text: 'Transferred From'
  },
  {
    dataField: 'account_Number2',
    text: 'Transferred To'
  },
  {
    dataField: 'workflow_Type_Name',
    text: 'Transaction Type'
  },
  {
    dataField: 'number_Of_Units',
    text: 'Units Transferred'
  },
  {
    dataField: 'unit_Type',
    text: 'Unit Type'
  },
  {
    dataField: 'transaction_Status',
    text: 'Status'
  },
  {
    dataField: 'uploadStatus',
    text: 'Upload Status',
    hidden: true,
    formatter: (cell, row) => (
      <div>
        {row.uploadStatus ? (
          <div className="status-sec">
            <img className="information-icon" src={info} alt="info icon" />
            <div className="status-msg">
              <p>Success</p>
              <img className="status-icon" src={success} alt="status icon" />
            </div>
          </div>
        ) : (
          <div>
            {row.statusMessage ? (
              <span className="d-inline-block">
                <div className="status-sec">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip">{row.statusMessage}</Tooltip>}
                  >
                    <img style={{ cursor: 'pointer' }} className="information-icon" src={info} alt="info icon" />
                  </OverlayTrigger>
                  <div className="status-msg">
                    <p>Failed</p>
                    <img className="status-icon" src={fail} alt="status icon" />
                  </div>
                </div>
              </span>
            ) : (
              '-'
            )}
          </div>
        )}
      </div>
    )
  }
];
/**
 * TableComponentUpdated is used to render the table with empty data.
 * Reason is in this case TableNoData component is not getting centered properly with BootstrapTable.
 */
const columnHelper = createColumnHelper();

const noDataTableColumns = [
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Transaction ID</span>
  }),
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Transaction Date</span>
  }),
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Transferred From</span>
  }),
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Transferred To</span>
  }),
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Transaction Type</span>
  }),
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Units Transferred</span>
  }),
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Unit Type</span>
  }),
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Status</span>
  })
];

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);
const GREY_DIM = '#686868';
const TransactionsUploader = (props) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [uploadedData, setUploadedData] = useState([]);
  const [uploadCSVApiRequestInProgress, setUploadCSVApiRequestInProgress] = useState(false);
  const [notification, setNotificationMessage] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false);

  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
  let rowClasses = (row) => (!row.uploadStatus ? 'alert-row' : 'success-row');

  const handleOnDrop = (data) => {
    const field = columns.findIndex((item) => item.dataField === 'uploadStatus');
    if (field) {
      columns[field].hidden = true;
    }
    formatCSV(data?.data);
    setUploadComplete(false);
  };

  const handleOnRemoveFile = () => {
    const field = columns.findIndex((item) => item.dataField === 'uploadStatus');
    if (field) {
      columns[field].hidden = true;
    }
    setUploadedData([]);
  };

  const formatCSV = (data) => {
    let newData = [];
    const header = data[0];

    for (let i = 1; i < data.length; i++) {
      const rowData = data[i];
      if (rowData.length === header.length) {
        let rowObject = {};
        for (let j = 0; j < header.length; j++) {
          const columnName = header[j].charAt(0).toLowerCase() + header[j].slice(1);
          const columnValue = columnName === 'the_Date' ? formatDate(rowData[j], 'YYYY-MM-DD') : rowData[j];
          rowObject[columnName] = columnValue;
        }
        newData.push(rowObject);
      }
    }
    setUploadedData(newData);
  };

  const uploadCSV = async () => {
    setUploadCSVApiRequestInProgress(true);
    try {
      uploadedData.forEach((value) => {
        value.the_Date = value.the_Date === '-' ? null : value.the_Date;
        value.number_Of_Units = value.number_Of_Units === '' ? null : value.number_Of_Units;
      });
      console.log(uploadedData);
      const response = await uploadCSVRecords(uploadedData, instance);
      if (response) {
        setUploadedData(response);
        setUploadComplete(true);
        const field = columns.findIndex((item) => item.dataField === 'uploadStatus');
        if (field) {
          columns[field].hidden = false;
        }
        setUploadCSVApiRequestInProgress(false);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setUploadCSVApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          navigate(redirectUrl);
        }
      },
      type === 'success' ? 3000 : 5000
    );
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const styles = {
    zone: {
      alignItems: 'center',
      border: `2px dashed ${GREY}`,
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      padding: 20
    },
    file: {
      background: 'linear-gradient(to bottom, #EEE, #DDD)',
      borderRadius: 20,
      display: 'flex',
      height: 120,
      width: 120,
      position: 'relative',
      zIndex: 10,
      flexDirection: 'column',
      justifyContent: 'center'
    },
    info: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 10,
      paddingRight: 10
    },
    size: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      marginBottom: '0.5em',
      justifyContent: 'center',
      display: 'flex'
    },
    name: {
      backgroundColor: GREY_LIGHT,
      borderRadius: 3,
      fontSize: 12,
      marginBottom: '0.5em'
    },
    progressBar: {
      bottom: 14,
      position: 'absolute',
      width: '100%',
      paddingLeft: 10,
      paddingRight: 10
    },
    zoneHover: {
      borderColor: GREY_DIM
    },
    default: {
      borderColor: GREY
    },
    remove: {
      height: 23,
      position: 'absolute',
      right: 6,
      top: 6,
      width: 23
    }
  };

  return (
    <div className="csv-upload-page">
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/carbon-management">Carbon</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Upload CSV</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div>
        {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
        <CSVReader
          onUploadAccepted={(results) => {
            console.log(results);
            handleOnDrop(results);
          }}
          onDragOver={(event) => {
            event.preventDefault();
            setZoneHover(true);
          }}
          onDragLeave={(event) => {
            event.preventDefault();
            setZoneHover(false);
          }}
        >
          {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps, Remove }) => (
            <>
              <div {...getRootProps()} style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}>
                {acceptedFile ? (
                  <>
                    <div style={styles.file}>
                      <div style={styles.info}>
                        <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                        <span style={styles.name}>{acceptedFile.name}</span>
                      </div>
                      <div style={styles.progressBar}>
                        <ProgressBar />
                      </div>
                      <div
                        {...getRemoveFileProps()}
                        onClick={(event) => {
                          getRemoveFileProps().onClick(event);
                          handleOnRemoveFile();
                        }}
                        style={styles.remove}
                        onMouseOver={(event) => {
                          event.preventDefault();
                          setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                        }}
                        onMouseOut={(event) => {
                          event.preventDefault();
                          setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                        }}
                      >
                        <Remove color={removeHoverColor} />
                      </div>
                    </div>
                  </>
                ) : (
                  'Drop CSV file here or click to upload.'
                )}
              </div>
            </>
          )}
        </CSVReader>
      </div>
      <br />
      <Row>
        <Col lg={12}>
          <div className="csv-tbl-responsive">
            {uploadedData.length > 0 ? (
              <BootstrapTable
                classes="custom-tbl"
                columns={columns}
                keyField="Transaction_Code"
                data={uploadedData}
                noDataIndication={<TableNoData colspan="4" mainMessage="No data available" />}
                rowClasses={uploadComplete ? rowClasses : ''}
              />
            ) : (
              <TableComponentUpdated
                columns={noDataTableColumns}
                data={[]}
                setTableState={null}
                tableLoading={false}
                rowClickable={false}
                noDataIndicator={() => <TableNoData colspan="10" mainMessage="No data available" />}
              />
            )}
          </div>
        </Col>
      </Row>
      <Button
        type="submit"
        onClick={() => uploadCSV()}
        className="btn btn-primary m-2"
        disabled={!verifyPermissions('write', instance) || uploadedData.length <= 0 || uploadComplete}
      >
        {uploadCSVApiRequestInProgress && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            variant="light"
            style={{ marginRight: '10px' }}
          />
        )}
        Upload Records
      </Button>
    </div>
  );
};

export default TransactionsUploader;
