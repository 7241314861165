import React from 'react';
// import { Alert } from 'react-bootstrap';
// import { AppContext } from '../../app/App';
import Header from '../Header';
import SideNav from '../SideNav';

const PageLayout = ({ children }) => {

  return (
    <div className="page-layout">
      <SideNav />
      <Header />
      <div className="page-layout--wrapper">
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
