import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import Unauthorized from './Unauthorized';

export const RouteGuard = ({ children, roles }) => {
  const { instance } = useMsal();
  const [isAuthorized, setIsAuthorized] = useState(false);

  const onLoad = async () => {
    const currentAccount = instance.getActiveAccount();
    if (currentAccount && currentAccount.idTokenClaims.extension_Role) {
      let intersection = roles.filter((role) => currentAccount.idTokenClaims.extension_Role.includes(role));

      if (intersection.length > 0) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    }
  };

  useEffect(() => {
    onLoad();
  });
  return isAuthorized ? children : <Unauthorized />;
};
