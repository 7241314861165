import React from 'react';
import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { getCertificateTemplateInBase64 } from '../services/partyService';
import { useMsal } from '@azure/msal-react';
import Loader from './Loader';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const ECertificateModal = ({
  show,
  handleOnCancel,
  eCertificateTemplate,
  defaultCarbonAccountId,
  orderReleaseId,
  recipientName
}) => {
  const { instance } = useMsal();
  const [template, setTemplate] = useState(null);
  const [getCertificateTemplateAPIInProgress, setGetCertificateTemplateAPIInProgress] = useState(false);

  useEffect(() => {
    const loadPDF = async () => {
      setGetCertificateTemplateAPIInProgress(true);
      try {
        const pdfData = await getCertificateTemplateInBase64(
          eCertificateTemplate,
          defaultCarbonAccountId,
          recipientName || '',
          orderReleaseId,
          instance
        );
        setTemplate(`data:application/pdf;base64,${pdfData}`);
      } catch (error) {
        console.error('Error converting PDF to image:', error);
      } finally {
        setGetCertificateTemplateAPIInProgress(false);
      }
    };

    if (show) {
      loadPDF();
    }
    // eslint-disable-next-line
  }, [show]);

  return (
    <Modal show={show} onHide={handleOnCancel} className="template-preview-modal">
      {/* <Modal.Header closeButton></Modal.Header> */}
      {getCertificateTemplateAPIInProgress ? (
        <Loader />
      ) : (
        <Document file={template}>
          <Page pageNumber={1} />
        </Document>
      )}
    </Modal>
  );
};
