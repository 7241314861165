import { object, number, string, array } from 'yup';

export const tradeAccountFormSchema = object().shape({
  etsType: number()
    .nullable()
    .required('ETS Type is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  name: string().required('Trade Account Name is a required field'),
  accountNumber: string().required('Account Number is a required field'),
  usageType: number()
    .nullable()
    .required('Usage Type is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  kyotoAccountNumber: string(),
  referenceInstrument: string().when('etsType', {
    is: 3,
    then: (s) => s.required('Reference Instrument is required when ETS Type is UK')
  })
});

export const tradeAccountUpdateSchema = object().shape({
  etsType: number()
    .nullable()
    .required('ETS Type is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  name: string().required('Trade Account Name is a required field'),
  accountNumber: string().required('Account Number is a required field'),
  usageType: number()
    .nullable()
    .required('Usage Type is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  kyotoAccountNumber: string(),
  referenceInstrument: string().when('etsType', {
    is: 3,
    then: (s) => s.required('Reference Instrument is required when ETS Type is UK')
  })
});

export const transferFormSchema = object().shape({
  fromId: string().required('From is a required field'),
  toId: string().required('To is a required field'),
  transactionId: string().required('Transaction Id is a required field'),
  type: string().required('Transaction Type is a required field'),
  transactionDate: string().required('Transaction Date is a required field'),
  units: number()
    .min(1)
    .nullable()
    .required('Units is a required field')
    .transform((v, o) => (o === '' ? null : v)),
  serials: array().of(
    object().shape({
      startSerial: number()
        .nullable()
        .notRequired()
        .typeError('Start Serial must be a numeric')
        .positive('Start Serial must be a positive integer')
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .test('start-corresponding-check', 'Start Serial is required when the End Serial is filled', (value, context) =>
          !value && context.parent.endSerial ? false : true
        ),
      endSerial: number()
        .nullable()
        .notRequired()
        .typeError('End Serial must be a numeric')
        .positive('End Serial must be a positive integer')
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .test('end-corresponding-check', 'End Serial is required when the Start Serial is filled', (value, context) =>
          !value && context.parent.startSerial ? false : true
        )
    })
  )
});
