import React from 'react';
import { Breadcrumb, Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router';
import DeliverTokens from './DeliverTokens';

import OrderForm from './OrderForm';

const ManageOrder = () => {
  const location = useLocation();
  let navigate = useNavigate();
  const path = location.pathname.split('/').pop();
  const receiverName = location.state && location.state.receiverName ? location.state.receiverName : null;
  const orderNo = location.state && location.state.orderNo ? location.state.orderNo : null;
  const orderId = location.state && location.state.orderId ? location.state.orderId : null;
  const status = location.state && location.state.status ? location.state.status : null;
  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item href="/orders">Orders</Breadcrumb.Item>
            {orderId && receiverName !== 'Re-Order' ? (
              <Breadcrumb.Item onClick={() => navigate(-1)}>{orderNo ? orderNo : ''}</Breadcrumb.Item>
            ) : (
              ''
            )}
            <Breadcrumb.Item active>
              {orderId && path !== 'deliver-tokens'
                ? receiverName === 'Re-Order'
                  ? receiverName
                  : status === 4
                  ? 'View Order'
                  : 'Edit Order'
                : path === 'deliver-tokens'
                ? 'Deliver Tokens'
                : 'Create Order'}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>{path === 'deliver-tokens' ? <DeliverTokens /> : <OrderForm />}</Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ManageOrder;
