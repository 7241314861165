import { LogLevel } from "@azure/msal-browser";
import { appConfigs } from "./appConfigs";

export const b2cPolicies = {
  names: {
    signIn: appConfigs.signInPolicy,
  },
  authorities: {
    signIn: {
      authority: appConfigs.authorityUrl + appConfigs.signInPolicy
    }
  }
};

export const msalConfig = {
  auth: {
    clientId: appConfigs.clientId,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [appConfigs.authorityDomain],
    redirectUri: appConfigs.redirectUri,
    postLogoutRedirectUri: appConfigs.redirectUri,
  },
  cache: {
    cacheLocation: 'localStorage', 
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
        }
      }
    }
  }
};

export const loginRequest = {
    scopes: [appConfigs.writeScope, appConfigs.readScope]
};

export const tokenRequest = {
  scopes: ['https://graph.microsoft.com/User.ReadWrite.All']
 };

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/users'
};

export const appRoles = {
  adminUser: "adminUser", 
  c2zeroUser: "c2zeroUser",
  readOnlyUser: "readOnlyUser",
  carbonMgtUser: "carbonMgtUser"
};