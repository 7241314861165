import { Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

export const FormSkeleton = (props) => {
  return (
    <Col lg={props.size}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '20px' }}>
        <Skeleton duration={2} width={100} height={15} />
        <Skeleton duration={2} height={40} />
      </div>
    </Col>
  );
};
