import { date, object, string } from 'yup';

export const generateReportFormSchema = object().shape({
  fromDate: date()
    .required('From Date is a required field')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  toDate: date()
    .required('To Date is a required field')
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),
  reportType: string()
    .required('Report Type is a required field')
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
});
