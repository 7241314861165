import React from 'react';
import users from '../assets/images/icons/user-dark.svg';
import logout from '../assets/images/icons/log-out.svg';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Toast, ToastContainer } from 'react-bootstrap';
import { addNotification, clearNotification } from '../app/actions';
import { useEffect } from 'react';
import { getIntegrations } from '../services/xeroService';
import { AppContext } from '../app/App';
import { useLocation } from 'react-router-dom';
import info from '../assets/images/icons/info-icon.svg';
import moment from 'moment';

const Header = () => {
  const context = React.useContext(AppContext);
  const currentAccount = context.msalInstance.getActiveAccount();
  const notificationAlert = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const location = useLocation();

  function handleLogout(instance) {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }

  const getAllXeroIntegrations = async () => {
    try {
      const response = await getIntegrations(context.msalInstance);
      if (response && response.length > 0) {
        if (moment.utc(response[0].expireAtUTC).isBefore(moment.utc().format())) {
          dispatch(addNotification('danger', '', 'XERO integration expired'));
        }
      } else {
        dispatch(addNotification('danger', '', "You Haven't Configured A XERO Integration"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const getAllXeroIntegrations = async () => {
  //   try {
  //     const response = await getIntegrations(context.msalInstance);
  //     if (response && response.length < 0) {
  //       dispatch(addNotification('danger', '', `You Haven't Configured A XERO Integration`));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    getAllXeroIntegrations();
    //eslint-disable-next-line
  }, []);

  return (
    <nav className="header">
      <div className="user-details">
        <img className="user-icon" src={users} alt="user icon" />
        <span className="user-name">{currentAccount?.idTokenClaims?.given_name}</span>
        <span className="logout" onClick={() => handleLogout(context.msalInstance)}>
          <img alt="" src={logout} />
        </span>
      </div>
      {notificationAlert.page !== location.pathname && notificationAlert && notificationAlert.message && (
        <ToastContainer position="top-center" className="p-3">
          <Toast
            bg={notificationAlert.status}
            onClose={() => dispatch(clearNotification())}
            show={notificationAlert}
            delay={10000}
            autohide
          >
            <Toast.Header>
              <img src={info} alt="info-icon" />
              <span> Notification</span>
            </Toast.Header>
            <Toast.Body>{notificationAlert.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </nav>
  );
};

export default Header;
