import React, { useEffect, useState } from 'react';
import { Alert, Breadcrumb } from 'react-bootstrap';
import AddOrder from '../../assets/images/icons/plus.svg';
import { NavLink } from 'react-router-dom';
import TableNoData from '../../components/TableNoData';
import { getAllOrders } from '../../services/orderService';
import { AppContext } from '../../app/App';
import { orderStatus, recordsPerPage } from '../../util/constants';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import { formatDate } from '../../common/formatter';
import { SearchBarUpdated } from '../../components/SearchBar';
import { Dropdown } from 'react-bootstrap';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import Dropdownmenu from '../../components/Dropdown';
import PaginationComponent from '../../components/PaginationComponent';
import { createColumnHelper } from '@tanstack/table-core';
import TableComponentUpdated from '../../components/TableNew';

const Orders = (props) => {
  const context = React.useContext(AppContext);

  const [allOrders, setAllOrders] = useState([]);
  const [getOrdersApiRequestInProgress, setGetOrdersApiRequestInProgress] = useState(true);
  const [notification, setNotificationMessage] = useState(null);

  const [totalCount, setTotalCount] = useState(0);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(null); // null: initial state before first API call trigger, true: empty, false: not empty

  const [tableState, setTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('orderNo', {
      cell: (info) => info.getValue(),
      header: () => <span>Order No</span>
    }),
    columnHelper.accessor((row) => row.receiver.name, {
      id: 'Company',
      cell: (info) => <span>{info.getValue()}</span>,
      header: () => <span>Company</span>,
      enableSorting: false
    }),
    columnHelper.accessor('createdOn', {
      header: () => 'Date',
      cell: (info) => <span>{formatDate(info.getValue())}</span>
    }),
    columnHelper.accessor('tokenCount', {
      header: () => <span>Token Count</span>,
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('carbonUnits', {
      header: 'Carbon Units',
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('status', {
      header: 'Order Status',
      cell: (info) => orderStatus[info.getValue()]
    }),
    columnHelper.accessor('vault.name', {
      header: 'Carbon Account',
      cell: (info) => info.getValue()
    }),
    columnHelper.accessor('', {
      header: 'Action',
      cell: (info) => (
        <div className="dropdownmenu">
          <Dropdown>
            <Dropdown.Toggle variant="link" data-testid="toggle-btn">
              <BiDotsVerticalRounded />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdownmenu
                path={getOrderActions(info.row.original)[0].routeUrl}
                id={info.row.original.id}
                receiver={info.row.original.receiver ? info.row.original.receiver.name : ''}
                action={getOrderActions(info.row.original)[0].action}
                className={'view-order-link'}
              />
              <Dropdown.Divider />
              <Dropdownmenu
                path={getOrderActions(info.row.original)[1].routeUrl}
                id={info.row.original.id}
                receiver={'Re-Order'}
                action={getOrderActions(info.row.original)[1].action}
                className={'re-order-link'}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )
    })
  ];

  const getOrderActions = (row) => {
    switch (row.satus) {
      case 1:
        return [
          { routeUrl: `/orders/view-order`, action: 'View Order' },
          { routeUrl: `/orders/re-order`, action: 'Reorder' }
        ];

      case 2:
        let actions = [
          { routeUrl: `/orders/view-order`, action: 'View Order' },
          { routeUrl: `/orders/re-order`, action: 'Reorder' }
        ];
        return actions;
      case 3:
        return [
          { routeUrl: `/orders/view-order`, action: 'View Order' },
          { routeUrl: `/orders/re-order`, action: 'Reorder' }
        ];
      case 4:
        return [
          { routeUrl: `/orders/view-order`, action: 'View Order' },
          { routeUrl: `/orders/re-order`, action: 'Reorder' }
        ];

      default:
        return [
          { routeUrl: `/orders/view-order`, action: 'View Order' },
          { routeUrl: `/orders/re-order`, action: 'Reorder' }
        ];
    }
  };

  const getOrders = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetOrdersApiRequestInProgress(true);

    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllOrders(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllOrders(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      setNotifications('danger', error?.title);
      console.log(error);
    } finally {
      setGetOrdersApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
      },
      type === 'success' ? 2000 : 5000
    );
  };
  const handleOrdersSearchChange = (value) => {
    setTableState((prevState) => ({
      ...prevState,
      searchText: value,
      sortField: null,
      isAscSort: null,
      pageNumber: 1
    }));
  };
  useEffect(() => {
    getOrders(tableState.pageNumber, null, null, null, true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getOrders(tableState.pageNumber, tableState.searchText, tableState.sortField?.toLowerCase(), tableState.isAscSort);
    // eslint-disable-next-line
  }, [tableState]);

  return (
    <section>
      <div className="orders-sec">
        <div className="page-header">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Orders</Breadcrumb.Item>
            </Breadcrumb>
            <SearchBarUpdated searchHandler={handleOrdersSearchChange} className="table-search" />
          </div>
          <div className="orders-sec-btngrp">
            {' '}
            <NavLink to="/orders/paused-order-releases" className="btn btn-warning">
              Paused Order Releases
            </NavLink>
            <NavLink to="/orders/create-order" className="btn btn-primary">
              <img src={AddOrder} alt="add Order icon" />
              Create Order
            </NavLink>
          </div>
        </div>
        {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
        {isTableInitiallyEmpty === null ? (
          <Loader />
        ) : isTableInitiallyEmpty === true ? (
          <React.Fragment>
            <NoData
              link="/orders/create-order"
              buttonText="Create New Order"
              mainMessage="You do not have Orders right now!"
              secondaryMessage="Please click 'Create New Order' to add a new Order"
              icon={AddOrder}
            />
          </React.Fragment>
        ) : (
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="order-tbl-responsive">
                <TableComponentUpdated
                  columns={columns}
                  data={allOrders}
                  setTableState={setTableState}
                  tableLoading={getOrdersApiRequestInProgress}
                  rowClickable={false}
                  noDataIndicator={() => <TableNoData colspan="6" mainMessage="No data available" />}
                />
                <PaginationComponent
                  totalCount={totalCount}
                  recordsPerPage={recordsPerPage}
                  setTableState={setTableState}
                  tableState={tableState}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Orders;
