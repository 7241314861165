import React from 'react';

const DescendingIcon = () => (
  <svg
    viewBox="0 0 36 36"
    aria-hidden="true"
    role="img"
    class="ic-sort-svg"
    preserveAspectRatio="xMidYMid meet"
    fill="#000000"
    transform="matrix(-1, 0, 0, 1, 0, 0)rotate(180)"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path fill="#00e1be" d="M36 32a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v28z"></path>
      <path fill="#FFF" d="M22 29v-9h7L18 7L7 20h7v9z"></path>
    </g>
  </svg>
);

export default DescendingIcon;
