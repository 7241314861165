import { object, string, number, array, addMethod } from 'yup';
import { tokenDeliveryMethods, tokenDeliveryTypes } from '../../util/constants';

addMethod(array, 'unique', function (message, mapper = (a) => a) {
  return this.test('unique', message, function (list) {
    const len = list.length;
    for (let i = 0; i < len; i++) {
      let batch = list[i].batch;
      for (let j = i + 1; j < len; j++) {
        if (batch === list[j].batch) {
          return this.createError({
            path: `orderRelease.[${j}].batch`,
            message: 'Batch name should be unique'
          });
        }
      }
    }
    return true;
  });
});

export const orderFormSchema = object().shape({
  receiverId: string().required('Company / Individual Name is a required field'),
  vaultId: string().required('Carbon Account is a required field'),
  brand: string(),
  product: object().shape({
    name: string().required('Product Name is a required field'),
    emissionPerItem: number()
      .min(1, 'Carbon Quantity per Token should be greater than or equal 1')
      .required('Carbon Quantity per Token is a required field')
      .nullable()
      .transform((v, o) => (o === '' ? null : v)),
    emissionPerItemUnit: string().required('Unit is a required field'),
    text: string().required('Product text is a required field').nullable()
  }),
  orderRelease: array()
    .of(
      object().shape({
        batch: string().required('Batch is a required field'),
        tokenCount: number()
          .min(1, 'Token Count should be greater than or equal 1')
          .required('Token Count is a required field')
          .nullable()
          .transform((v, o) => (o === '' ? null : v))
      })
    )
    .unique('Batch must be unique'),
  orderType: number().required('Order Type is a required field')
});

export const generateTokenFormSchema = object().shape({
  tokenDeliveryMethod: string().when('tokenDeliveryType', {
    is: (val) => val === tokenDeliveryTypes.qrCodes,
    then: () => string().required('Token delivery method is a required field')
  }),
  tokenDeliveryType: string().required('Token delivery type is a required field'),
  email: string().when(['tokenDeliveryMethod', 'tokenDeliveryType'], {
    is: (tokenDeliveryMethod, tokenDeliveryType) =>
      tokenDeliveryMethod === tokenDeliveryMethods.email || tokenDeliveryType === tokenDeliveryTypes.urls,
    then: () => string().required('Email is a required field')
  }),
  size: number()
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .when('tokenDeliveryType', {
      is: (val) => val === tokenDeliveryTypes.qrCodes,
      then: () =>
        number()
          .required('Size is a required field')
          .nullable()
          .min(100, 'QR code size should be greater than or equal 100px')
          .transform((v, o) => (o === '' ? null : v))
    }),
  imageType: string().when('tokenDeliveryType', {
    is: (val) => val === tokenDeliveryTypes.qrCodes,
    then: () => string().required('Image type is a required field')
  }),
  eCertificateTemplate: string().when('tokenDeliveryType', {
    is: (val) => val === tokenDeliveryTypes.eCertificate,
    then: () => string().required('Certificate template is a required field')
  }),
  recipientName: string().when('tokenDeliveryType', {
    is: (val) => val === tokenDeliveryTypes.eCertificate,
    then: () => string().required('Recipient Name is a required field')
  })
});
