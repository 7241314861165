import React from 'react';
import { PieChart, Pie, Cell, Legend } from 'recharts';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { fortmatTimStampWithTz } from '../../common/formatter';
import { useState } from 'react';
import Loader from '../../components/Loader';
import NoData from '../../components/NoData';
import AddOrder from '../../assets/images/icons/plus.svg';

const AnalyticsChart = ({
  title,
  width,
  height,
  data,
  colors,
  date,
  filterValues,
  onChangeData,
  isLoading,
  placeholder
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [id, setId] = useState(null);
  const defaultData = [{ name: 'No Data', value: 1 }];
  const initialSettings = {
    locale: {
      format: 'DD-MM-YYYY',
      separator: ' - '
    },
    startDate: startDate,
    endDate: endDate
  };
  let sum = 0;

  const filterData = (start = startDate, end = endDate, value = id) => {
    setStartDate(start);
    setEndDate(end);
    setId(value);
    onChangeData(value, start ? fortmatTimStampWithTz(start) : null, end ? fortmatTimStampWithTz(end) : null);
  };

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className="row">
            <div className="col-12 col-sm-3 col-md-12 col-lg-3">
              <h5>{title ? title : ''}</h5>
            </div>
            <div className="col-12 col-sm-9 col-md-12 col-lg-9">
              <div className="input-fld">
                <div className="input1">
                  <Select
                    isSearchable={true}
                    isClearable={true}
                    placeholder={placeholder}
                    options={filterValues}
                    defaultValue={filterValues?.filter((item) => item.id === id)}
                    classNamePrefix="react-select"
                    onChange={(e) => filterData(startDate, endDate, e?.id ? e.id : null)}
                  />
                </div>
                <div className="input2">
                  {date ? (
                    <DateRangePicker initialSettings={initialSettings} onCallback={filterData}>
                      <input type="text" className="form-control" />
                    </DateRangePicker>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          {data ? (
            <PieChart width={width} height={height}>
              <Pie
                data={data}
                labelLine={false}
                cx="50%"
                cy="30%"
                dataKey="value"
                outerRadius={100}
                innerRadius={80}
                fill="#8884d8"
                label={data}
                paddingAngle={2}
                minAngle={20}
              >
                {data.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % data.length]} />
                ))}
                {data.map((_, index) => (sum = sum + data[index].value))}
              </Pie>
              {sum === 0 ? (
                <Pie
                  data={defaultData}
                  labelLine={false}
                  cx="50%"
                  cy="30%"
                  dataKey="value"
                  outerRadius={100}
                  innerRadius={80}
                  fill="#8884d8"
                >
                  {defaultData.map((_, index) => (
                    <Cell key={`cell-0`} fill="#C0C0C0" />
                  ))}
                </Pie>
              ) : (
                ''
              )}
              <Legend layout="vertical" verticalAlign="middle" align="left" iconType="rect" content={data} />
            </PieChart>
          ) : (
            <NoData
              mainMessage="You do not have any analytics data at the moment"
              secondaryMessage=""
              icon={AddOrder}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AnalyticsChart;
