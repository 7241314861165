import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createUser, getUser, updateUser } from '../../services/userService';
import { Alert, Breadcrumb, Card, Button, Form, Spinner } from 'react-bootstrap';
import { userFormSchema, userUpdateSchema } from './formValidation';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FormSkeleton } from '../../components/formSkeleton';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// import { SkeletonTheme } from 'react-loading-skeleton';
import { userCategories, userRolesArray } from '../../util/constants';
import { verifyPermissions } from '../../common/permissionVerifier';

const ManageUser = (props) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const location = useLocation();
  const subjectId = location?.state && location.state.subjectId ? location.state.subjectId : null;
  const [notification, setNotificationMessage] = useState(null);
  const [initialValues, setInitialValues] = useState({
    roles: '',
    userCategory: null,
    firstName: '',
    lastName: '',
    mobileNumber: null,
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [getUserApiRequestInProgress, setGetUserApiRequestInProgress] = useState(true);
  const [saveUsersApiRequestInProgress, setSaveUsersApiRequestInProgress] = useState(false);
  const [selectedUserTypes, setSelectedUserTypes] = useState(null);
  const [eligibleUserRoles, setEligibleUserRoles] = useState(userRolesArray);
  const userName = location?.state && location.state.userName ? location.state.userName : null;
  const animatedComponents = makeAnimated();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm({
    defaultValues: { ...initialValues },
    resolver: yupResolver(subjectId ? userUpdateSchema : userFormSchema),
    mode: 'all'
  });

  const resetForm = () => {
    reset();
    setSelectedUserTypes(null);
    setEligibleUserRoles(userRolesArray);
  };

  const onSubmitHandler = (data) => {
    const rolesStringValue = data.roles.map((item) => item.value).join(',');

    setSaveUsersApiRequestInProgress(true);
    if (subjectId) {
      userUpdateSchema
        .validate(data)
        .then(function (value) {
          delete data['confirmPassword'];
          const requestData = {
            ...value,
            mobileNumber: value.mobileNumber ? value.mobileNumber.toString() : null,
            roles: rolesStringValue
          };

          updateSingleUser(requestData);
        })
        .catch(function (err) {
          console.log(err);
          setSaveUsersApiRequestInProgress(false);
        });
    } else {
      userFormSchema
        .validate(data)
        .then(function (value) {
          delete data['confirmPassword'];
          const requestData = {
            ...value,
            mobileNumber: value.mobileNumber ? value.mobileNumber.toString() : null,
            roles: rolesStringValue
          };

          createNewUser(requestData);
        })
        .catch(function (err) {
          console.log(err);
          setSaveUsersApiRequestInProgress(false);
        });
    }
  };

  const createNewUser = async (userData) => {
    setSaveUsersApiRequestInProgress(true);
    try {
      const resp = await createUser(userData, instance);
      if (resp) {
        await setNotifications('success', 'User Successfully Created', '/users');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveUsersApiRequestInProgress(false);
    }
  };

  const updateSingleUser = async (userData) => {
    setSaveUsersApiRequestInProgress(true);
    try {
      const resp = await updateUser(subjectId, userData, instance);
      if (resp) {
        await setNotifications('success', 'User Successfully Updated', '/users');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveUsersApiRequestInProgress(false);
    }
  };

  const getUserDetails = async (subjectId) => {
    setGetUserApiRequestInProgress(true);
    try {
      const response = await getUser(subjectId, instance);
      if (response) {
        response.roles = getRolesMap(response.roles.split(','));
        response.mobileNumber = response.mobileNumber ? response.mobileNumber.toString() : null;
        const eligibleUserRoles = filterRoles(response.roles);
        setEligibleUserRoles(eligibleUserRoles);
        setSelectedUserTypes(response.roles);
        setInitialValues(response);
        reset(response);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetUserApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          navigate(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };

  const handleChange = (selectedOptions) => {
    const eligibleUserRoles = filterRoles(selectedOptions);
    setSelectedUserTypes(selectedOptions);
    setEligibleUserRoles(eligibleUserRoles);
  };

  const getRolesMap = (roles) => {
    return userRolesArray.filter((item) => roles.includes(item.value));
  };

  const filterRoles = (roleList) => {
    return userRolesArray.filter((item) => {
      if (roleList.length > 0) {
        if (roleList[0] === userRolesArray[1]) {
          return item === userRolesArray[3];
        } else {
          if (roleList[0] === userRolesArray[3]) {
            return item === userRolesArray[1];
          } else {
            return roleList[0] === item;
          }
        }
      } else {
        return item;
      }
    });
  };

  useEffect(() => {
    if (subjectId) {
      getUserDetails(subjectId);
    } else {
      setGetUserApiRequestInProgress(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/users">Users</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{subjectId ? userName : 'Create User'}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <Card>
            <Card.Body>
              {notification && notification.message && (
                <Alert variant={notification.type}>{notification.message}</Alert>
              )}
              <Form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="none" noValidate>
                <div className="row">
                  {getUserApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <React.Fragment>
                      <div className="col-lg-6">
                        <Form.Label>User Type</Form.Label>
                        <Controller
                          name="roles"
                          control={control}
                          {...register('roles')}
                          render={({ field, fieldState: { invalid, isTouched, isDirty, error }, formState }) => {
                            return (
                              <React.Fragment>
                                <Select
                                  placeholder="Select the User Type"
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  defaultValue={initialValues.roles}
                                  value={selectedUserTypes}
                                  isMulti
                                  isDisabled={
                                    (!verifyPermissions('createAnyUser', instance) &&
                                      initialValues.roles.includes(userRolesArray[0])) ||
                                    !verifyPermissions('write', instance)
                                  }
                                  options={
                                    !verifyPermissions('createAnyUser', instance) &&
                                    eligibleUserRoles.includes(userRolesArray[0])
                                      ? eligibleUserRoles.slice(1)
                                      : eligibleUserRoles
                                  }
                                  onChange={(selectedOptions) => {
                                    field.onChange(selectedOptions || '');
                                    handleChange(selectedOptions);
                                  }}
                                  isInvalid={invalid}
                                  onTouched={field.onTouched}
                                  onBlur={field.onBlur}
                                  classNamePrefix="react-select"
                                />
                                {(invalid || isTouched || isDirty || error) && (
                                  <div className="form-error">{errors.roles?.message}</div>
                                )}
                              </React.Fragment>
                            );
                          }}
                        />
                      </div>
                    </React.Fragment>
                  )}
                  {getUserApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <React.Fragment>
                      <div className="col-lg-6">
                        <Form.Label>User Category</Form.Label>
                        <Form.Control
                          as="select"
                          type="select"
                          {...register('userCategory')}
                          isInvalid={errors.userCategory}
                          disabled={
                            (!verifyPermissions('createAnyUser', instance) &&
                              initialValues.roles.includes(userRolesArray[0])) ||
                            !verifyPermissions('write', instance)
                          }
                        >
                          <option value="">Select the User Category</option>
                          {Object.keys(userCategories).map((userCategory, index) => (
                            <option key={index} value={userCategory}>
                              {userCategories[userCategory]}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.userCategory?.message}</Form.Control.Feedback>
                      </div>
                    </React.Fragment>
                  )}
                  {getUserApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        {...register('firstName')}
                        isInvalid={errors.firstName}
                        placeholder="Please enter first name"
                        autoComplete="none"
                        disabled={
                          (!verifyPermissions('createAnyUser', instance) &&
                            initialValues.roles.includes(userRolesArray[0])) ||
                          !verifyPermissions('write', instance)
                        }
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.firstName?.message}</Form.Control.Feedback>
                    </div>
                  )}
                  {getUserApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        {...register('lastName')}
                        isInvalid={errors.lastName}
                        placeholder="Please enter last name"
                        autoComplete="none"
                        disabled={
                          (!verifyPermissions('createAnyUser', instance) &&
                            initialValues.roles.includes(userRolesArray[0])) ||
                          !verifyPermissions('write', instance)
                        }
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.lastName?.message}</Form.Control.Feedback>
                    </div>
                  )}
                  {getUserApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        {...register('mobileNumber')}
                        isInvalid={errors.mobileNumber}
                        placeholder="Please enter mobile number"
                        autoComplete="none"
                        type="text"
                        disabled={
                          (!verifyPermissions('createAnyUser', instance) &&
                            initialValues.roles.includes(userRolesArray[0])) ||
                          !verifyPermissions('write', instance)
                        }
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.mobileNumber?.message}</Form.Control.Feedback>
                    </div>
                  )}
                  {getUserApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        {...register('email')}
                        isInvalid={errors.email}
                        placeholder="Please provide an email"
                        autoComplete="none"
                        disabled={
                          (!verifyPermissions('createAnyUser', instance) &&
                            initialValues.roles.includes(userRolesArray[0])) ||
                          !verifyPermissions('write', instance)
                        }
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                    </div>
                  )}
                  {getUserApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        {...register('password')}
                        isInvalid={errors.password}
                        placeholder="Please enter password"
                        autoComplete="new-password"
                        disabled={
                          (!verifyPermissions('createAnyUser', instance) &&
                            initialValues.roles.includes(userRolesArray[0])) ||
                          !verifyPermissions('write', instance)
                        }
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
                    </div>
                  )}
                  {getUserApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <div className="col-lg-6">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        {...register('confirmPassword')}
                        isInvalid={errors.confirmPassword}
                        placeholder="Please enter password again to confirm"
                        autoComplete="none"
                        disabled={
                          (!verifyPermissions('createAnyUser', instance) &&
                            initialValues.roles.includes(userRolesArray[0])) ||
                          !verifyPermissions('write', instance)
                        }
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.confirmPassword?.message}</Form.Control.Feedback>
                    </div>
                  )}
                </div>
                <div className="form-buttons">
                  {subjectId ? (
                    <NavLink to="/users" className="btn btn-light">
                      Cancel
                    </NavLink>
                  ) : (
                    <span onClick={resetForm} className="btn btn-light">
                      Clear
                    </span>
                  )}
                  <Button
                    type="submit"
                    className="btn btn-primary m-2"
                    disabled={
                      getUserApiRequestInProgress ||
                      saveUsersApiRequestInProgress ||
                      (!verifyPermissions('createAnyUser', instance) &&
                        initialValues.roles.includes(userRolesArray[0])) ||
                      !verifyPermissions('write', instance)
                    }
                  >
                    {saveUsersApiRequestInProgress && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        variant="light"
                        style={{ marginRight: '10px' }}
                      />
                    )}
                    {subjectId ? 'Update User' : 'Create User'}
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ManageUser;
