const initialState = {
  status: '',
  page: '',
  message: ''
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'NOTIFY':
      return { status: action.status, page: action.page, message: action.message };
    case 'CLEAR':
      return initialState;
    default:
      return state;
  }
};
