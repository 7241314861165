import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import TableNoData from '../../components/TableNoData';
import { getFilteredOrderReleases } from '../../services/orderService';
import { AppContext } from '../../app/App';
import { recordsPerPage } from '../../util/constants';
import Loader from '../../components/Loader';
// import SearchBar from '../../components/SearchBar';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { createColumnHelper } from '@tanstack/table-core';
import TableComponentUpdated from '../../components/TableNew';
import PaginationComponent from '../../components/PaginationComponent';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('orderNo', {
    cell: (info) => info.getValue(),
    header: () => <span>Order No</span>,
    enableSorting: true
  }),
  columnHelper.accessor('batch', {
    cell: (info) => (info.getValue() ? info.getValue() : '-'),
    header: () => <span>Batch</span>,
    enableSorting: true
  }),
  columnHelper.accessor('productName', {
    cell: (info) => info.getValue(),
    header: () => <span>Product</span>,
    enableSorting: true
  }),
  columnHelper.accessor('tokenCount', {
    cell: (info) => info.getValue(),
    header: () => <span>Token Count</span>,
    enableSorting: true
  }),
  columnHelper.accessor('carbonEmission', {
    cell: (info) => info.getValue(),
    header: () => <span>Carbon Emission</span>,
    enableSorting: true
  }),
  columnHelper.accessor('xeroInvoiceStatus', {
    cell: (info) => (info.getValue() ? info.getValue() : '-'),
    header: () => <span>Invoice Status</span>,
    enableSorting: true
  }),
  columnHelper.accessor('status', {
    cell: (info) => (info.getValue() ? info.getValue() : '-'),
    header: () => <span>Release Status</span>,
    enableSorting: true
  })
];

const OrderReleases = (props) => {
  const context = React.useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [allOrders, setAllOrders] = useState([]);
  const [getOrdersApiRequestInProgress, setGetOrdersApiRequestInProgress] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const approvalStatus =
    location.state && location.state.approvalStatus !== null ? location.state.approvalStatus : null;
  const [totalCount, setTotalCount] = useState(0);
  // const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(false);
  const [tableState, setTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });

  const ordersTableRowEvents = {
    onClick: (row) => {
      navigate(`/orders/view-order/${row.orderId}/${row.id}`, {
        state: {
          orderId: row.orderId,
          orderReleaseId: row.id,
          receiverName: row.company
        }
      });
    }
  };

  const getOrders = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setTableLoading(true);
    const filters = [
      {
        key: 'Status',
        value: String(approvalStatus)
      }
    ];
    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort,
        filters
      };

      const response = await getFilteredOrderReleases(data, context.msalInstance);

      if (response && response.paginatedList) {
        setAllOrders(response.paginatedList);
        setTotalCount(response.totalCount);
        // if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
        // setIsTableInitiallyEmpty(true);
        // } else {
        // setIsTableInitiallyEmpty(false);
        setTableLoading(false);
        // }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetOrdersApiRequestInProgress(false);
    }
  };

  useEffect(() => {
    getOrders(tableState.pageNumber);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getOrders(tableState.pageNumber, tableState.searchText, tableState.sortField?.toLowerCase(), tableState.isAscSort);
    // eslint-disable-next-line
  }, [tableState]);

  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;
  return (
    <section>
      <div>
        <div className="page-header">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Order Releases</Breadcrumb.Item>
            </Breadcrumb>
            {/* <SearchBar apiRequest={getOrders} className="table-search" /> */}
          </div>
        </div>
        {getOrdersApiRequestInProgress ? (
          <Loader />
        ) : (
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <TableComponentUpdated
                columns={columns}
                data={allOrders}
                setTableState={setTableState}
                rowClickable={true}
                onRowClick={ordersTableRowEvents.onClick}
                tableLoading={tableLoading}
                noDataIndicator={NoDataIndication}
              />
              <PaginationComponent
                totalCount={totalCount}
                recordsPerPage={recordsPerPage}
                setTableState={setTableState}
                tableState={tableState}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default OrderReleases;
