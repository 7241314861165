import React, { useEffect } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import AscendingIcon from './AscendingIcon';
import DescendingIcon from './DescendingIcon';
const TableComponentUpdated = ({
  columns,
  data,
  rowClickable = false,
  onRowClick,
  setTableState,
  tableLoading,
  noDataIndicator
}) => {
  const [sorting, setSorting] = React.useState([]);
  const [, setPagination] = React.useState([]);
  useEffect(() => {
    if (sorting.length !== 0) {
      setTableState((prevState) => ({
        ...prevState,
        sortField: sorting[0].id,
        isAscSort: sorting[0].desc ? false : true,
        pageNumber: 1
      }));
    }
    // eslint-disable-next-line
  }, [sorting]);
  const tableInstance = useReactTable({
    columns: columns,
    data: data,
    state: {
      sorting
    },
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    manualPagination: true,
    sortDescFirst: false,
    onSortingChange: setSorting,
    manualSorting: true,
    debugTable: true
  });

  const handleRowClick = (row) => {
    rowClickable && onRowClick && onRowClick(row);
  };
  return (
    <div>
      <div className="react-bootstrap-table">
        <table className="table table-hover table-bordered custom-tbl">
          <thead>
            {tableInstance.getHeaderGroups().map((headerEl) => {
              return (
                <tr key={headerEl.id}>
                  {headerEl.headers.map((columnEl) => {
                    return (
                      // <th
                      //   key={columnEl.id}
                      //   colSpan={columnEl.colSpan}
                      //   onClick={columnEl.column.getToggleSortingHandler()}
                      // >
                      //   {columnEl.isPlaceholder
                      //     ? null
                      //     : flexRender(columnEl.column.columnDef.header, columnEl.getContext())}
                      //   {{
                      //     asc: '🔼',
                      //     desc: '🔽'
                      //   }[columnEl.column.getIsSorted()] ?? null}
                      // </th>

                      <th
                        key={columnEl.id}
                        colSpan={columnEl.colSpan}
                        onClick={columnEl.column.getToggleSortingHandler()}
                        className={`${columnEl.column.getToggleSortingHandler() ? 'sortable-column' : ''}`}
                      >
                        {columnEl.isPlaceholder
                          ? null
                          : flexRender(columnEl.column.columnDef.header, columnEl.getContext())}
                        {columnEl.column.getIsSorted() === 'asc' && <AscendingIcon />}
                        {columnEl.column.getIsSorted() === 'desc' && <DescendingIcon />}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          {data.length === 0 ? (
            noDataIndicator()
          ) : (
            // <tbody className={tableLoading ? 'table-body-backdrop' : ''}>
            <tbody>
              <span className={tableLoading ? 'table-body-backdrop' : ''}></span>
              {tableInstance.getRowModel().rows.map((rowEl) => {
                const rowStyle = rowClickable ? { cursor: 'pointer' } : {};
                return (
                  <tr key={rowEl.id} onClick={() => handleRowClick(rowEl.original)} style={rowStyle}>
                    {rowEl.getVisibleCells().map((cellEl) => {
                      return <td key={cellEl.id}>{flexRender(cellEl.column.columnDef.cell, cellEl.getContext())}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
        <div className="h-4" />
      </div>
    </div>
  );
};

export default TableComponentUpdated;

/**
 * window.table = tableInstance;
 * todo:
 * table body should be a backdrop while data is loading.: to be done by UX
 * handle when search is empty
 */
