import React from 'react';
import check from '../../assets/images/icons/check.svg';

const OrderSteps = (props) => {
  return (
    <div className="order-steps">
      <ul className="order-steps-list">
        {props.data.map((step, i) => (
          <li key={i} className="step">
            <div className={step.completed ? 'step-circle completed' : 'step-circle'}>
              <img className="step-tick" src={check} alt="step icon" />
            </div>
            <span className="step-text">{step.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrderSteps;
