import { BrowserAuthError } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import axios from 'axios';
import { appConfigs } from './appConfigs';
import { loginRequest } from './authConfig';

axios.defaults.baseURL = `${appConfigs.apiHost}`;

const unhandledError = {
  title: 'Something Went Wrong, Please Contact Technical Team.',
  status: 500
};

export const post = async (url, data, authenticationRequired, msalInstance) => {
  let options = {
    data,
    url,
    method: 'post',
    responseType: 'json'
  };

  options = await addRequestHeaders(options, authenticationRequired, msalInstance);
  return axios(options);
};

export const put = async (url, data, authenticationRequired, msalInstance) => {
  let options = {
    data,
    url,
    method: 'put',
    responseType: 'json'
  };

  options = await addRequestHeaders(options, authenticationRequired, msalInstance);
  return axios(options);
};

export const deleteReq = async (url, data, authenticationRequired, msalInstance) => {
  let options = {
    data,
    url,
    method: 'delete',
    responseType: 'json'
  };

  options = await addRequestHeaders(options, authenticationRequired, msalInstance);
  return axios(options);
};

export const patch = async (url, data, authenticationRequired, msalInstance) => {
  let options = {
    data,
    url,
    method: 'patch',
    responseType: 'json'
  };

  options = await addRequestHeaders(options, authenticationRequired, msalInstance);
  return axios(options);
};

export const get = async (url, params, authenticationRequired, msalInstance) => {
  let options = {
    params,
    url,
    method: 'get',
    responseType: 'json'
  };

  options = await addRequestHeaders(options, authenticationRequired, msalInstance);
  return axios(options);
};

export const remove = async (url, authenticationRequired, msalInstance) => {
  let options = {
    url,
    method: 'delete',
    responseType: 'json'
  };

  options = await addRequestHeaders(options, authenticationRequired, msalInstance);
  return axios(options);
};

const addRequestHeaders = async (options, authenticationRequired, msalInstance) => {
  const token = await getToken(msalInstance);
  options.headers = {};
  if (authenticationRequired) {
    options.headers.Authorization = `Bearer ${token.accessToken}`;
  }
  return options;
};

const getToken = async (msalInstance) => {
  try {
    return await msalInstance.acquireTokenSilent(loginRequest);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
      SignInRedirect(msalInstance);
    }

    if (error.response && error.response.status === 401) {
      // Redirect the user to login
      SignInRedirect(msalInstance);
    } else {
      throw error.response ? error.response.data : error;
    }
  }
};

const SignInRedirect = async (msalInstance) => {
  msalInstance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
  });
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect the user to login
      SignInRedirect();
    } else if (error.response && error.response.status === 500) {
      const errorResp = {
        ...unhandledError,
        detail: error.response.data.detail
      };
      throw errorResp;
    } else {
      throw error.response ? error.response.data : error;
    }
  }
);

export const postXeroToken = async (url, code) => {
  const qs = require('qs');
  let data = qs.stringify({
    grant_type: 'authorization_code',
    client_id: appConfigs.xeroxClientId,
    code: code,
    redirect_uri: appConfigs.xeroxRedirectUri,
    code_verifier: localStorage.getItem('verifier')
  });

  let options = {
    url,
    method: 'post',
    header: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: data
  };
  return axios(options);
};
