import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import addUserIcon from '../../assets/images/icons/user-plus.svg';
import { NavLink } from 'react-router-dom';

import { getAllCustomers } from '../../services/partyService';
import { AppContext } from '../../app/App';
import NoData from '../../components/NoData';
import Loader from '../../components/Loader';
import { recordsPerPage } from '../../util/constants';
import TableNoData from '../../components/TableNoData';
import { SearchBarUpdated } from '../../components/SearchBar';
import { createColumnHelper } from '@tanstack/table-core';
import TableComponentUpdated from '../../components/TableNew';
import PaginationComponent from '../../components/PaginationComponent';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('name', {
    cell: (info) => info.getValue(),
    header: () => <span>Comapany/Individual Name</span>,
    enableSorting: true
  }),
  columnHelper.accessor('primaryContactName', {
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => <span>Key Contact</span>,
    enableSorting: true
  }),
  columnHelper.accessor('primaryContactTelephone', {
    header: () => 'Mobile',
    cell: (info) => <span>{info.getValue()}</span>,
    enableSorting: false
  })
];

const Customers = () => {
  const context = React.useContext(AppContext);
  let navigate = useNavigate();
  const [allCustomers, setAllCustomers] = useState([]);
  const [getCustomersApiRequestInProgress, setGetCustomersApiRequestInProgress] = useState(true);

  const [totalCount, setTotalCount] = useState(0);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(null);
  const [tableState, setTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });

  const customersTableRowEvents = {
    onClick: (row) => {
      navigate(`/customers/edit-customer`, {
        state: {
          customerId: row.id,
          customerName: row.name,
          customerActiveStatus: row.onboardStatus
        }
      });
    }
  };
  const handleCustomersSearchChange = (value) => {
    setTableState((prevState) => ({
      ...prevState,
      searchText: value,
      pageNumber: 1
    }));
  };

  const getCustomers = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetCustomersApiRequestInProgress(true);

    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllCustomers(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllCustomers(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetCustomersApiRequestInProgress(false);
    }
  };

  useEffect(() => {
    getCustomers(tableState.pageNumber);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCustomers(
      tableState.pageNumber,
      tableState.searchText,
      tableState.sortField?.toLowerCase(),
      tableState.isAscSort,
      true
    );
    // eslint-disable-next-line
  }, [tableState]);

  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;

  return (
    <section>
      <div>
        <div className="page-header">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item>Customers</Breadcrumb.Item>
            </Breadcrumb>
            {/* todo: remove previous search bar and do the relevant renames  */}
            {/* <SearchBar apiRequest={getCustomers} className="table-search" /> */}
            <SearchBarUpdated searchHandler={handleCustomersSearchChange} className="table-search" />
          </div>
          <NavLink to="/customers/create-customer" className="btn btn-primary">
            <img src={addUserIcon} alt="add user icon" />
            Create Customer
          </NavLink>
        </div>
        {isTableInitiallyEmpty === null ? (
          <Loader />
        ) : isTableInitiallyEmpty === true ? (
          <React.Fragment>
            <NoData
              link="/customers/create-customer"
              buttonText="Create New Customer"
              mainMessage="You do not have Customers right now!"
              secondaryMessage="Please click 'Create New Customer' to add a new Customer"
              icon={addUserIcon}
            />
          </React.Fragment>
        ) : (
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="customer-tbl-responsive">
                <TableComponentUpdated
                  columns={columns}
                  data={allCustomers}
                  setTableState={setTableState}
                  rowClickable={true}
                  onRowClick={customersTableRowEvents.onClick}
                  tableLoading={getCustomersApiRequestInProgress}
                  noDataIndicator={NoDataIndication}
                />
                <PaginationComponent
                  totalCount={totalCount}
                  recordsPerPage={recordsPerPage}
                  setTableState={setTableState}
                  tableState={tableState}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Customers;
