import React, { useEffect, useState } from 'react';
import { Breadcrumb, Alert, Card, Modal, Table } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import {
  getAllTradeAccounts,
  getAllTransactionHistory,
  getAllUnitDetails,
  getTradeAccount
} from '../../services/carbonManagementService';
import Loader from '../../components/Loader';
import Editicon from '../../assets/images/icons/edit.svg';
import { recordsPerPage, tradeAccountETSTypes, tradeAccountUsageTypes } from '../../util/constants';
import TableNoData from '../../components/TableNoData';
import { AppContext } from '../../app/App';
import { formatDate } from '../../common/formatter';
import { createColumnHelper } from '@tanstack/table-core';
import PaginationComponent from '../../components/PaginationComponent';
import TableComponentUpdated from '../../components/TableNew';

const columnHelper = createColumnHelper();

const allTransactionHistoryColumns = [
  columnHelper.accessor('transactionId', {
    cell: (info) => info.getValue(),
    header: () => <span>Transaction ID</span>,
    enableSorting: true
  }),
  columnHelper.accessor('transactionDate', {
    cell: (info) => formatDate(info.getValue()),
    header: () => <span>Transaction Date</span>,
    enableSorting: true
  }),
  columnHelper.accessor('transferedFromName', {
    cell: (info) => info.getValue(),
    header: () => <span>Transferred From</span>,
    enableSorting: false
  }),
  columnHelper.accessor('transferedToName', {
    cell: (info) => info.getValue(),
    header: () => <span>Transferred To</span>,
    enableSorting: false
  }),
  columnHelper.accessor('transactionType', {
    cell: (info) => info.getValue(),
    header: () => <span>Transaction Type</span>,
    enableSorting: false
  }),
  columnHelper.accessor('unitsReceived', {
    cell: (info) => info.getValue(),
    header: () => <span>Units Received</span>,
    enableSorting: false
  }),
  columnHelper.accessor('unitsTransferred', {
    cell: (info) => info.getValue(),
    header: () => <span>Units Transferred</span>,
    enableSorting: false
  }),
  columnHelper.accessor('status', {
    cell: (info) => info.getValue(),
    header: () => <span>Status</span>,
    enableSorting: false
  })
];
const unitColumns = [
  columnHelper.accessor('cpName', {
    cell: (info) => info.getValue(),
    header: () => <span>CP</span>,
    enableSorting: true
  }),
  columnHelper.accessor('unitType', {
    cell: (info) => info.getValue(),
    header: () => <span>Unit Type</span>,
    enableSorting: true
  }),
  columnHelper.accessor('unitBalance', {
    cell: (info) => info.getValue(),
    header: () => <span>Unit Balance</span>,
    enableSorting: false
  }),
  columnHelper.accessor('availableBalance', {
    cell: (info) => info.getValue(),
    header: () => <span>Available Balance</span>,
    enableSorting: false
  })
];

const TradeAccountDetails = (props) => {
  const context = React.useContext(AppContext);
  let navigate = useNavigate();
  const { instance } = useMsal();
  const location = useLocation();

  const tradeAccountId = location.state && location.state.tradeAccountId ? location.state.tradeAccountId : null;
  const [allTransactionHistory, setAllTransactionHistory] = useState([]);
  const [allUnitDetails, setAllUnitDetails] = useState([]);
  const [getUnitDetailsApiRequestInProgress, setGetUnitDetailApiRequestInProgress] = useState(false);
  const [getTransactionHistoryApiRequestInProgress, setGetTransactionHistoryApiRequestInProgress] = useState(true);
  const [getTradeAccountApiRequestInProgress, setGetTradeAccountApiRequestInProgress] = useState(true);
  const [tradeAccount, setTradeAccount] = useState(null);
  const [notification, setNotificationMessage] = useState(null);
  const tradeAccountName = location.state && location.state.tradeAccountName ? location.state.tradeAccountName : null;
  const [historyTableLoading, setHistoryTableLoading] = useState(false);
  const [unitDetailstableLoading, setUnitDetailstableLoading] = useState(false);
  const [totalHistoryCount, setTotalHistoryCount] = useState(0);
  const [totalUnitDetailsCount, setTotalUnitDetailsCount] = useState(0);
  const [selectedTransactionData, setSelectedtransactionData] = useState(null);

  const [allTransactionHistoryTableState, setAllTransactionHistoryTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });

  const [allUnitDetailsTableState, setAllUnitDetailsTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });

  const getTradeAccountDetails = async () => {
    setGetTradeAccountApiRequestInProgress(true);
    try {
      const response = await getTradeAccount(tradeAccountId, instance);
      if (response) {
        setTradeAccount(response);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetTradeAccountApiRequestInProgress(false);
    }
  };

  const getUnitDetails = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setUnitDetailstableLoading(true);
    const data = {
      pageNumber,
      searchKeyWord,
      pageSize: recordsPerPage,
      sortBy,
      isAscSort
    };
    try {
      const response = await getAllUnitDetails(tradeAccountId, context.msalInstance, data);
      if (response) {
        setAllUnitDetails(response.paginatedList);
        setTotalUnitDetailsCount(response.totalCount);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUnitDetailstableLoading(false);
      setGetUnitDetailApiRequestInProgress(false);
    }
  };

  const getTransactionHistory = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setHistoryTableLoading(true);
    const data = {
      pageNumber,
      searchKeyWord,
      pageSize: recordsPerPage,
      sortBy,
      isAscSort
    };
    try {
      const response = await getAllTransactionHistory(tradeAccountId, data, context.msalInstance);
      if (response) {
        const accounts = await getTradeAccounts();
        const transactionData = response.paginatedList;
        setTotalHistoryCount(response.totalCount);
        transactionData.forEach((item, index) => {
          accounts.forEach((account) => {
            if (item.transferedFrom === account.accountNumber) {
              transactionData[index] = {
                ...transactionData[index],
                transferedFromName: account.name
              };
            }
            if (item.transferedTo === account.accountNumber) {
              transactionData[index] = {
                ...transactionData[index],
                transferedToName: account.name
              };
            }
          });
        });
        setAllTransactionHistory(transactionData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setHistoryTableLoading(false);
      setGetTransactionHistoryApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          navigate(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };

  const getTradeAccounts = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    try {
      const data = {
        pageNumber,
        searchKeyWord,
        sortBy,
        isAscSort
      };
      const response = await getAllTradeAccounts(data, context.msalInstance);
      if (response && response.paginatedList) {
        return response.paginatedList;
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTradeAccountDetails();
    getTransactionHistory();
    getUnitDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTransactionHistory(
      allTransactionHistoryTableState.pageNumber,
      allTransactionHistoryTableState.searchText,
      allTransactionHistoryTableState.sortField?.toLowerCase(),
      allTransactionHistoryTableState.isAscSort
    );
    // eslint-disable-next-line
  }, [allTransactionHistoryTableState]);

  useEffect(() => {
    getUnitDetails(
      allUnitDetailsTableState.pageNumber,
      allUnitDetailsTableState.searchText,
      allUnitDetailsTableState.sortField?.toLowerCase(),
      allUnitDetailsTableState.isAscSort
    );
    // eslint-disable-next-line
  }, [allUnitDetailsTableState]);

  const handleClose = () => setSelectedtransactionData(null);
  const transactionsHistoryTableRowEvents = {
    onClick: (row) => {
      setSelectedtransactionData(row);
    }
  };

  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/carbon-management">Carbon</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Trade Account Details</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
      {getTradeAccountApiRequestInProgress ? (
        <Loader />
      ) : (
        <div>
          {tradeAccount && (
            <section>
              <Card>
                <Card.Body>
                  <div className="trade-account-details">
                    <div className="row">
                      <div className="col-6">
                        <div className="info-label">
                          <label>ETS Type</label>
                          <p>{tradeAccountETSTypes[tradeAccount.etsType] || '-'}</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="info-label">
                          <label>Trade Account Name</label>
                          <p>{tradeAccount.name || '-'}</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="info-label">
                          <label>Kyoto Account Number</label>
                          <p>{tradeAccount.kyotoAccountNumber || '-'}</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="info-label">
                          <label>Account Number</label>
                          <p>{tradeAccount.accountNumber || '-'}</p>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="info-label">
                          <label>Usage Type</label>
                          <p>{tradeAccountUsageTypes[tradeAccount.usageType] || '-'}</p>
                        </div>
                      </div>
                    </div>

                    <p>
                      <b></b>
                    </p>
                    <p>
                      <b></b>
                    </p>
                    <p>
                      <b></b>
                    </p>
                    <p>
                      <b></b>
                    </p>
                    <p>
                      <b></b>
                    </p>
                  </div>
                  <div className="trade-account-details--btns">
                    <Link
                      to="/carbon-management/update-trade-account"
                      state={{ tradeAccountId, tradeAccountName }}
                      className="btn btn-primary"
                    >
                      <img src={Editicon} alt="edit icon" /> Edit
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </section>
          )}

          <section>
            <div>
              <div className="page-header">
                <div className="breadcrumb-wrapper">
                  <Breadcrumb>
                    <Breadcrumb.Item>Unit Details</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
              {getUnitDetailsApiRequestInProgress ? (
                <Loader />
              ) : (
                <div className="customer-orderhistory-responsive">
                  <TableComponentUpdated
                    columns={unitColumns}
                    data={allUnitDetails}
                    setTableState={setAllUnitDetailsTableState}
                    rowClickable={false}
                    tableLoading={unitDetailstableLoading}
                    noDataIndicator={() => <TableNoData colspan={4} mainMessage="No data available" />}
                  />
                  <PaginationComponent
                    totalCount={totalUnitDetailsCount}
                    recordsPerPage={recordsPerPage}
                    setTableState={setAllUnitDetailsTableState}
                    tableState={allUnitDetailsTableState}
                  />
                </div>
              )}
            </div>
            <div className="transaction-total">
              {' '}
              <p>
                Total <b>{allUnitDetails && allUnitDetails.length > 0 ? allUnitDetails[0].total : 0}</b>
              </p>
            </div>
          </section>
          <section>
            <div>
              {getTransactionHistoryApiRequestInProgress ? (
                <Loader />
              ) : (
                <div className="customer-orderhistory-responsive">
                  <TableComponentUpdated
                    columns={allTransactionHistoryColumns}
                    data={allTransactionHistory}
                    setTableState={setAllTransactionHistoryTableState}
                    rowClickable={true}
                    onRowClick={transactionsHistoryTableRowEvents.onClick}
                    tableLoading={historyTableLoading}
                    noDataIndicator={() => <TableNoData colspan={8} mainMessage="No data available" />}
                  />
                  <PaginationComponent
                    totalCount={totalHistoryCount}
                    recordsPerPage={recordsPerPage}
                    setTableState={setAllTransactionHistoryTableState}
                    tableState={allTransactionHistoryTableState}
                  />
                </div>
              )}
            </div>
            <Modal size="xl" show={selectedTransactionData} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Transaction Data</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Card>
                  <Card.Body>
                    <div className="trade-account-details">
                      <div className="row">
                        <div className="col-6">
                          <div className="info-label">
                            <label>Transaction Id</label>
                            <p>{selectedTransactionData?.transactionId || '-'}</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="info-label">
                            <label>Transaction Id</label>
                            <p>{selectedTransactionData?.transactionId || '-'}</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="info-label">
                            <label>Transaction Id</label>
                            <p>{selectedTransactionData?.transactionId || '-'}</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="info-label">
                            <label>Transaction Date</label>
                            <p>{formatDate(selectedTransactionData?.transactionDate) || '-'}</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="info-label">
                            <label>Transfered From</label>
                            <p>{selectedTransactionData?.transferedFromName || '-'}</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="info-label">
                            <label>Transfered To</label>
                            <p>{selectedTransactionData?.transferedToName || '-'}</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="info-label">
                            <label>Transaction Type</label>
                            <p>{selectedTransactionData?.transactionType || '-'}</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="info-label">
                            <label>Units Received</label>
                            <p>{selectedTransactionData?.unitsReceived || '-'}</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="info-label">
                            <label>Units Transferred</label>
                            <p>{selectedTransactionData?.unitsTransferred || '-'}</p>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="info-label">
                            <label>Status</label>
                            <p>{selectedTransactionData?.status || '-'}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*Serial Numbers Table */}
                    <Table className="table" responsive>
                      <thead>
                        <tr>
                          <th>Start Serial</th>
                          <th>End Serial</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedTransactionData?.serials.map((item, index) => (
                          <tr key={index}>
                            <td>{item.startSerial}</td>
                            <td>{item.endSerial}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Modal.Body>
            </Modal>
          </section>
        </div>
      )}
    </div>
  );
};

export default TradeAccountDetails;
