import { postXeroToken, get, post, remove } from '../util/apiHelper';
import { appConfigs } from '../util/appConfigs';

export const authorizeXero = async () => {
  let consentUrl = `${appConfigs.xeroAuthEndpoint}?client_id=${appConfigs.xeroClientId}&response_type=code&scope=${appConfigs.xeroScopes}&redirect_uri=${appConfigs.xeroRedirectUri}&state=YOUR_STATE`;
  window.location.href = consentUrl;
};

export const getXeroToken = async (code) => {
  try {
    const response = await postXeroToken('https://identity.xero.com/connect/token', code);
    if (response) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const integrateXero = async (data, msalInstance) => {
  try {
    const response = await post('/Integration', data, true, msalInstance);
    if (response) {
      return response;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const getIntegrations = async (msalInstance) => {
  try {
    const response = await get('/Integration', null, true, msalInstance);
    if (response) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};

export const removeIntegration = async (id, msalInstance) => {
  try {
    const response = await remove(`/Integration/${id}`, true, msalInstance);
    if (response) {
      return response;
    }
    throw response;
  } catch (error) {
    throw error;
  }
};
