import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner, Alert } from 'react-bootstrap';
import { verifyPermissions } from '../../common/permissionVerifier';
import { units } from '../../util/constants';

const ViewOrderModal = ({
  show,
  handleClose,
  onSendForRegistration,
  title,
  product,
  orderRelease,
  customer,
  action,
  isLoading,
  readyToClaim,
  toggleReadyToClaim,
  actionButtonVariant,
  notification,
  instance
}) => {
  const [textPreview, setTextPreview] = useState('');
  const [initialTextPreview, setInitialTextPreview] = useState('');

  useEffect(() => {
    if (orderRelease && product) {
      setTextPreview(
        `Boom! ${product?.emissionPerItem}${units[product?.emissionPerItemUnit]} of CO2e locked away by choosing ${
          product.text
        }`
      );
      setInitialTextPreview(
        `Almost There! Sign in or Sign up and calim the  ${product?.emissionPerItem}${
          units[product?.emissionPerItemUnit]
        } of CO2e locked away by choosing ${product.text}`
      );
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Modal show={show} onHide={handleClose} size="lg" className="viewOrderModal">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
        <div className="row">
          <div className="col-md-4 col-lg-6">
            <div className="order-preview">
              <img src={product?.logoReferenceId} alt="" className="logo-image" />
              <div className="order-details-items">
                <div className="item">
                  <strong>Company</strong>
                  <label>{customer.name ? customer.name : '-'}</label>
                </div>
                <div className="item">
                  <strong>Brand</strong>
                  <label>{product.brand ? product.brand : '-'}</label>
                </div>
                <div className="item">
                  <strong>Product name</strong>
                  <label>{product.name ? product.name : '-'}</label>
                </div>
                <div className="item">
                  <strong>Batch</strong>
                  <label>{orderRelease.batch ? orderRelease.batch : '-'}</label>
                </div>
                <div className="item">
                  <strong>Quantity</strong>
                  <label>{orderRelease.tokenCount ? orderRelease.tokenCount : '-'}</label>
                </div>
                <div className="item">
                  <strong>Token Size</strong>
                  <label>
                    {product.emissionPerItem && product.emissionPerItemUnit
                      ? product.emissionPerItem + units[product.emissionPerItemUnit]
                      : '-'}
                  </label>
                </div>
                <div className="item">
                  <strong>Total Carbon emission units</strong>
                  <label>{orderRelease.tokenCount * product.emissionPerItem +
                  units[product.emissionPerItemUnit]}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="preview-wrapper">
              <div className="preview">
                <img src={product?.logoReferenceId} alt="" />
                <p>{initialTextPreview ? initialTextPreview : ''}</p>
              </div>
              <div className="preview preview-2">
                <img src={product?.logoReferenceId} alt="" />
                <p>{textPreview ? textPreview : ''}</p>
              </div>
            </div>
          </div>
          <div className="ready-to-claim">
            <label className="switch">
              <input type="checkbox" onChange={() => toggleReadyToClaim()} defaultChecked={readyToClaim} />
              <span className="slider round"></span>
            </label>
            <div className="text">
              <label>Tokens are ready to Claim</label>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant={actionButtonVariant}
          onClick={onSendForRegistration}
          disabled={isLoading || !verifyPermissions('approve', instance)}
        >
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              variant="light"
              style={{ marginRight: '10px' }}
            />
          )}
          {action}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewOrderModal;
