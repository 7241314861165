import React, { useMemo } from 'react';
import ReactPaginate from 'react-paginate';

const PaginationComponent = ({ totalCount, recordsPerPage, setTableState, tableState }) => {
  const totalPages = useMemo(() => {
    return Math.ceil(totalCount / recordsPerPage);
  }, [totalCount, recordsPerPage]);
  const handlePageChange = ({ selected }) => {
    setTableState((prevState) => ({
      ...prevState,
      pageNumber: selected + 1
    }));
  };

  const handleFirstPageClick = () => {
    setTableState((prevState) => ({
      ...prevState,
      pageNumber: 1
    }));
  };

  const handleLastPageClick = () => {
    setTableState((prevState) => ({
      ...prevState,
      pageNumber: totalPages
    }));
  };
  if (totalCount === 0) return null;
  if (totalCount < recordsPerPage) return null;
  return (
    <div className="row react-bootstrap-table-pagination">
      <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6"></div>
      <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
        <div className="wrap-pagination">
          <button
            disabled={tableState.pageNumber === 1}
            className={tableState.pageNumber === 1 ? 'btn-pagi btn-pagi-disabled' : 'btn-pagi'}
            onClick={handleFirstPageClick}
          >{`<<`}</button>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={'pagination'}
            activeClassName={'active'}
            forcePage={tableState.pageNumber - 1}
          />
          <button
            disabled={tableState.pageNumber === totalPages}
            className={tableState.pageNumber === totalPages ? 'btn-pagi btn-pagi-disabled' : 'btn-pagi'}
            onClick={handleLastPageClick}
          >{`>>`}</button>
        </div>
      </div>
    </div>
  );
};

export default PaginationComponent;
