import React, { useState, useEffect, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { createOrder, deleteOrderRelease, getOrder, updateOrder } from '../../services/orderService';
import { Col, Form, InputGroup, Row, Button, Alert, Spinner } from 'react-bootstrap';
import { orderFormSchema } from './formValidation';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormSkeleton } from '../../components/formSkeleton';
import { AppContext } from '../../app/App';
import { getAllCustomers } from '../../services/partyService';
import { getAllTradeAccounts } from '../../services/carbonManagementService';
import { OrderTypes, tradeAccountUsageTypes, units } from '../../util/constants';
import xIcon from '../../assets/images/icons/x.svg';
import Loader from '../../components/Loader';
import { verifyPermissions } from '../../common/permissionVerifier';
import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import { convertUnits, padLeadingZeros } from '../../common/formatter';
import Resizer from 'react-image-file-resizer';
import { useRef } from 'react';
import { ECertificateModal } from '../../components/ECertificateModal';
// const { v1: uuidv1 } = require('uuid');

const OrderForm = () => {
  const location = useLocation();
  const context = React.useContext(AppContext);
  let navigate = useNavigate();
  const { instance } = useMsal();
  const reader = new FileReader();
  const [notification, setNotificationMessage] = useState(null);
  const [getOrderApiRequestInProgress, setGetOrderApiRequestInProgress] = useState(false);
  const [allCustomers, setAllCustomers] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [allTradeAccounts, setAllTradeAccounts] = useState([]);
  const [remainingCarbon, setRemainingCarbon] = useState(0);
  const [deleteRelease, setDeleteRelease] = useState([]);
  const [imgFile, setImgFile] = useState(null);
  const [availableCarbon, setAvailableCarbon] = useState(0);
  const [total, setTotal] = useState({
    value: 0,
    unit: ''
  });
  const [releaseData, setReleaseData] = useState([]);
  const [textPreview, setTextPreview] = useState('');
  const [initialTextPreview, setInitialTextPreview] = useState('');
  const [perToken, setPerToken] = useState(0);
  const [unit, setUnit] = useState('');
  const [isCarbonAvailable, setIsCarbonAvailable] = useState(true);
  const [error, setError] = useState(false);
  const fileAddRef = useRef(null);
  const [isOrdeDeailsAproved, setIsOrdeDeailsAproved] = useState(null);

  const [order, setOrder] = useState({
    receiverId: '',
    vaultId: '',
    product: {
      name: '',
      logoReferenceId: '',
      description: '',
      emissionPerItem: 0,
      emissionPerItemUnit: ''
    },
    orderRelease: [
      {
        batch: '',
        tokenCount: null,
        isBundleToken: false,
        pendingCarbon: false
      }
    ],
    orderType: 0
  });

  const [draftOrderApiRequestInProgress, setDraftOrderApiRequestInProgress] = useState(false);
  const [saveOrderApiRequestInProgress, setSaveOrderApiRequestInProgress] = useState(false);
  const [getCustomersApiRequestInProgress, setGetCustomersApiRequestInProgress] = useState(true);
  const [getTradeAccountsApiRequestInProgress, setGetTradeAccountsApiRequestInProgress] = useState(true);
  const orderId = location.state && location.state.orderId ? location.state.orderId : null;
  const receiverName = location.state && location.state.receiverName ? location.state.receiverName : null;
  const status = location.state && location.state.status ? location.state.status : null;
  const [showCertificateTemplate, setShowCertificateTemplate] = useState(false);
  const [shouldCreateOrderBtnDisabled, setShouldCreateOrderBtnDisabled] = useState(false);
  const [canCustomerProcessECertificate, setCanCustomerProcessECertificate] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
    watch
  } = useForm({
    defaultValues: order,
    resolver: yupResolver(orderFormSchema),
    mode: 'all'
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'orderRelease'
  });
  const selectedCustomerId = watch('receiverId');
  const carbonAccountId = watch('vaultId');
  const orderType = watch('orderType');

  const selectedCustomerDetails = useMemo(
    () => allCustomers.filter((customer) => customer.id == selectedCustomerId)[0],
    [selectedCustomerId, allCustomers]
  );

  const prefillECertificateOrderFieldsWithCustomerConfigurations = async () => {
    if (selectedCustomerDetails?.eCertificateTemplateId && !orderId) {
      setValue('product.name', selectedCustomerDetails?.productName);
      setValue('product.brand', selectedCustomerDetails?.brand);
      setValue('product.text', selectedCustomerDetails?.productDisplayText);
      setValue('vaultId', selectedCustomerDetails?.defaultCarbonAccountId);

      handleFormOnChange('vaultId', selectedCustomerDetails?.defaultCarbonAccountId, '');
      let imgResponse = await fetch(selectedCustomerDetails.customerLogo);
      if (imgResponse) {
        let imgBlob = await imgResponse.blob();
        if (imgBlob) {
          fileAddRef.current = imgBlob;
          uppy.cancelAll();
          uppy.addFile({
            name: selectedCustomerDetails.customerLogo.split('/').pop(),
            type: 'image/' + selectedCustomerDetails.customerLogo.split('.').pop(),
            data: imgBlob
          });
        }
      }
    }
  };

  useEffect(() => {
    setCanCustomerProcessECertificate(null);
    if (notification) {
      setNotificationMessage(null);
    }
    if (shouldCreateOrderBtnDisabled) {
      setShouldCreateOrderBtnDisabled(false);
    }
    if (
      selectedCustomerDetails &&
      selectedCustomerDetails?.eCertificateTemplateId &&
      orderType == OrderTypes.eCertificate
    ) {
      prefillECertificateOrderFieldsWithCustomerConfigurations();
    } else if (
      selectedCustomerDetails &&
      !selectedCustomerDetails?.eCertificateTemplateId &&
      orderType == OrderTypes.eCertificate
    ) {
      setCanCustomerProcessECertificate(false);
      setShouldCreateOrderBtnDisabled(true);
    } else if (
      selectedCustomerDetails &&
      selectedCustomerDetails?.eCertificateTemplateId &&
      orderType == OrderTypes.Token
    ) {
      prefillECertificateOrderFieldsWithCustomerConfigurations();
    } else if (
      selectedCustomerDetails &&
      !selectedCustomerDetails?.eCertificateTemplateId &&
      orderType == OrderTypes.Token
    ) {
      /**
       * This is the existing scenario where we have normal customer and token orders.Nothing to be done here.
       */
    }
    // eslint-disable-next-line
  }, [selectedCustomerDetails, orderType]);

  useEffect(() => {
    if (orderType == OrderTypes.eCertificate) {
      setValue('orderRelease', [
        {
          batch: '',
          tokenCount: 1,
          isBundleToken: false,
          pendingCarbon: false
        }
      ]);
    } else if (orderType == OrderTypes.Token) {
      setValue('orderRelease', [
        {
          batch: '',
          tokenCount: null,
          isBundleToken: false,
          pendingCarbon: false
        }
      ]);
    }
  }, [orderType]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        100,
        100,
        file.type === 'image/png' ? 'PNG' : 'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file',
        100,
        100
      );
    });

  const getCustomers = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    try {
      const data = {
        pageNumber,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllCustomers(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllCustomers(response.paginatedList);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetCustomersApiRequestInProgress(false);
    }
  };

  const getTradeAccounts = async (pageNumber = 0, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    try {
      const data = {
        pageNumber,
        searchKeyWord,
        sortBy,
        isAscSort
      };
      const response = await getAllTradeAccounts(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllTradeAccounts(response.paginatedList);
        return response.paginatedList;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetTradeAccountsApiRequestInProgress(false);
    }
  };

  const createNewOrder = async (orderData) => {
    let successMsg = '';
    if (!orderId) {
      orderData = {
        ...orderData,
        orderRelease: releaseData
      };
    }
    if (orderData.status === 1) {
      setDraftOrderApiRequestInProgress(true);
      successMsg = 'Order Successfully Saved as Draft';
    } else {
      setSaveOrderApiRequestInProgress(true);
      successMsg = 'Order Successfully Created';
    }
    try {
      const resp = await createOrder(orderData, instance);
      if (resp) {
        await setNotifications('success', successMsg, '/orders');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveOrderApiRequestInProgress(false);
      setDraftOrderApiRequestInProgress(false);
    }
  };

  const updateOrderData = async (orderData) => {
    let successMsg = '';
    orderData = {
      ...orderData,
      orderRelease: releaseData
    };
    if (orderData.status === 1) {
      setDraftOrderApiRequestInProgress(true);
      successMsg = 'Order Successfully Saved as Draft';
    } else {
      setSaveOrderApiRequestInProgress(true);
      successMsg = 'Order Successfully Updated';
    }
    try {
      if (deleteRelease.length > 0) {
        deleteRelease.map((item) => {
          deleteOrderRelease(item, instance);
          return 0;
        });
      }
      const resp = await updateOrder(orderId, orderData, instance);
      if (resp) {
        await setNotifications('success', successMsg, '/orders');
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setSaveOrderApiRequestInProgress(false);
      setDraftOrderApiRequestInProgress(false);
    }
  };

  const setNotifications = async (type, message, redirectUrl) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
        if (redirectUrl) {
          navigate(redirectUrl);
        }
      },
      type === 'success' ? 2000 : 5000
    );
  };

  const setFile = async (files) => {
    if (files) {
      setFileData({ file: files });
      if (fileAddRef.current !== files) {
        const image = await resizeFile(files);
        reader.readAsDataURL(image);
      } else {
        reader.readAsDataURL(files);
      }
      reader.onloadend = () => {
        const imageBase64 = reader.result.replace('application/octet-stream', 'image/jpeg');
        setImgFile(imageBase64);
        setError(false);
      };
    }
  };

  const sendOrderRequest = async (data) => {
    orderFormSchema
      .validate(data)
      .then(function (value) {
        if (data.status === 1) {
          setDraftOrderApiRequestInProgress(true);
        } else {
          setSaveOrderApiRequestInProgress(true);
        }
        if (orderId && receiverName !== 'Re-Order') {
          data.orderRelease = data.orderRelease.map((item) =>
            item.orderReleaseId ? { ...item, id: item.orderReleaseId || '' } : item
          );
          delete data.receiver;
          updateOrderData(data);
        } else {
          createNewOrder(data);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const onSubmitHandler = async (data) => {
    if (fileData.length > 0 || imgFile) {
      const newData = {
        ...data,
        status: data.status ? data.status : 2,
        product: {
          ...data.product,
          description: 'Test Description',
          productImage: imgFile
          // logoReferenceId: fileData.file ? fileData.location : order.product.logoReferenceId
        }
      };
      sendOrderRequest(newData);
    } else {
      window.scrollTo(0, 0);
      setError(true);
    }
  };

  const onReOrderSubmitHandler = async (data) => {
    if (fileData) {
      console.log('asda', releaseData);
      let orderRelease = [];
      releaseData.forEach((item, index) => {
        orderRelease[index] = {
          batch: item.batch,
          tokenCount: item.tokenCount,
          pendingCarbon: item.pendingCarbon,
          isBundleToken: item.isBundleToken
        };
      });

      const newData = {
        receiverId: data.receiverId,
        reference: data.reference,
        status: 2,
        vaultId: data.vaultId,
        product: {
          brand: data.product.brand,
          emissionPerItem: data.product.emissionPerItem,
          emissionPerItemUnit: data.product.emissionPerItemUnit,
          productImage: imgFile,
          name: data.product.name,
          text: data.product.text,
          description: 'Test Description'
        },
        orderRelease: orderRelease,
        orderType: data.orderType
      };
      sendOrderRequest(newData);
    } else {
      window.scrollTo(0, 0);
      setError(true);
    }
  };

  const onDraftSubmitHandler = async (data) => {
    const newData = {
      ...data,
      status: 1,
      product: {
        ...data.product,
        description: 'Test Description',
        // logoReferenceId: fileData.file ? fileData.location : order.product.logoReferenceId
        productImage: imgFile
      }
    };
    sendOrderRequest(newData);
  };

  const resetForm = () => {
    setTotal({ value: 0, unit: '' });
    setTextPreview('');
    setInitialTextPreview('');
    setReleaseData([]);
    remove();
    reset();
    uppy.cancelAll();
    setAvailableCarbon(0);
  };

  //Check whether the total remaining carbon amount is less than available carbon
  const checkCarbonAvailable = (totalCarbon, pendingCarbon, availableCarbon, unit) => {
    let remaining = totalCarbon - pendingCarbon;
    const remainingCarbonCal = remaining !== 0 ? convertUnits(remaining, unit) : 0;
    setIsCarbonAvailable(
      (remainingCarbonCal > 0 ? remainingCarbonCal : 0) <=
        (availableCarbon !== null && availableCarbon !== 0 ? availableCarbon : 0)
    );
    return remainingCarbonCal;
  };

  const removeRelease = (index) => {
    const id = releaseData[index]?.id ? releaseData[index].id : '';
    deleteRelease && id !== '' ? setDeleteRelease([...deleteRelease, id]) : setDeleteRelease(id);
    let totalCal = total.value - (releaseData[index]?.totalPerRelease ? releaseData[index].totalPerRelease : 0);
    setTotal({ value: totalCal, unit: total.unit });
    releaseData.splice(index, 1);
    remove(index);
    let pendingCarbonVal = 0;
    releaseData.forEach((item) => {
      if (item.pendingCarbon) {
        pendingCarbonVal = pendingCarbonVal + item.totalPerRelease;
      }
    });
    let remainingCarbonCal = checkCarbonAvailable(totalCal, pendingCarbonVal, availableCarbon, unit);
    setRemainingCarbon(
      remainingCarbonCal && remainingCarbonCal !== 0
        ? availableCarbon !== 0
          ? (availableCarbon - remainingCarbonCal).toFixed(3)
          : remainingCarbonCal
        : availableCarbon
    );
  };

  const setReleaseDetails = async (index, value, field) => {
    let totalCal = 0;
    let pendingCarbonVal = 0;
    if (index === -1 && releaseData.length > 0) {
      releaseData.forEach((item) => {
        item.totalPerRelease = item.tokenCount * perToken;
        item.unit = unit ? unit : '';
      });
    } else {
      const tokenCount = field === 'tokenCount' ? value : getValues('orderRelease.' + index + '.tokenCount');
      const batch = field === 'batch' ? value : getValues('orderRelease.' + index + '.batch');
      const isBundleToken =
        field === 'isBundleToken'
          ? value
          : orderId
          ? releaseData[index]
            ? releaseData[index].isBundleToken
            : getValues('orderRelease.' + index + '.isBundleToken')
          : false;
      const pendingCarbon =
        field === 'pendingCarbon'
          ? value
          : orderId
          ? releaseData[index]
            ? releaseData[index].pendingCarbon
            : getValues('orderRelease.' + index + '.pendingCarbon')
          : false;
      const totalPerRelease = perToken * tokenCount;

      releaseData[index] = {
        ...releaseData[index],
        isBundleToken: isBundleToken,
        batch: batch,
        tokenCount: tokenCount,
        totalPerRelease: totalPerRelease,
        unit: unit ? unit : '',
        pendingCarbon: pendingCarbon
      };
    }
    setReleaseData([...releaseData]);

    releaseData.forEach((item) => {
      totalCal = totalCal + item.totalPerRelease;
    });

    releaseData.forEach((item) => {
      if (item.pendingCarbon) {
        pendingCarbonVal = pendingCarbonVal + item.totalPerRelease;
      }
    });
    let remainingCarbonCal = checkCarbonAvailable(totalCal, pendingCarbonVal, availableCarbon, unit);
    setRemainingCarbon(
      remainingCarbonCal && remainingCarbonCal !== 0
        ? (availableCarbon - remainingCarbonCal).toFixed(3)
        : availableCarbon
    );
    setTotal({ value: totalCal, unit: unit ? unit : '' });
  };

  const handleFormOnChange = (field, value, nestedField) => {
    if (field === 'receiverId') {
      setValue('receiverId', value);
    }
    let values = getValues();
    let totalCal = 0;
    let pendingCarbonVal = 0;
    let avlCarbon = 0;

    if (nestedField) {
      let nestedValues = { ...values[nestedField] };
      nestedValues = { ...nestedValues, [field]: value };
      values = { ...values, [nestedField]: nestedValues };
    } else {
      values = { ...values, [field]: value };
    }
    if (
      values.product?.name &&
      values.product?.emissionPerItem &&
      values.product?.emissionPerItemUnit &&
      values.product?.text &&
      values.receiverId
    ) {
      setTextPreview(
        `Boom! ${values.product?.emissionPerItem}${
          units[values.product?.emissionPerItemUnit]
        } of CO2e locked away by choosing ${values.product.text ? values.product.text : ''}`
      );

      setInitialTextPreview(
        `Almost There! Sign in or sign up and claim the ${values.product?.emissionPerItem}${
          units[values.product?.emissionPerItemUnit]
        } of CO2e you've locked away by choosing ${values.product.text ? values.product.text : ''}`
      );
    }

    //Check whether the Quantity per Token an Unit has values and calculate the order total carbon amount is less than available carbon
    if (values.product?.emissionPerItem && values.product?.emissionPerItemUnit) {
      setPerToken(values.product.emissionPerItem);
      setUnit(units[values.product.emissionPerItemUnit]);
      releaseData.forEach((item) => {
        item.totalPerRelease = item.tokenCount * values.product.emissionPerItem;
        item.unit = units[values.product.emissionPerItemUnit];
        totalCal = totalCal + item.totalPerRelease;
      });
      setTotal({ value: totalCal, unit: units[values.product.emissionPerItemUnit] });
    }

    releaseData.forEach((item) => {
      if (item.pendingCarbon) {
        pendingCarbonVal = pendingCarbonVal + item.totalPerRelease;
      }
    });

    //This check only invoke when the order level attributes emissionPerItem, emissionPerItemUnit, vaultId changed
    if (field === 'emissionPerItem' || field === 'emissionPerItemUnit' || field === 'vaultId') {
      //If the carbon account is change in the order filter the carbon acount and get the available carbon amount
      if (field === 'vaultId') {
        avlCarbon = Number(
          allTradeAccounts.filter((item) => item.id === value)[0].totalCarbonUnits
            ? allTradeAccounts.filter((item) => item.id === value)[0].totalCarbonUnits.split(' ')[0]
            : '0'
        );

        setAvailableCarbon(avlCarbon);
        setRemainingCarbon(avlCarbon);
      } else {
        avlCarbon = availableCarbon;
      }

      let remainingCarbonCal = checkCarbonAvailable(
        totalCal,
        pendingCarbonVal,
        field === 'vaultId' ? avlCarbon : availableCarbon,
        field === 'emissionPerItemUnit' ? units[value] : unit
      );
      setRemainingCarbon(
        remainingCarbonCal && remainingCarbonCal !== 0 ? (avlCarbon - remainingCarbonCal).toFixed(3) : avlCarbon
      );
    }
  };
  const handleClickViewECertificate = (e) => {
    e.preventDefault();
    setShowCertificateTemplate(true);
  };

  const getOrderDetails = async (orderId, accounts) => {
    setGetOrderApiRequestInProgress(true);
    let totalCal = 0;
    let pendingCarbonVal = 0;
    try {
      const response = await getOrder(orderId, instance);
      if (response) {
        setOrder(response);
        setPerToken(response.product.emissionPerItem);
        setUnit(units[response.product.emissionPerItemUnit]);
        response.orderRelease.forEach((item, index) => {
          releaseData[index] = {
            ...item,
            id: item.id,
            batch: item.batch,
            tokenCount: item.tokenCount,
            totalPerRelease: response.product.emissionPerItem * item.tokenCount,
            unit: units[response.product.emissionPerItemUnit],
            pendingCarbon: item.pendingCarbon,
            isBundleToken: item.isBundleToken
          };
          if (item.approvalStatus >= 2 && receiverName !== 'Re-Order') {
            setIsOrdeDeailsAproved(true);
          }
        });

        let imgResponse = await fetch(response.product.logoReferenceId);
        if (imgResponse) {
          let imgBlob = await imgResponse.blob();
          if (imgBlob) {
            fileAddRef.current = imgBlob;
            uppy.addFile({
              name: response.product.logoReferenceId.split('/').pop(),
              type: 'image/' + response.product.logoReferenceId.split('.').pop(),
              data: imgBlob
            });
          }
        }

        releaseData.forEach((item) => {
          totalCal = totalCal + item.totalPerRelease;
        });
        setTotal({
          value: totalCal,
          unit: units[response.product.emissionPerItemUnit] ? units[response.product.emissionPerItemUnit] : ''
        });

        response.orderRelease = response.orderRelease.map((item) => ({ ...item, orderReleaseId: item.id }));

        accounts.then((accountList) => {
          const avlCarbon = Number(
            accountList.filter((item) => item.id === response.vaultId)[0]?.totalCarbonUnits.split(' ')[0]
          );
          releaseData.forEach((item) => {
            if (item.pendingCarbon) {
              pendingCarbonVal = pendingCarbonVal + item.totalPerRelease;
            }
          });

          let remainingCarbonCal = checkCarbonAvailable(
            totalCal,
            pendingCarbonVal,
            avlCarbon,
            units[response.product.emissionPerItemUnit] ? units[response.product.emissionPerItemUnit] : ''
          );
          setRemainingCarbon(avlCarbon !== 0 ? (avlCarbon - remainingCarbonCal).toFixed(3) : remainingCarbonCal);
        });

        append(response.orderRelease);
        reset(response);
      }
    } catch (error) {
      console.log(error);
      setNotifications('danger', error.title);
    } finally {
      setGetOrderApiRequestInProgress(false);
    }
  };

  const [uppy] = useState(
    () =>
      new Uppy({
        restrictions: {
          maxFileSize: 2000000,
          maxNumberOfFiles: 1,
          minNumberOfFiles: 1,
          allowedFileTypes: ['image/*']
        },
        plugins: ['Url']
      })
  );

  useEffect(() => {
    uppy.on('file-added', (result) => {
      setFile(result.data);
    });

    uppy.on('file-removed', (file, reason) => {
      setFileData([]);
      setImgFile(null);
    });
    // eslint-disable-next-line
  }, [uppy]);

  useEffect(() => {
    const accounts = getTradeAccounts();
    getCustomers();
    if (orderId) {
      getOrderDetails(orderId, accounts);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (orderId && order && allCustomers) {
      setTextPreview(
        `Boom! ${order.product?.emissionPerItem}${
          units[order.product?.emissionPerItemUnit]
        } of CO2e locked away by choosing ${order.product.text ? order.product.text : ''}`
      );
      setInitialTextPreview(
        `Almost There! Sign in or sign up and claim the ${order.product?.emissionPerItem}${
          units[order.product?.emissionPerItemUnit]
        } of CO2e you've locked away by choosing ${order.product.text ? order.product.text : ''}`
      );
    }

    const avlCarbon = allTradeAccounts.filter((item) => item.id === order.vaultId)[0]?.totalCarbonUnits.split(' ')[0];
    setAvailableCarbon(avlCarbon);
    setRemainingCarbon(avlCarbon);
    //eslint-disable-next-line
  }, [allTradeAccounts, allCustomers, order]);

  return (
    <React.Fragment>
      <ECertificateModal
        show={showCertificateTemplate}
        handleOnCancel={() => setShowCertificateTemplate(!showCertificateTemplate)}
        eCertificateTemplate={selectedCustomerDetails?.eCertificateTemplateId || '00000000-0000-0000-0000-000000000000'}
        defaultCarbonAccountId={carbonAccountId}
        recipientName={null}
        orderReleaseId={orderId || null}
      />
      {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
      {canCustomerProcessECertificate === false && (
        <Alert variant={'danger'}>
          Selected Company/Individual doesn’t contain all the required configurations for the Carbon Cancellation
          Certificate orders. Please navigate to{' '}
          <NavLink to="/customers" style={{ color: 'black' }}>
            Customers
          </NavLink>{' '}
          to update the required configurations.
        </Alert>
      )}
      <div className="order-form-wrapper">
        <Form className="order-form" noValidate autoComplete="none">
          <fieldset disabled={isOrdeDeailsAproved}>
            <Row>
              <Col sm={6}>
                <Form.Label>Order Type *</Form.Label>
                <Form.Control
                  as="select"
                  type="select"
                  {...register('orderType')}
                  disabled={!verifyPermissions('write', instance)}
                  isInvalid={errors.orderType}
                >
                  <option value={0} disabled>
                    Please select Order Type
                  </option>
                  {[
                    {
                      value: OrderTypes.Token,
                      label: 'Token'
                    },
                    {
                      value: OrderTypes.eCertificate,
                      label: 'Carbon Cancellation Certificate'
                    }
                  ].map((orderType, index) => (
                    <option key={index} value={orderType.value}>
                      {orderType.label}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors?.orderType?.message}</Form.Control.Feedback>
              </Col>
              {getCustomersApiRequestInProgress ||
              getOrderApiRequestInProgress ||
              getTradeAccountsApiRequestInProgress ? (
                <FormSkeleton size="6" />
              ) : (
                <Col sm={6}>
                  <Form.Label>Company / Individual Name *</Form.Label>
                  <Form.Control
                    as="select"
                    type="select"
                    {...register('receiverId')}
                    isInvalid={errors?.receiverId}
                    disabled={!verifyPermissions('write', instance)}
                    onChange={(e) => e.target.value && handleFormOnChange('receiverId', e.target.value)}
                  >
                    <option value="" disabled>
                      Please select Company / Individual Name
                    </option>
                    {allCustomers.map(
                      (customer, index) =>
                        customer.onboardStatus === 4 && (
                          <option key={index} value={customer.id}>
                            {customer.name}
                          </option>
                        )
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors?.receiverId?.message}</Form.Control.Feedback>
                </Col>
              )}
              {getCustomersApiRequestInProgress || getOrderApiRequestInProgress ? (
                <FormSkeleton size="6" />
              ) : (
                <Col sm={6}>
                  <Form.Label>Product Name *</Form.Label>
                  <Form.Control
                    placeholder="Please enter Product Name"
                    {...register('product.name')}
                    isInvalid={errors.product?.name}
                    autoComplete="none"
                    maxLength={100}
                    disabled={!verifyPermissions('write', instance)}
                    onChange={(e) => e.target.value && handleFormOnChange('name', e.target.value, 'product')}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.product?.name?.message}</Form.Control.Feedback>

                  {getCustomersApiRequestInProgress || getOrderApiRequestInProgress ? (
                    <FormSkeleton size="6" />
                  ) : (
                    <Col sm={6}>
                      <Form.Label>Brand</Form.Label>
                      <Form.Control
                        placeholder="Please enter Brand"
                        {...register('product.brand')}
                        isInvalid={errors.product?.brand}
                        autoComplete="none"
                        maxLength={100}
                        disabled={!verifyPermissions('write', instance)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.product?.brand?.message}</Form.Control.Feedback>
                    </Col>
                  )}
                </Col>
              )}
              {getCustomersApiRequestInProgress || getOrderApiRequestInProgress ? (
                <FormSkeleton size="6" />
              ) : (
                <Col sm={12} md={6}>
                  <Form.Label>Customer Logo</Form.Label>
                  <Dashboard
                    uppy={uppy}
                    note="Preferred image types: image/svg, image/png, image/jpeg, Size 2MB or less"
                    width={600}
                    height={200}
                    showLinkToFileUploadResult={true}
                    proudlyDisplayPoweredByUppy={false}
                    showProgressDetails={true}
                    hideUploadButton={true}
                  />
                  {error && <div className="invalid-logo">Customer Logo is a required field</div>}
                </Col>
              )}
              {getCustomersApiRequestInProgress || getOrderApiRequestInProgress ? (
                <FormSkeleton size="6" />
              ) : (
                <Col sm={6}>
                  <Form.Label>Carbon Quantity per Token *</Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Please enter Carbon Quantity per Token"
                      {...register('product.emissionPerItem')}
                      isInvalid={errors.product?.emissionPerItem}
                      onChange={(e) => {
                        e.target.value && handleFormOnChange('emissionPerItem', e.target.value, 'product');
                      }}
                      autoComplete="none"
                      maxLength={100}
                      disabled={!verifyPermissions('write', instance)}
                      type="number"
                    ></Form.Control>
                    <Form.Control
                      as="select"
                      type="select"
                      {...register('product.emissionPerItemUnit')}
                      disabled={!verifyPermissions('write', instance)}
                      isInvalid={errors.product?.emissionPerItemUnit}
                      onChange={(e) => {
                        e.target.value && handleFormOnChange('emissionPerItemUnit', e.target.value, 'product');
                      }}
                    >
                      <option value="" disabled>
                        Unit
                      </option>
                      {Object.keys(units).map(
                        (unit, index) =>
                          index !== 0 && (
                            <option key={index} value={unit}>
                              {units[unit]}
                            </option>
                          )
                      )}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.product?.emissionPerItem?.message}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.product?.emissionPerItemUnit?.message}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Col>
              )}
              {getCustomersApiRequestInProgress ||
              getTradeAccountsApiRequestInProgress ||
              getOrderApiRequestInProgress ? (
                <FormSkeleton size="6" />
              ) : (
                <Col sm={6}>
                  <Form.Label>Carbon Account *</Form.Label>
                  <Form.Control
                    as="select"
                    type="select"
                    {...register('vaultId')}
                    disabled={!verifyPermissions('write', instance)}
                    isInvalid={errors.vaultId}
                    onChange={(e) => e.target.value && handleFormOnChange('vaultId', e.target.value, '')}
                  >
                    <option value="" disabled>
                      Please select Carbon Account
                    </option>
                    {allTradeAccounts.map(
                      (account, index) =>
                        tradeAccountUsageTypes[account.usageType] === tradeAccountUsageTypes[2] && (
                          <option key={index} value={account.id}>
                            {account.name}
                          </option>
                        )
                    )}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.vaultId?.message}</Form.Control.Feedback>
                  <label className="available-carbon-text">
                    Available Carbon :{' '}
                    <strong>
                      {availableCarbon
                        ? Number(padLeadingZeros(availableCarbon)) !== 0
                          ? padLeadingZeros(availableCarbon)
                          : 0
                        : 0}{' '}
                      Tonnes
                    </strong>
                  </label>
                  <label className="available-carbon-text">
                    Remaining Carbon :{' '}
                    <strong>{remainingCarbon ? (remainingCarbon !== 0 ? remainingCarbon : 0) : 0} Tonnes</strong>
                  </label>
                </Col>
              )}
              {getCustomersApiRequestInProgress || getOrderApiRequestInProgress ? (
                <FormSkeleton size="6" />
              ) : (
                <Col sm={6}>
                  <Form.Label>Product Text *</Form.Label>
                  <Form.Control
                    placeholder="Please enter Product Text"
                    {...register('product.text')}
                    isInvalid={errors.product?.text}
                    autoComplete="none"
                    disabled={!verifyPermissions('write', instance)}
                    maxLength={100}
                    onChange={(e) => e.target.value && handleFormOnChange('text', e.target.value, 'product')}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.product?.text?.message}</Form.Control.Feedback>
                </Col>
              )}
              {getCustomersApiRequestInProgress || getOrderApiRequestInProgress ? (
                <FormSkeleton size="6" />
              ) : (
                <Col sm={6}>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="4"
                    {...register('reference')}
                    isInvalid={errors.reference}
                    maxLength={250}
                    autoComplete="none"
                    disabled={!verifyPermissions('write', instance)}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">{errors.reference?.message}</Form.Control.Feedback>
                </Col>
              )}
            </Row>
          </fieldset>
          {/* token release edit  */}

          <h3 className="token-release-title">Token Release</h3>
          {!isCarbonAvailable ? (
            <p className="error-msg">
              Order exceeds the available carbon amount. Please select a different carbon account or select "Proceed
              with pending carbon"
            </p>
          ) : (
            ''
          )}
          {fields.map((item, index) => {
            return (
              <fieldset disabled={item.approvalStatus >= 2 && receiverName !== 'Re-Order'}>
                <div className="release-section">
                  <Row key={item.id}>
                    {verifyPermissions('write', instance) &&
                    index > 0 &&
                    (!orderId || !item.approvalStatus || item.approvalStatus < 2 || receiverName === 'Re-Order') ? (
                      <span className="close-btn" type="button" onClick={() => removeRelease(index)}>
                        <img src={xIcon} alt="close icon" />
                      </span>
                    ) : (
                      ''
                    )}
                    {getCustomersApiRequestInProgress || getOrderApiRequestInProgress ? (
                      <FormSkeleton size="6" />
                    ) : (
                      <Col sm={6}>
                        <Form.Label>Batch *</Form.Label>
                        <Form.Control
                          placeholder="Please enter Batch"
                          {...register(`orderRelease.${index}.batch`)}
                          isInvalid={errors.orderRelease?.[index]?.batch}
                          autoComplete="none"
                          maxLength={100}
                          onBlur={(e) => setReleaseDetails(index, e.target.value, 'batch')}
                          disabled={!verifyPermissions('write', instance)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.orderRelease?.[index]?.batch?.message}
                        </Form.Control.Feedback>
                      </Col>
                    )}
                    {getCustomersApiRequestInProgress || getOrderApiRequestInProgress ? (
                      <FormSkeleton size="6" />
                    ) : (
                      <Col sm={6}>
                        <Form.Label>Token Count *</Form.Label>
                        <Form.Control
                          placeholder="Please enter Token Count"
                          {...register(`orderRelease.${index}.tokenCount`)}
                          isInvalid={errors.orderRelease?.[index]?.tokenCount}
                          autoComplete="none"
                          maxLength={100}
                          onBlur={(e) => setReleaseDetails(index, e.target.value, 'tokenCount')}
                          disabled={!verifyPermissions('write', instance) || orderType == OrderTypes.eCertificate}
                          type="number"
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.orderRelease?.[index]?.tokenCount?.message}
                        </Form.Control.Feedback>
                      </Col>
                    )}
                    <Col sm={12} className="checkbox-div">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="Bundle Token"
                          disabled={!verifyPermissions('write', instance)}
                          {...register(`orderRelease.${index}.isBundleToken`)}
                          onChange={(e) => {
                            setReleaseDetails(index, e.target.checked, 'isBundleToken');
                          }}
                        />
                        <span> </span>
                        <Form.Check
                          type="checkbox"
                          label="Proceed with pending carbon"
                          disabled={!verifyPermissions('write', instance)}
                          {...register(`orderRelease.${index}.pendingCarbon`)}
                          onChange={(e) => {
                            setReleaseDetails(index, e.target.checked, 'pendingCarbon');
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </fieldset>
            );
          })}
          {status !== 4 && (
            <div className="add-release">
              <Button
                variant="secondary"
                disabled={!releaseData[0]}
                onClick={() => {
                  if (orderType == OrderTypes.eCertificate) {
                    append({ batch: '', tokenCount: 1, isBundleToken: false, pendingCarbon: false });
                  } else {
                    append({ batch: '', tokenCount: null, isBundleToken: false, pendingCarbon: false });
                  }
                }}
              >
                {' '}
                Add Another Release
              </Button>
            </div>
          )}
          <div className="carbon-details">
            <label className="carbon-details-title">Carbon Details</label>
            <table>
              {releaseData.map((o) => (
                <tr key={o.id}>
                  <td>{o.batch ? o.batch : ''}</td>
                  <td>{o.totalPerRelease ? o.totalPerRelease + ' ' + o.unit : 0}</td>
                </tr>
              ))}
              <tr>
                <td>
                  <strong>Total Carbon</strong>
                </td>
                <td>
                  <strong>{total.value + ' ' + total.unit}</strong>
                </td>
              </tr>
            </table>
          </div>
          <div className="form-buttons">
            <NavLink to="/Orders" className="btn btn-light">
              Cancel
            </NavLink>
            {status !== 4 && (
              <div className="right">
                {orderId ? (
                  ''
                ) : (
                  <Button variant="btn btn-light" onClick={resetForm} disabled={!verifyPermissions('write', instance)}>
                    Clear
                  </Button>
                )}
                {receiverName !== 'Re-Order' && (
                  <Button
                    type="button"
                    variant="outline-secondary"
                    onClick={handleSubmit(onDraftSubmitHandler)}
                    disabled={
                      !verifyPermissions('write', instance) ||
                      getOrderApiRequestInProgress ||
                      saveOrderApiRequestInProgress ||
                      draftOrderApiRequestInProgress
                    }
                  >
                    {draftOrderApiRequestInProgress && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        variant="light"
                        style={{ marginRight: '10px' }}
                      />
                    )}
                    Save Draft
                  </Button>
                )}
                <Button
                  type="button"
                  className="btn btn-primary m-2"
                  onClick={
                    receiverName === 'Re-Order' ? handleSubmit(onReOrderSubmitHandler) : handleSubmit(onSubmitHandler)
                  }
                  disabled={
                    !verifyPermissions('write', instance) ||
                    !isCarbonAvailable ||
                    getOrderApiRequestInProgress ||
                    saveOrderApiRequestInProgress ||
                    draftOrderApiRequestInProgress ||
                    shouldCreateOrderBtnDisabled
                  }
                >
                  {saveOrderApiRequestInProgress && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      variant="light"
                      style={{ marginRight: '10px' }}
                    />
                  )}
                  {orderId ? (receiverName === 'Re-Order' ? 'Re-Order' : 'Edit Order') : 'Create Order'}
                </Button>
              </div>
            )}
          </div>
        </Form>
        <div>
          <div className="preview-wrapper">
            <div style={{ display: 'flex' }}>
              {orderId &&
              (getCustomersApiRequestInProgress ||
                getOrderApiRequestInProgress ||
                getTradeAccountsApiRequestInProgress) ? (
                <Loader />
              ) : textPreview ? (
                <div className="preview">
                  <div className="preview-img">
                    {fileData.file ? (
                      <img src={URL.createObjectURL(fileData.file)} alt="" />
                    ) : (
                      <img src={order?.product?.logoReferenceId} alt="" />
                    )}
                  </div>
                  <p>{initialTextPreview ? initialTextPreview : ''}</p>
                </div>
              ) : (
                ''
              )}
              {orderId &&
              (getCustomersApiRequestInProgress ||
                getOrderApiRequestInProgress ||
                getTradeAccountsApiRequestInProgress) ? (
                <Loader />
              ) : textPreview ? (
                <div className="preview preview-2">
                  <div className="preview-img">
                    {fileData.file ? (
                      <img src={URL.createObjectURL(fileData.file)} alt="" />
                    ) : (
                      <img src={order?.product?.logoReferenceId} alt="" />
                    )}
                  </div>
                  <p>{textPreview ? textPreview : ''}</p>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div>
            {orderId &&
            (getCustomersApiRequestInProgress ||
              getOrderApiRequestInProgress ||
              getTradeAccountsApiRequestInProgress) ? (
              <Loader />
            ) : textPreview ? (
              <>
                {orderType == OrderTypes.eCertificate && selectedCustomerId && carbonAccountId && (
                  <Button variant="link" className="view-certificate" onClick={handleClickViewECertificate}>
                    View Carbon Cancellation Certificate Template
                  </Button>
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderForm;
