import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

const ConfirmAction = ({
  show,
  handleClose,
  onActionConfirm,
  title,
  content,
  action,
  isLoading,
  actionButtonVariant,
  inprogress
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant={actionButtonVariant} onClick={onActionConfirm} disabled={isLoading}>
          {isLoading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              variant="light"
              style={{ marginRight: '10px' }}
            />
          )}
          {inprogress && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              variant="light"
              style={{ marginRight: '10px' }}
            />
          )}
          {action}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmAction;
