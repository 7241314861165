import React from 'react';
import nodataImg from '../assets/images/no-data.svg';
import { NavLink } from 'react-router-dom';

const NoData = (props) => {
  return (
    <div className="no-data">
      <img alt="No data currently" src={nodataImg} className="main-image" />
      <h1>{props.mainMessage}</h1>
      <h2>{props.secondaryMessage}</h2>
      {props.link && (
        <NavLink to={props.link} className="btn btn-primary">
          <img src={props.icon} alt="button icon" />
          {props.buttonText}
        </NavLink>
      )}
    </div>
  );
};

export default NoData;
