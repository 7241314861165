import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../util/authConfig';
import App from '../app/App';

export const AuthInteceptor = (props) => {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={App} 
    >
      {props.children}
    </MsalAuthenticationTemplate>
  );
};
