import React from 'react';
import TableComponentUpdated from './TableNew';
import { createColumnHelper } from '@tanstack/react-table';
import { Alert, Breadcrumb, NavLink } from 'react-bootstrap';
import { SearchBarUpdated } from './SearchBar';
import { recordsPerPage } from '../util/constants';
import { useState } from 'react';
import { useEffect } from 'react';
import { getCertificateTemplates } from '../services/partyService';
import PaginationComponent from './PaginationComponent';
import Loader from './Loader';
import NoData from './NoData';
import TableNoData from './TableNoData';
import { AppContext } from '../app/App';
import { formatDate } from '../common/formatter';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    cell: (info) => info.getValue(),
    header: () => <span>Template</span>,
    enableSorting: false
  }),
  columnHelper.accessor('createdOn', {
    id: 'createdOn',
    cell: (info) => <span>{formatDate(info.getValue())}</span>,
    header: () => <span>Date Uploaded</span>,
    enableSorting: false
  })
];

export const Templates = () => {
  const context = React.useContext(AppContext);
  const [allTemplates, setAllTemplates] = useState([]);
  const [getTemplatesApiRequestInProgress, setGetTemplatesApiRequestInProgress] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(null);
  const [notification, setNotificationMessage] = useState(null);

  const [tableState, setTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });

  const handleTemplatesSearchChange = (value) => {
    setTableState((prevState) => ({
      ...prevState,
      searchText: value,
      isAscSort: null,
      pageNumber: 1
    }));
  };
  const getTemplates = async (pageNumber = 1, searchKeyWord = null) => {
    setGetTemplatesApiRequestInProgress(true);

    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord
      };

      const response = await getCertificateTemplates(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllTemplates(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      setNotifications('danger', error?.title);
      console.log(error);
    } finally {
      setGetTemplatesApiRequestInProgress(false);
    }
  };
  const setNotifications = async (type, message) => {
    setNotificationMessage({ type: type, message: message });
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setNotificationMessage(null);
      },
      type === 'success' ? 2000 : 5000
    );
  };
  useEffect(() => {
    getTemplates(tableState.pageNumber);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTemplates(tableState.pageNumber, tableState.searchText);
    // eslint-disable-next-line
  }, [tableState]);
  return (
    <section>
      <div>
        <div className="page-header">
          <div className="breadcrumb-wrapper">
            <Breadcrumb>
              <Breadcrumb.Item active>Templates</Breadcrumb.Item>
            </Breadcrumb>
            {/* todo: remove previous search bar and do the relevant renames  */}
            <SearchBarUpdated searchHandler={handleTemplatesSearchChange} className="table-search" />
          </div>
          <NavLink to="/" className="btn btn-primary" disabled>
            Add New Template
          </NavLink>
        </div>
        {notification && notification.message && <Alert variant={notification.type}>{notification.message}</Alert>}
        {isTableInitiallyEmpty === null ? (
          <Loader />
        ) : isTableInitiallyEmpty === true ? (
          <React.Fragment>
            <NoData
              mainMessage="You do not have templates right now!"
              secondaryMessage="Please click 'Add new template' to add a new template"
            />
          </React.Fragment>
        ) : (
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="customer-tbl-responsive">
                <TableComponentUpdated
                  columns={columns}
                  data={allTemplates}
                  setTableState={setTableState}
                  rowClickable={false}
                  tableLoading={getTemplatesApiRequestInProgress}
                  noDataIndicator={() => <TableNoData colspan="6" mainMessage="No data available" />}
                />
                <PaginationComponent
                  totalCount={totalCount}
                  recordsPerPage={recordsPerPage}
                  setTableState={setTableState}
                  tableState={tableState}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
