import React, { useEffect, useState } from 'react';
import { getAllUsers } from '../../services/userService';
import { useNavigate } from 'react-router';
import { Breadcrumb } from 'react-bootstrap';
import { AppContext } from '../../app/App';
import NoData from '../../components/NoData';
import addUserIcon from '../../assets/images/icons/user-plus.svg';
import Loader from '../../components/Loader';
import { NavLink } from 'react-router-dom';
import { recordsPerPage } from '../../util/constants';
import TableNoData from '../../components/TableNoData';
import { formatRoles } from '../../common/formatter';
import { SearchBarUpdated } from '../../components/SearchBar';
import { createColumnHelper } from '@tanstack/table-core';
import TableComponentUpdated from '../../components/TableNew';
import PaginationComponent from '../../components/PaginationComponent';

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor((user) => `${user.firstName || ''} ${user.lastName || ''}`, {
    id: 'firstName',
    cell: (info) => info.getValue(),
    header: () => <span>Name</span>,
    enableSorting: true
  }),
  columnHelper.accessor('roles', {
    id: 'roles',
    cell: (info) => <span>{formatRoles(info.getValue()) || '-'}</span>,
    header: () => <span>USER TYPE</span>,
    enableSorting: true
  })
];

const Users = () => {
  const context = React.useContext(AppContext);
  const [getUsersApiRequestInProgress, setGetUsersApiRequestInProgress] = useState(true);

  let navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isTableInitiallyEmpty, setIsTableInitiallyEmpty] = useState(null);
  const [tableState, setTableState] = useState({
    searchText: '',
    sortField: null,
    isAscSort: null,
    pageNumber: 1
  });
  const usersTableRowEvents = {
    onClick: (row) => {
      navigate('/users/update-user', {
        state: {
          subjectId: row.subjectId,
          userName: `${row.firstName || ''} ${row.lastName || ''}`
        }
      });
    }
  };
  const handleUsersSearchChange = (value) => {
    setTableState((prevState) => ({
      ...prevState,
      searchText: value,
      pageNumber: 1
    }));
  };

  const getUsers = async (pageNumber = 1, searchKeyWord = null, sortBy = null, isAscSort = null) => {
    setGetUsersApiRequestInProgress(true);
    try {
      const data = {
        pageNumber,
        pageSize: recordsPerPage,
        searchKeyWord,
        sortBy,
        isAscSort
      };

      const response = await getAllUsers(data, context.msalInstance);
      if (response && response.paginatedList) {
        setAllUsers(response.paginatedList);
        setTotalCount(response.totalCount);
        if (response.totalCount === 0 && pageNumber === 1 && !searchKeyWord) {
          setIsTableInitiallyEmpty(true);
        } else {
          setIsTableInitiallyEmpty(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGetUsersApiRequestInProgress(false);
    }
  };
  useEffect(() => {
    getUsers(tableState.pageNumber);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getUsers(tableState.pageNumber, tableState.searchText, tableState.sortField?.toLowerCase(), tableState.isAscSort);
    // eslint-disable-next-line
  }, [tableState]);
  const NoDataIndication = () => <TableNoData mainMessage="No data available" />;
  return (
    <div>
      <div className="page-header">
        <div className="breadcrumb-wrapper">
          <Breadcrumb>
            <Breadcrumb.Item>Users</Breadcrumb.Item>
          </Breadcrumb>
          {/* todo: remove previous search bar and do the relevant renames  */}
          <SearchBarUpdated searchHandler={handleUsersSearchChange} className="table-search" />
          {/* <SearchBar apiRequest={getUsers} className="table-search" /> */}
        </div>
        <NavLink to="/users/create-user" className="btn btn-primary">
          <img src={addUserIcon} alt="add user icon" />
          Create User
        </NavLink>
      </div>
      {isTableInitiallyEmpty === null ? (
        <Loader />
      ) : isTableInitiallyEmpty === true ? (
        <React.Fragment>
          <NoData
            link="/users/create-user"
            buttonText="Create New User"
            mainMessage="You do not have Users right now!"
            secondaryMessage="Please click 'Create New User' to add a new User"
            icon={addUserIcon}
          />
        </React.Fragment>
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <div className="user-tbl-responsive">
              <TableComponentUpdated
                columns={columns}
                data={allUsers}
                setTableState={setTableState}
                rowClickable={true}
                onRowClick={usersTableRowEvents.onClick}
                tableLoading={getUsersApiRequestInProgress}
                noDataIndicator={NoDataIndication}
              />
              <PaginationComponent
                totalCount={totalCount}
                recordsPerPage={recordsPerPage}
                setTableState={setTableState}
                tableState={tableState}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
